const state = {
  type: null,
  message: null,
  timer: 0
}

const mutations = {
  set: (state, options) => {
    state.type = options.type
    state.message = options.message
    state.timer = options.timer
  },
  clear: (state) => {
    state.type = ''
    state.message = ''
    state.timer = 0
  }
}

const actions = {
  showSuccess({ commit }, message) {
    commit('set', { type: 'success', message, timer: 5 })
  },
  showDanger({ commit }, message) {
    commit('set', { type: 'danger', message, timer: 5 })
  },
  clear({ commit }) {
    commit('clear')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
