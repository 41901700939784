export default {
  titletreinamentos: 'Trainings',
  sair: 'Exit',
  novotreinamento: 'New training',
  infotreinamento: 'Training information',
  codigotreinamento: 'Training code',
  nometreinamento: 'Training name',
  descricaotreinamento: 'Brief description of the training',
  informecodigotreinamento: 'Enter the training code',
  informenometreinamento: 'Enter the name of the training',
  informedescricaotreinamento: 'Make a description of the training',
  salvar: 'Save',
  cancelar: 'Cancel change',
  camposnaopreenchidos: 'There is mandatory field (s) not filled in',
  editartreinamento: 'Edit training',
  excluirtreinamento: 'Delete training',
  treinamentocadastrado: 'Training successfully registered!!!',
  treinamentoatualizado: 'Training updated successfully!!!',
  atencao: 'Attention!!!',
  aviso: 'Warning!!!',
  certezaexcluirtreinamento: 'Are you sure you want to delete this training?',
  treinamentoexcluido: 'Training successfully deleted!!!',
  treinamentos: 'Trainings',
  novo: 'New',
  referencias: 'References',
  cargo: 'Position',
  acoes: 'Actions',
  imprimir: 'Print',
  exportarpdf: 'Export PDF',
  exportarexcel: 'Export Excel',
  editar: 'Edit',
  excluir: 'Delete',
  treinamento: 'Training',
  cadastrado: 'successfully registered!!!',
  cadastrada: 'successfully registered!!!',
  cadastradas: 'successfully registered!!!',
  atualizado: 'updated successfully!!!',
  atualizada: 'updated successfully!!!',
  atualizadas: 'updated successfully!!!',
  excluido: 'deleted successfully!!!',
  excluida: 'deleted successfully!!!',
  certezaexcluir: 'Are you sure you want to delete',
  certezacancelar: 'Are you sure you want to cancel',
  este: 'this',
  esta: 'this',
  cliqueparaselecionar: 'Click to select',
  selecione: 'Select',
  umoumais: 'one or more',
  cargomin: 'position',
  cargosmin: 'positions',
  cadastrorealizado: 'Successful registration!!!',
  updaterealizado: 'Update successfully!!!',
  arquivo: 'File',
  arquivomin: 'file',
  do: 'of the',
  da: 'of the',
  escolhaarquivo: 'Choose a file or drag it here...',
  escolhaarquivos: 'Choose one or more files or drag them here...',
  arquivocadastrado: 'Registered file',
  ver: 'See',
  baixar: 'Download',
  maodeobra: 'Labor',
  arquivorelacionadolocal: 'Location related documents / images',
  textoarquivo:
    'If applicable, photos or other files can be registered to better demonstrate the ideal conditions for performing this task.',
  ambiente: 'Environment',
  oqueusar: 'What do you need to use for the task?',
  textomateriais:
    'Various materials can be included. They must be registered one by one.',
  informemateriais: 'Enter the task materials',
  arquivorelacionadomaterial: 'Material related documents / images',
  videorelacionadomaterial: 'Material related video',
  informeummaterial: 'Please enter at least one material',
  material: 'Material',
  materialmin: 'material',
  precisausar: 'What do you need to use',
  oquesaber: 'What do you need to know to accomplish the training?',
  textometodo:
    'Various necessary knowledge can be included. They must be registered one by one.',
  informesaber: 'Enter what you need to know',
  arquivorelacionadometodo: 'Method related documents / images',
  videorelacionadometodo: 'Method related video',
  informeummetodo: 'Please enter at least one method',
  metodo: 'Method',
  metodomin: 'method',
  precisasaber: 'What do you need to know',
  oquecontrolar: 'What do you need to control in the training?',
  textocontrolar:
    'Several measures can be included. They must be registered one by one.',
  informemedidas: 'Enter the task measurements',
  arquivorelacionadomedida: 'Measure related documents / images',
  videorelacionadomedida: 'Measure related video',
  precisacontrolar: 'What you need to control',
  medida: 'Measure',
  informeumamedida: 'Please enter at least one measure',
  oqueoperar: 'What needs to operate on the task?',
  textooperar:
    'Several machines can be included. They must be registered one by one.',
  informemaquinas: 'Enter the machines used in the task',
  arquivorelacionadomaquina: 'Machine related documents / images',
  videorelacionadomaquina: 'Machine related video',
  precisaoperar: 'What needs to operate',
  maquina: 'Machine',
  maquinamin: 'machine',
  informeumamaquina: 'Please enter at least one machine',
  linguagem: 'Language',
  portugues: 'Portuguese',
  ingles: 'English',
  espanhol: 'Spanish',
  linkdash: 'Dashboard to be consulted',
  nomedash: 'Dashboard name',
  linkform: 'Form to be filled',
  linkdmforms: 'Form DMForm to be filled',
  nomeform: 'Form name',
  informelinkdash: 'Enter the dashboard link',
  informenomedash: 'Enter the dashboard name',
  informelinkform: 'Enter the form link',
  informenomeform: 'Enter the form name',
  formulariomin: 'form',
  formulario: 'Form',
  palavraschave: 'Training related keywords',
  informepalavrachave: 'Enter keywords',
  palavrasadicionadas: 'Added keywords:',
  excluirpalavra: 'Delete keyword',
  logomarca: 'Logo',
  obrigadoparticipacao: 'Thank you for your participation',
  respostaenviada: 'Your answer has been sent',
  enviaroutraresposta: 'Submit another answer',
  compartilhar: 'Share',
  copiadosucesso: 'Successfully copied!!!',
  duplicadosucesso: 'Successfully duplicated!!!',
  copiar: 'Copy',
  convidado: 'Guest',
  dashboardousite: 'Dashboard / Site',
  obrigatorio: 'Required',
  cliqueparabaixar: 'Click to download',
  cliqueparaabrir: 'Click to open',
  cliqueparaassistir: 'Click to watch',
  arquivos: 'Files',
  arquivosmin: 'files',
  verouocultar: 'Show or hide',
  videorelacionadolocal: 'Location related video',
  maquinas: 'Machines',
  cliente: 'Client',
  clientemin: 'client',
  clientes: 'Clients',
  clientesmin: 'clients',
  umaoumais: 'one or more',
  sim: 'Yes',
  nao: 'No',
  emminutos: 'in minutes',
  pesquisa: 'Research',
  pesquisamin: 'research',
  pesquisas: 'Researches',
  pesquisasmin: 'researches',
  pessoa: 'People',
  pessoamin: 'people',
  pessoas: 'Peoples',
  pessoasmin: 'peoples',
  atribuicoes: 'Assignments',
  recursos: 'Resources',
  empresa: 'Company',
  operacao: 'Operation',
  atividade: 'Activity',
  treinamentocomplementar: 'Treinamento complementar',
  datainicio: 'Start date',
  datatermino: 'Finish date',
  treinamentoe: 'Training is',
  periodoaplicacao: 'Period of application',
  existepontuacaoaprovacao: 'Is there a minimum passing score?',
  pilar: 'Pillar',
  bloco: 'Block',
  item: 'Item',
  origem: 'Source',
  indicadorassociado: 'Associated indicator',
  enviar: 'Send',
  pilares: 'Pillars',
  blocos: 'Blocks',
  itens: 'Items',
  origens: 'Sources',
  avaliacao: 'Evaluation',
  cargos: 'Positions',
  locais: 'Places',
  estrutura: 'Structure',
  estruturas: 'Structures',
  estruturamin: 'structure',
  estruturasmin: 'structures',
  usuarios: 'Users',
  local: 'Place',
  caracteristicas: 'Characteristics',
  informelocal: 'Enter the place',
  informecaracteristicaslocal: 'Enter the characteristics of the place',
  localcadastrado: 'Place successfully registered!!!',
  localatualizado: 'Place updated successfully!!!',
  localexcluido: 'Place successfully deleted!!!',
  certezaexcluirlocal: 'Are you sure you want to delete this place?',
  descricao: 'Description',
  informecargo: 'Enter the position',
  informedescricaocargo: 'Enter the position description',
  cargocadastrado: 'Position successfully registered!!!',
  cargoatualizado: 'Position updated successfully!!!',
  cargoexcluido: 'Position successfully deleted!!!',
  certezaexcluircargo: 'Are you sure you want to delete this position?',
  certezaduplicarvaga: 'Are you sure you want to duplicate this job opening?',
  grupo: 'Group',
  grupos: 'Groups',
  grupomin: 'group',
  informegrupo: 'Enter the group name',
  grupocadastrado: 'Group successfully registered!!!',
  grupoatualizado: 'Group successfully updated!!!',
  grupoexcluido: 'Group successfully deleted!!!',
  escondermaquinas: 'Click to show or hide the machines in that group',
  cadastrogrupos: 'Register of machine groups',
  cadastromaquinas: 'Register of machines',
  informemaquina: 'Enter the machine name',
  naohamaquinas: 'There are no machines registered in this group',
  cadastrogruposrecursos: 'Register of resource groups',
  cadastrorecursos: 'Register of resources',
  naohamateriais: 'There are no materials registered in this group',
  informematerial: 'Enter material name',
  empresamin: 'company',
  empresasmin: 'companies',
  nome: 'Name',
  informenomeusuario: 'Enter the user name',
  email: 'Email',
  informeemailusuario: `Enter the user's email`,
  celular: 'Cell phone',
  informecelularusuario: `Inform the user's cell phone`,
  informecargousuario: 'Enter the user position',
  ativo: 'Active',
  usuariocadastrado: 'User successfully registered!!!',
  codigounidade: 'Unity code',
  informecodigounidade: 'Enter the unity code',
  senha: 'Password',
  informesenhausuario: 'Enter the user password',
  tamanhosenha: 'Password must be at least 8 characters',
  perfil: 'Profile',
  perfilmin: 'profile',
  unidade: 'Unity',
  unidademin: 'unity',
  unidades: 'Units',
  unidadesmin: 'units',
  temacessograde: 'Has access to the grid',
  biblioteca: 'Library',
  bibliotecamin: 'library',
  bibliotecasmin: 'libraries',
  administradordemarco: 'Demarco Administrator',
  usuarioscadastrados: 'Registered users',
  cadastroestruturainicial: 'Register of initial structure',
  cadastroestruturasequencial: 'Register of sequential structures',
  informenomeestrutura: 'Enter structure name',
  informedetalhesestrutura: 'Enter structure details',
  ativa: 'Active',
  ativamin: 'active',
  detalhes: 'Details',
  opcional: 'optional',
  cadastrobibliotecainicial: 'Register of initial library',
  cadastrobibliotecasequencial: 'Register of sequential libraries',
  cadastreumcargo: 'Register a position to select',
  administrador: 'Administrator',
  usuario: 'User',
  tituloambiente: 'Environment (WHERE it will be executed)',
  titulomaodeobra: 'Labor (BY WHOM it will be executed))',
  titulomaterial: 'Material (what you need to USE)',
  titulometodo: 'Method (what you need to KNOW)',
  titulomedida: 'Measure (what you need to CONTROL)',
  titulomaquina: 'Machine (what you need to OPERATE)',
  cadastro: 'Register',
  planejamento: 'Planning',
  tituloplano: 'WHAT, WHY, WHERE and BY WHOM it will be executed',
  titulocomo: 'HOW it will be executed',
  tituloquando: 'WHEN will be executed',
  titulocustos: 'Training COSTS components',
  infopessoa: 'Person information',
  catalogo: 'Catalog',
  catalogomin: 'catalog',
  cadastrogradesequencial: 'Register of sequential grids',
  cadastrogradeinicial: 'Register of initial grid',
  informenomegrade: 'Enter grid name',
  informedetalhesgrade: 'Enter grid details',
  cpf: 'CPF',
  informecpf: 'Enter the CPF',
  cadastropessoa: 'Person register',
  novapessoa: 'New person',
  pessoais: 'Personal data',
  funcionais: 'Functional data',
  sociais: 'Social data',
  transacionais: 'Transactional data',
  datanascimento: 'Date of birth',
  nomepai: `Father's name`,
  nomemae: `Mother's name`,
  informedatanascimento: 'Enter the date of birth',
  informenomepai: `Enter the fathe's name`,
  informenomemae: `Enter the mother's name`,
  dataadmissao: 'Admission date',
  informedataadmissao: 'Enter the admission date',
  salario: 'Salary',
  informesalario: 'Enter the salary',
  codigopostal: 'Postal code',
  cep: 'Postal code',
  informecodigopostal: 'Enter the postal code',
  logradouro: 'Street',
  informelogradouro: 'Enter the street',
  numero: 'Number',
  informenumero: 'Enter the number',
  bairro: 'Neighborhood',
  informebairro: 'Enter the neighborhood',
  cidade: 'City',
  cidademin: 'city',
  informecidade: 'Enter the city',
  estado: 'State',
  estadomin: 'state',
  informeestado: 'Enter the state',
  pais: 'Country',
  informepais: 'Enter the country',
  jornadatrabalho: 'Working Hours',
  informejornadatrabalho: 'Enter the working hours',
  informenome: 'Enter the name',
  notaminima: 'Minimum score for approval',
  informenotaminima: 'Enter the minimum score for approval',
  informenotamaxima: 'Enter the maximum score',
  notamaxima: 'Maximum score',
  tempontuacao: 'There is a minimum score for approval',
  vinculadograde: 'Linked to the grid',
  cadastre: 'Register',
  um: 'one',
  uma: 'an',
  incluircampo: 'Add field',
  ondeserarealizado: 'Where will be held',
  localmin: 'place',
  desejaadicionar: 'Do you want to add / remove an item?',
  selecioneparaadicionar: 'Select one or more items to add',
  imagemoudocumento: 'Documents / Images',
  formularioexterno: 'External form',
  video: 'Video',
  videomin: 'video',
  quemparticipara: 'Who will participate in the training?',
  quemparticipa: 'Who participates',
  naoaplicatreinamento: 'Does not apply to this training',
  preenchermanualmente: 'Fill in information',
  copiardadostreinamento: 'Copy information from training',
  copiardados: 'Copy information from another training',
  informelinkvideo: 'Enter the video link on Youtube, Vimeo or Google Drive',
  invalidLinkVideoURL:
    'The video link must be from Youtube, Vimeo or Google Drive',
  nomevideo: 'Video name',
  informenomevideo: 'Enter the video name',
  oqueserafeito: 'What will be done in the training?',
  informeoqueserafeito: 'Inform what will be done in the training',
  porqueserafeito: 'Why should training be carried out?',
  informeporqueserafeito: 'Inform the reason for the training',
  quemeresponsavel: 'Who is responsible for conducting the training?',
  responsavelmin: 'responsible',
  responsavel: 'Responsible',
  passoexecucao: 'Step for conducting the training',
  informeopassoexecucao: 'Enter the step for conducting the training',
  frequenciamin: 'frequency',
  frequencia: 'Frequency',
  comquefrequencia: 'How often will training take place?',
  pordemanda: 'On demand',
  diaria: 'Daily',
  semanal: 'Weekly',
  quinzenal: 'Biweekly',
  mensal: 'Monthly',
  semestral: 'Semester',
  anual: 'Annual',
  segunda: 'Monday',
  terca: 'Tuesday',
  quarta: 'Wednesday',
  quinta: 'Thursday',
  sexta: 'Friday',
  sabado: 'Saturday',
  domingo: 'Sunday',
  janeiro: 'January',
  fevereiro: 'February',
  marco: 'March',
  abril: 'April',
  maio: 'May',
  junho: 'June',
  julho: 'July',
  agosto: 'August',
  setembro: 'September',
  outubro: 'October',
  novembro: 'November',
  dezembro: 'December',
  informediavalido: 'Please enter a valid day',
  informeprazovalido: 'Please enter a valid deadline',
  emmin: '(in min.)',
  fatogerador: 'Demand generating fact',
  informefatogerador: 'Enter the generating fact of the demand',
  diaprimeiraquinzena: 'Execution day in the first half',
  informediaprimeiraquinzena: 'Enter the execution day in the first half',
  diasegundaquinzena: 'Execution day in the second half',
  informediasegundaquinzena: 'Enter the execution day in the second half',
  mesprimeirosemestre: 'Month of the first semestrer',
  messegundosemestre: 'Month ofthe second semestrer',
  horadia: 'Time of day',
  diasemana: 'Day of the week',
  diasemanamin: 'day of the week',
  diaexecucao: 'execution day',
  primeiro: 'First',
  segundo: 'Second',
  primeiromin: 'first',
  segundomin: 'second',
  diames: 'Day of month',
  diamesmin: 'day of month',
  mes: 'Month',
  mesmin: 'month',
  mesano: 'Month of the year',
  tempoduracaotreinamento: 'Training duration',
  informetempoduracaotreinamento: 'Inform the duration of the training',
  informetempoduracaopesquisa: 'Inform the duration of the research',
  informediames: 'Enter the day of the month',
  naoinformado: 'Not informed',
  treinamentoobrigatorio: 'Training is mandatory',
  quaispessoasparticiparaotreinamento:
    'Which people will participate in the training?',
  quaispessoasparticiparaopesquisa:
    'Which people will participate in the research?',
  recurso: 'Resource',
  informerecurso: 'Enter resource name',
  recursomin: 'resource',
  grade: 'Grid',
  grademin: 'grid',
  adicionargradesequencial: 'Add sequential grid',
  dataespecifica: 'Specific date',
  avaliacaomin: 'evaluation',
  gruposrecursos: 'Resources groups',
  gruposmin: 'groups',
  recursosmin: 'resources',
  diariamente: 'Daily at',
  toda: 'All',
  dias: 'Days',
  e: 'and',
  dia: 'Day',
  nosmesesde: 'In the months of',
  nomesde: 'Annually, in',
  a: 'the',
  o: 'the',
  decadames: 'of each month',
  quandoacontecera: 'When will it happen',
  naoidentificado: 'Not identified',
  unidadesparticipantes: 'Participating units',
  cargosparticipantes: 'Participating positions',
  planejamentomin: 'planning',
  arquivorelacionadotreinamento: 'Training related documents / images',
  quaiscargosdevemparticipar: 'Which positions should they participate?',
  videorelacionadotreinamento: 'Training related video',
  avaliacoes: 'Evaluations',
  avaliacoesmin: 'evaluations',
  novaavaliacao: 'New evaluation',
  campo: 'Field',
  campomin: 'field',
  cadastroavaliacao: 'Evaluation registration',
  nomeavaliacao: 'Evaluation name',
  informenomeavaliacao:
    'Enter the name of the evaluation (short name preferably)',
  descricaoavaliacao: 'Form description',
  informedescricaoavaliacao: 'Enter the evaluation description',
  adicionarsecao: 'Add section',
  adicionarcampo: 'Add field',
  nomesecao: 'Section name',
  informenomesecao: 'Enter the section name',
  salvarsecao: 'Save section',
  cancel: 'Cancel',
  nomecampo: 'Field name or question to be answered',
  informenomecampo: 'Enter the field name',
  textonomecampo:
    'Text that will appear as a label on the evaluation. Cannot be changed after responses are registered',
  textoincludecampo: 'Text that will appear as a label on the field',
  tipocampo: 'Field type',
  selecionetipocampo: 'Select the field type',
  listaopcoes: 'List of options',
  informeopcao: 'Enter the option',
  excluiropcao: 'Delete option',
  campoobrigatorio: 'Required field',
  campos: 'Fields',
  camposmin: 'fields',
  secao: 'Section',
  secaomin: 'section',
  escolhaopcao: 'Choose an option',
  avaliacaoatualizada: 'Successfully updated evaluation!!!',
  avaliacaocadastrada: 'Evaluation successfully registered!!!',
  avancar: 'Advance',
  preencher: 'Fill',
  data: 'Date',
  opcao: 'Option',
  selecaounica: 'Single item selection',
  selecaomultipla: 'Selection of multiple items',
  textocurto: 'Short text',
  textolongo: 'Long text',
  cliqueeditar: 'Click to edit',
  consultarinformacoes: 'Consult information',
  informacoesenviadas: 'Information sent successfully!!!',
  informacoesexcluidas: 'Information deleted successfully!!!',
  preenchidopor: 'Filled by',
  datapreenchimento: 'Date of completion',
  estas: 'these',
  informacoesmin: 'information',
  telefone: 'Phone',
  hora: 'Hour',
  informeemailvalido: 'Enter a valid email',
  adicionarimagem: 'Add image',
  adicionarvideo: 'Add video',
  nomevideopreview: 'Name of the video or text to be displayed',
  informenomevideopreview:
    'Enter the name of the video or text to be displayed',
  nomearquivopreview: 'Name of the file or text to be displayed',
  informenomearquivopreview:
    'Enter the name of the file or text to be displayed',
  pequena: 'Small',
  grande: 'Big',
  tamanhonatela: 'Enter the size of the image on the screen',
  imagem: 'Image',
  semsecao: 'Before the first section',
  valorminimo: 'Minimum value',
  informevalorminimo: 'Enter the minimum value',
  valormaximo: 'Maximum value',
  informevalormaximo: 'Enter the maximum value',
  quantidademinimo: 'Minimum quantity of selectable options',
  informequantidademinimo: 'Enter the minimum quantity',
  quantidademaximo: 'Maximum quantity of selectable options',
  informequantidademaximo: 'Enter the maximum quantity',
  duplicar: 'Duplicate',
  voltar: 'Back',
  classificacao: 'Rating',
  niveis: 'Levels',
  simbolo: 'Symbol',
  estrela: 'Star',
  treinamentomin: 'training',
  limparpesquisa: 'Clear search',
  informepalavrachaveparapesquisar: 'Enter a keyword to search and press enter',
  informepalavraparapesquisar: 'Enter a word to search and press enter',
  valordaquestao: 'Question value',
  informevalordaquestao: 'Enter the question value',
  somainvalida:
    'The sum of the points of the questions cannot be higher than the maximum score of the evaluation',
  valornaopodesernegativo: 'The question value cannot be less than zero',
  respostacorreta: 'Correct answer',
  selecionerespostacorreta: 'Select the correct answer',
  respostascorretas: 'Correct answers',
  selecionerespostascorretas: 'Select the correct answers',
  vincular: 'Link',
  ocultarinformacoes: 'Hide information',
  mostrarinformacoes: 'Show information',
  aprovado: 'Approved',
  reprovado: 'Disapproved',
  pontuacaoobtida: 'Score obtained',
  resultado: 'Result',
  gradesmin: 'grids',
  inserirnatelade: 'Insert on the',
  selecionetela: 'Select the screen',
  titulocampo: 'Field name',
  tipo: 'Type',
  tela: 'Screen',
  vinculo: 'Bond',
  vinculos: 'Bonds',
  vinculomin: 'bond',
  informevinculo: 'Enter the bond',
  informedescricaovinculo: 'Enter the bond description',
  jornadadetrabalho: 'Workday',
  jornadas: 'Workday',
  jornadamin: 'workday',
  informejornada: 'Enter the workday',
  informedescricaojornada: 'Enter the workday description',
  remuneracao: 'Remuneration',
  remuneracaobeneficios: 'Remuneration and Benefits',
  contratacao: 'Hiring',
  recrutamentoselecao: 'Recruitment and Selection',
  movimentacao: 'Movement',
  carreira: 'Career',
  novapesquisa: 'New research',
  configuracao: 'Configuration',
  gerenciamento: 'Management',
  movimentacaoregistro: 'Movement and Registration',
  relacionamento: 'Relationship',
  acompanhamentoretencao: 'Monitoring and Retention',
  desenvolvimento: 'Development',
  integracaotreinamento: 'Integration and Training',
  informetelefone: 'Enter the phone number',
  documentoidentificacao: 'Identification document',
  textodocumentoidentificacao: 'In Brazil, inform the CPF',
  gradetreinamentos: 'Training grid',
  gradetbaseconhecimento: 'File grid',
  foto: 'Photo',
  pessoasparticipantes: 'Participating people',
  textovalidanotaminima:
    'The minimum score cannot be higher than the evaluation value.',
  cadastropesquisa: 'Research registration',
  nomepesquisa: 'Research name',
  informenomepesquisa: 'Enter the research name',
  descricaopesquisa: 'Research description',
  informedescricaopesquisa: 'Enter the research description',
  dataterminoinvalida: 'Finish date cannot be earlier than start date',
  dataatestadoinvalida: 'Certificate date cannot be later than start date',
  tempeso: 'Answers will have weights',
  opcoes: 'Options',
  pesos: 'Weights',
  respostaobrigatoria: 'Mandatory answer',
  pesoresposta: 'Response weight',
  documento: 'Document',
  admissao: 'Admission',
  afastamentos: 'Work leave',
  ausencias: 'Absences',
  demissao: 'Dismissal',
  tipodemissao: 'Dismissal type',
  tipoocorrencia: 'Occurrence type',
  datademissao: 'Dismissal date',
  dataocorrencia: 'Occurrence date',
  ferias: 'Vacation',
  jornada: 'Workday',
  ocorrencias: 'Occurrences',
  transferencias: 'Transfers',
  demissoes: 'Dismissals',
  cadastrosubcategoriademissao: 'Registration of dismissal subcategory',
  cadastrocategoriademissao: 'Registration of dismissal category',
  informecategoria: 'Enter category name',
  informedetalhescategoria: 'Enter category details',
  categoria: 'Category',
  categoriamin: 'category',
  adicionarsubcategoria: 'Add subcategory',
  cadastrosubcategoriaocorrencia: 'Registration of occurrence subcategory',
  cadastrocategoriaocorrencia: 'Registration of occurrence category',
  cadastrosubcategoriatransferencia: 'Registration of transfer subcategory',
  cadastrocategoriatransferencia: 'Registration of transfer category',
  cadastrosubcategoriaafastamento: 'Registration of work leave subcategory',
  cadastrocategoriaafastamento: 'Registration of work leave category',
  exigejustificativa: 'Requires justification and supporting document',
  exigedocumentocomprobatorio: 'Requires supporting document',
  exigeatestado: 'Requires presentation of medical certificate',
  cadastrosubcategoriaausencia: 'Registration of absence subcategory',
  cadastrocategoriaausencia: 'Registration of absence category',
  cadastrosubcategoriapromocao: 'Registration of rise subcategory',
  cadastrocategoriapromocao: 'Registration of rise category',
  colaborador: 'Collaborator',
  dataafastamento: 'Work leave date',
  tipoafastamento: 'Work leave type',
  cadastreumafastamento:
    'Register a work leave type in Configuration / Work leave',
  cadastreumademissao:
    'Register a dismissal type in Configuration / Dismissals',
  cadastreumaocorrencia:
    'Register a occurrence type in Configuration / Occurrences',
  tipomin: 'type',
  justificativa: 'Justification',
  informejustificativa: 'Enter the work leave justification',
  arquivosrelacionadosafastamento: 'Work leave related files',
  arquivosrelacionadosdemissao: 'Dismissal related files',
  arquivosrelacionadosocorrencia: 'Occurrence related files',
  dataterminoafastamento: 'Leave end date',
  dataretorno: 'Return date',
  afastamentocadastrado: 'Work leave registered successfully!!!',
  afastamentoatualizado: 'Work leave updated successfully!!!',
  afastamentoexcluido: 'Work leave deleted successfully!!!',
  demissaocadastrado: 'Dismissal registered successfully!!!',
  demissaoatualizado: 'Dismissal updated successfully!!!',
  demissaoexcluido: 'Dismissal deleted successfully!!!',
  demissaocancelada: 'Dismissal canceled successfully!!!',
  ocorrenciacadastrado: 'Occurrence registered successfully!!!',
  ocorrenciaatualizado: 'Occurrence updated successfully!!',
  ocorrenciaexcluido: 'Occurrence deleted successfully!!',
  ocorrenciacancelada: 'Occurrence canceled successfully!!!',
  esteafastamento: 'this work leave',
  estademissao: 'this dismissal',
  estaocorrencia: 'this occurrence',
  baixarimagem: 'Download image',
  baixardocumento: 'Download document',
  arquivoscadastrados: 'Registered files',
  tipoausencia: 'Absence type',
  cadastreumaausencia: 'Register an absence type in Configuration / Absences',
  arquivosrelacionadosausencia: 'Absence related files',
  ausenciacadastrada: 'Absence registered successfully!!!',
  ausenciaatualizada: 'Absence updated successfully!!!',
  ausenciaexcluida: 'Absence deleted successfully!!!',
  estaausencia: 'this absence',
  tipoatestado: 'Certificate type',
  medico: 'Medical',
  odontologico: 'Dental',
  codigodoenca: 'Disease code',
  informecodigodoenca: 'Enter the disease code or fill in with Not informed',
  identificacaomedico: 'Identification of the doctor / dentist',
  informeidentificacaomedico:
    'Enter the identification of the doctor / dentist',
  textocid:
    'As the patient can request that the disease code is not mentioned in the certificate, the absence of this information must also be registered in the system: “NOT INFORMED”',
  dataatestado: 'Certificate date',
  promocao: 'Rise',
  promocoes: 'Rises',
  transferenciaexcluida: 'Transfer deleted successfully!!!',
  transferenciacancelada: 'Transfer canceled successfully!!!',
  estatransferencia: 'this transfer',
  transferenciaatualizada: 'Transfer updated successfully!!!',
  transferenciacadastrada: 'Transfer registered successfully!!!',
  motivoentrada: 'Entry reason',
  motivosaida: 'Leaving reason',
  estruturadestino: 'Target structure',
  estruturaatual: 'Current structure',
  datamovimentacao: 'Movement date',
  arquivosrelacionadostransferencia: 'Transfer related files',
  cadastreumatransferencia:
    'Registes a transfer type in Configuration / Transfers',
  unidadedestinoinvalida:
    'The target structure must be different from the current structure',
  peridodoaplicacao: 'Application period',
  datainiciopromocao: 'Rise start date',
  cargoatual: 'Current position',
  salarioatual: 'Current salary',
  novocargo: 'New position',
  novosalario: 'New salary',
  informenovosalario: 'Enter new salary',
  tipopromocao: 'Rise type',
  cadastreumapromocao: 'Register a rise type in Configuration / Rises',
  arquivosrelacionadospromocao: 'Rise related files',
  datapromocao: 'Rise date',
  promocaomin: 'rise',
  arquivosrelacionadospesquisa: 'Research related files',
  tempoduracaopesquisa: 'Research duration',
  marcartodos: 'Select / Deselect all',
  marcartodas: 'Select / Deselect all',
  desenvolvimentoecarreira: 'Development and Career',
  dossievirtual: 'Workplace',
  eventosfolha: 'Payroll Events',
  encargos: 'Charges',
  provisoes: 'Provisions',
  remuneracaovariavel: 'Variable Salary',
  adiantamentosalarial: 'Salary Advance',
  beneficios: 'Benefits',
  dadosbancarios: 'Bank Data',
  parametros: 'Parameters',
  recibos: 'Receipts',
  folhadepagamento: 'Payroll',
  relatorios: 'Reports',
  programacao: 'Programming',
  controle: 'Control',
  documentos: 'Documents',
  numeroperiodoaquisitivo: 'Vacation purchase period (PP) number',
  informenumeroperiodoaquisitivo:
    'Enter the sequential number to which this purchasing period refers',
  datainicioperiodoaquisitivo: 'Purchasing period start date',
  dataterminoperiodoaquisitivo: 'Purchasing period end date',
  diasantecedencia:
    'Days in advance to go on vacation before the end of the purchasing period',
  informediasantecedencia: 'Inform the number of days in advance',
  datalimitesairferias: 'Deadline for going on vacation',
  numerofaltasperiodoaquisitivo: 'Number of absences in the acquisition period',
  informenumerofaltaspa: 'Enter the number of absences',
  numerodiasferias: 'Number of vacation days',
  informenumerodiasferias:
    'Enter the number of days the collaborator will go on vacation',
  opcaoabonopecuniario: 'Option for pecuniary allowance',
  opcaoantecipacao: 'Option to prepay the 1st installment of the 13th salary',
  datainicioferias: 'Vacation start date',
  dataterminoferias: 'Vacation end date',
  numeroperiodoaquisitivoinvalido:
    'The purchase period number must be greater than 0',
  diasantecedenciainvalido:
    'The number of days in advance must be greater than or equal to 30',
  diasferiasinvalido: 'The number of vacation days must be greater than 0',
  datainicioferiasinvalida:
    'The vacation start date cannot be earlier than the purchase period start date',
  datainicioferiasfimperiodo:
    'The vaccation start date cannot be later than the concessive period end date',
  numerofaltasinvalido:
    'The number of absences must be greater than or equal to 0',
  colaboradorsaldozero:
    'Colaborator does not have vacation balance for the informed purchasing period',
  saldoferiasinsuficiente: `Number of vacation days greater than the collaborator's balance. Current balance: `,
  numeropa: 'PP number',
  datainiciopa: 'PP start date',
  dataterminopa: 'PP end date',
  numerofaltas: 'Absences number',
  saldodiasferias: 'Vacation days balance',
  iniciogozoferias: 'Vacation start',
  terminogozoferias: 'Vacation end',
  totaldiasferias: 'Total vacation days',
  abonopecuniario: 'Pecuniary allowance',
  antecipar13sal: 'Anticipate 13th sal. (Y/N)',
  aprovada: 'Approved (Y/N)',
  S: 'Y',
  N: 'N',
  feriasmin: 'vacation',
  solicitacaoabonopecuniario: 'Request for pecuniary allowance',
  solicitacaoantecipar13sal: 'Request to advance 13th salary',
  avisoferias: 'Vacation notice',
  reciboferias: 'Vacation receipt',
  gerar: 'Generate',
  vagas: 'Jobs',
  vaga: 'Job',
  vagamin: 'job',
  candidatos: 'Candidates',
  candidatosmin: 'candidates',
  formularios: 'Forms',
  entrevistas: 'Interviews',
  exames: 'Exams',
  agenciasrs: 'R&S Agencies',
  informediasabono: 'Enter the number of days referring to the allowance',
  diasabonoinvalido:
    'The number of allowance days must be greater than or equal to 0',
  diasabonoinsuficiente:
    'The number of vacation days added to the number of allowance days cannot be greater than the vacation days balance. Current balance:',
  escalas: 'Scales',
  marcacoes: 'Bookings',
  compensacoes: 'Compensations',
  adicionais: 'Additional',
  quadropessoal: 'Staff',
  quantidadeidealpessoas: 'Optimal number of people',
  staffnuminvalido: 'The number of people must be greater than or equal to 0',
  camporepetido: 'Two fields with the same name cannot be inserted',
  secaorepetida: 'Two sections with the same name cannot be inserted',
  empresatrabalha: 'Company where he/she works',
  empresasacessar: 'Companies it will access',
  permissoesempresa: 'Permissions in the company ',
  admin_sistema: 'Demarco Administrator',
  camposobrigatorios: 'Required fields',
  exibirusuarios: 'Show users',
  ativos: 'Active',
  inativos: 'Inactive',
  hierarquia: 'Hierarchy',
  reunioes: 'Meetings',
  feedback: 'Feedback',
  planos: 'Plans',
  pipeline: 'Pipeline',
  geral: 'General',
  condutor: 'Conductor',
  planoacao: 'Action plan',
  aprovacoes: 'Approvals',
  marqueitensaprovacao: 'Tick the items that need approval',
  atividades: 'Activities',
  experiencia: 'Experience',
  habilidades: 'Skills',
  funcoeseresponsabilidadescargo: 'Roles and responsibilities of the position',
  textocargoplanejamento:
    'Position selection is mandatory if no people are selected',
  textopessoasplanejamento:
    'People selection is mandatory if positions are not selected',
  caracteristica: 'Characteristic',
  caracteristicamin: 'characteristic',
  informecaracteristica: 'Enter the characteristic',
  peso: 'Weight',
  informepeso: 'Inform the weight of this characteristic',
  opcoesresposta: 'Response options',
  informeopcaoresposta: 'Enter response options',
  opcoesadicionadas: 'Added options',
  referencia: 'Reference',
  referenciamin: 'reference',
  nivelexigencia: 'Level of exigency',
  desejavel: 'Desirable',
  recomendavel: 'Recommendable',
  pesoinvalido: 'The value of the weight must be greater than or equal to zero',
  informecargoparapesquisar: 'Enter a position to search',
  contratacoes: 'Hiring',
  cadastrosubcategoriacontratacao: 'Registration of hiring subcategory',
  cadastrocategoriacontratacao: 'Registration of hiring category',
  requisicao: 'Request',
  aprovacao: 'Approval',
  anunciarvaga: 'Announce job',
  novavaga: 'New job',
  caracteristicasnaoinformadas: 'Uninformed characteristics',
  datarequisicao: 'Request date',
  codigovaga: 'Job code',
  informecodigovaga: 'Enter the job code',
  nomevaga: 'Job name',
  informenomevaga: 'Enter the job name',
  justificativacontratacao: 'Justification for hiring',
  cadastreummotivo: 'Register a reason in Configuration / Hiring',
  motivomin: 'reason',
  quantidadevagas: 'Number of jobs',
  informequantidadevagas: 'Enter the number of jobs',
  salarioreferencia: 'Reference salary',
  informesalarioreferencia: 'Enter the reference salary',
  prazocontratacao: 'Deadline for hiring (in days)',
  informeprazocontratacao: 'Enter the deadline for hiring',
  tiporecrutamento: 'Recruitment type',
  interno: 'Internal',
  externo: 'External',
  misto: 'Mixed',
  nomeaprovador: `Approver's name`,
  informenomeaprovador: `Enter the the approver's name`,
  emailaprovador: `Approver's mail`,
  informeemailaprovador: `Enter the the approver's mail`,
  celularaprovador: `Approver's cell phone`,
  informecelularaprovador: `Enter the the approver's cell phone`,
  cadastreumaestrutura: 'Register a structure at Configuration / Structure',
  quantidadevagasinvalida: 'The number of jobs must be greater than 0',
  salarioinvalido: 'The salary must be greater than 0',
  prazoinvalido: 'The deadline for hiring must be greater than 0',
  aguardandoaprovacao: 'Waiting for approval',
  aprovadavaga: 'Approved',
  reprovada: 'Disapproved',
  horariotrabalho: 'Work schedule',
  informehorariotrabalho: 'Enter the work schedule',
  aprovaroureprovar: 'Approve or disapprove',
  aprovarvaga: 'Approve job',
  reprovarvaga: 'Disapprove job',
  formulariocandidatos: 'Candidate form',
  formularioinscricao: 'Registration form',
  tituloformulario: 'Form title',
  informetituloformulario: 'Enter the form title',
  icp: 'Profile compliance index',
  icpparaaprovacao: 'Minimum profile compliance index (%)',
  informeicpparaaprovacao: 'Enter the minimum profile compliance index',
  descricaoformulario: 'Form description',
  informedescricaoformulario: 'Enter the form description',
  formulariocadastrocandidatos: 'Candidate registration form',
  formularioinscricaocandidatos: 'Candidate registration form',
  textoicpaprovacao:
    'Minimum PCI value so that the candidate can advance to the next phase of the selection process',
  requisitosadicionais: 'Additional requirements',
  informerequisitosadicionais: 'Enter the additional requirements, if any',
  ecolaboradordaempresa: 'Are you a company collaborator?',
  outrasinformacoes: 'Other information',
  novoform: 'New form',
  cadastroformulario: 'Form registration',
  nomeformulario: 'Form name',
  informenomeformulario: 'Enter the form name',
  vagaaqualserefere: 'Job to which it refers',
  cadastreumavaga: 'Register a job',
  identificacaocandidato: 'Identification of the candidate',
  formularionaopodeseralterado:
    'This form cannot be changed, as there are responses linked to it',
  checkhabilidades: 'Skills check',
  formulariosgerais: 'General forms',
  formulariocheckhabilidades: 'Skills check form registration',
  parecerentrevistador: `Interviewer's opinion`,
  apto: 'Apt',
  inapto: 'Inapt',
  ocandidatoesta: 'The candidate is',
  opcaomin: 'option',
  selecao: 'Selection',
  linkinscricao: 'Registration link',
  formulariocheckhabilidadesparaocargo:
    'Skills check form for the position of ',
  novocandidato: 'New candidate',
  candidato: 'Candidate',
  naofoiencontradocolaborador: `No collaborator was found with the document informed in the candidate's registration`,
  candidatocomrestricao:
    'Candidate does not meet mandatory characteristic(s) of profile and/or experience',
  novaadmissao: 'New admission',
  admissaomin: 'admission',
  cancelada: 'canceled successfully!!!',
  cancelado: 'canceled successfully!!!',
  afastamento: 'Work leave',
  afastamentocancelado: 'Work leave canceled succesfully!!!',
  ausencia: 'Absence',
  funilcontratacao: 'Hiring Funnel',
  informedocumento: 'Enter the document',
  informetipo: 'Enter the type',
  novaagencia: 'New agency',
  agenciamin: 'agency',
  agencia: 'Agency',
  certezaexcluiragencia: 'Are you sure you want to delete this agency?',
  agenciaexcluida: 'Agency successfully deleted!!!',
  informetipodocumento: 'Enter the document type',
  etapa: 'Stage',
  etapamin: 'stage',
  informeetapa: 'Enter the stage name',
  parecerfinal: 'Final opinion',
  justificativamin: 'justification',
  situacao: 'Status',
  situacaomin: 'status',
  inscricoesrecebidas: 'Received registrations',
  inscricoesconcluidas: 'Completed registrations',
  inscricoesvalidadas: 'Validated registrations',
  prazoemdias: 'Deadline (days)',
  etapasselecao: 'Selection stages',
  agendamentos: 'Schedules',
  agendamento: 'Schedule',
  registros: 'Registers',
  formulariosassociados: 'Associated forms',
  avaliacoesassociadas: 'Associated evaluations',
  cadastreetapasfunil:
    'Register the funnel stages in Configuration / Hiring Funnel',
  formulariosmin: 'forms',
  informetipoclinica: 'Select type of clinic / doctor',
  clinicasalva: 'Clinic successfully registered !!!',
  clinicaatualizada: 'Clinic updated successfully !!!',
  clinica: 'Clinic',
  novaclinica: 'New Clinic',
  certezaexcluirclinica: 'Are you sure you want to delete this clinic?',
  clinicaexcluida: 'Clinic successfully deleted!!!',
  juridica: 'Legal entity',
  fisica: 'Legal person',
  nomeclinica: 'Name of clinic / doctor',
  informenomeclinica: 'Enter the name of the clinic / doctor',
  informenomeagencia: `Enter the agency's name`,
  informedocumentoidentificacao: 'Enter the identification document',
  pessoajuridica: 'Legal Entity',
  pessoafisica: 'Natural Person',
  selecionetipoagencia: 'Select the agency type',
  pesodaetapa: 'Stage weight in the selection process',
  pesoetapa: 'Stage weight',
  anterior: 'Previous',
  proximo: 'Next',
  formularioassociado: 'Associated form',
  cadastreformulariorecrutamento:
    'Register a form at Recruitment and Selection / Forms',
  avaliacaoassociada: 'Associated evaluation',
  cadastreavaliacaorecrutamento:
    'Register a evaluation at Recruitment and Selection / Evaluation',
  etapasdeselecao: 'Selection Stages',
  agendaretapa: 'Schedule stage',
  candidatomin: 'candidate',
  gerenciaretapas: 'Manage Stages',
  aderenciavaga: 'Job adherence',
  verdetalhesetapas: 'Show stages details',
  incluirnocalculodeaderencia: 'Include in the job adherence calculation',
  atualizaaderecencia: 'Update adherence',
  atribuiretapa: 'Assign stage',
  cumprimentoprocesso: '% Process completion',
  canceladavaga: 'Canceled',
  congelada: 'Frozen',
  finalizada: 'Finished',
  comentario: 'Comment',
  indefinido: 'Undefined',
  parecerfinalprocesso: 'Final opinion of the process',
  comentariosfinais: 'Final comments',
  bancocandidatos: 'Candidate bank',
  checklistadmissao: 'Admission checklist',
  candidatosememail:
    'Candidate did not inform email. It is not possible to send the admission checklist.',
  candidatosememailcadastrado:
    'Candidate did not inform email. It is not possible to send emails to him.',
  veroupreenchercheckhabilidades: 'View or complete skills check',
  parecermin: 'opinion',
  tipoagendamento: 'Schedule type',
  envioform: 'Sending form / evaluation',
  entrevista: 'Interview',
  examemedico: 'Medical exam',
  avaliacaomedica: 'Medical evaluation',
  avaliacaopsicologica: 'Psychological evaluation',
  examepsicologico: 'Psychological exam',
  exametoxicologico: 'Toxicological exam',
  horarioinicio: 'Init hour',
  horarioinicioopcional: 'Init hour (optional)',
  horariotermino: 'Finish hour',
  horarioterminoopcional: 'Finish hour (optional)',
  tipolocal: 'Location type',
  clinicacadastradasistema: 'Clinic registered in the system',
  localcadastradosistema: 'Location registered in the system',
  localnaocadastradosistema: 'Location not registered in the system',
  enviarmensagempara: 'Send message to',
  lideranca: 'Leadership',
  clinicamin: 'clinic',
  mensagemlideranca: 'Message for leadership',
  mensagemcandidato: 'Message to candidate',
  informemensagem: 'Enter the message',
  informeemaillideranca: 'Enter leadership email',
  nomesliderancas: 'Leadership name(s)',
  verdetalhesagendamento: 'Show schedule details',
  congelarvaga: 'Freeze job',
  cancelarvaga: 'Cancel job',
  finalizarvaga: 'End job',
  descongelarvaga: 'Unfreeze job',
  editaroualterarstatusvaga: 'Edit information or change job status',
  certezacancelarvaga: 'Are you sure you want to cancel this job?',
  certezafinalizarvaga: 'Are you sure you want to finish this job?',
  vaganaoativa: 'The selected job is not currently active',
  mensagemaprovador: 'Message to the approver',
  informeremensagemaprovador:
    'Enter the text of the message to be sent to the approver',
  enviado: 'sent successfully!!!',
  numdiasprevistos: 'Number of days scheduled for realization',
  responsaveletapa: 'Responsible for the stage',
  diasrealizacao: 'Days for realization',
  informedescricaoetapa: 'Enter a description for the stage',
  informenumdias: 'Enter the number of days',
  informetelefonecelular: 'Enter the cell phone',
  informeemail: 'Enter email',
  selecionefuncionalidades:
    'Check the features that the position can access in the Macro app',
  permissoesmacro: 'Permissions on Macro',
  ecolaborador: 'Is collaborator?',
  admin_central_demarco: 'Central Demarco Administrator',
  administradorcentraldemarco: 'Central Demarco Administrator',
  erroaosalvarregistro: 'Error saving register',
  erroaoexcluirregistro: 'Error deleting register',
  exportarparaexcel: 'Export data to Excel',
  cadastradopor: 'Registered By',
  datacadastro: 'Registration Date',
  atualizadopor: 'Updated By',
  dataatualizacao: 'Update Date',
  transferencia: 'Transfer',
  emoji: 'Emoji',
  significadoemojis: 'Emojis meaning',
  informesignificadoemoji: 'Enter the emoji meaning',
  odio: 'Hate',
  desapontamento: 'Disappointed',
  neutro: 'Natural',
  bom: 'Good',
  excelente: 'Excellent',
  significadoemoji: 'Emoji meaning',
  mesesperiodoconcessivo: 'Number of months in the concessive period',
  informeperiodoconcessivo:
    'Enter the number of months in the concession period',
  periodo: 'Period',
  erroafastamento:
    'The record could not be inserted. Employee in work leave on the date/period informed',
  erroausencia:
    'The record could not be inserted. Employee absent on the date/period informed',
  erroferias:
    'The record could not be inserted. Employee on vacation on the date/period informed',
  copia: 'Copy',
  datainicioperiodo2invalida:
    'The start date of period 2 must be after the end of period 1',
  datainicioperiodo3invalida:
    'The start date of period 3 must be after the end of period 2',
  dataterminoperiodoinvalida:
    'The period end date must be after the start date',
  periodoconcessivoinvalido:
    'The number of months in the concessive period must be greater than 0',
  sebrasil: 'if Brazil',
  existepessoavinculadaaodocumento:
    'There is already a person linked to this document',
  limiterespostas: 'Maximum number of options selected in the response',
  selecionenomaximo: 'Select at most',
  opcoesmin: 'options',
  nessaquestao: 'on this question',
  informelimiterespostas:
    'Enter the maximum number of options that can be selected',
  limiterespostainvalido:
    'The maximum number of options selected must be greater than or equal to 1 and less than or equal to the number of response options',
  planilha: 'Worksheet',
  escolhaplanilha: 'Choose a worksheet or drag it here...',
  treinamentodemarcoacademy: `It's à Demarco Academy training`,
  cargouousalarioinvalido:
    'The position or salary must be different from the current one',
  novosalarionaopodesermenor:
    'The new salary cannot be less than the current salary',
  exportardadosresumidos: 'Export summary data',
  exportardadoscompletos: 'Export completed data',
  considerarcalculoabsenteismo: 'Consider when calculating absenteeism',
  considerarcalculoturnover: 'Consider when calculating turnover',
  importaradmissoes: 'Import admissions',
  importar: 'Import',
  dadosimportados: 'Data imported successfully!!!',
  registrosnaoimportados: 'Records not imported',
  IsWorkdayNotExist: 'workday not found',
  isNotEmpty: 'mandatory field(s) not filled in',
  isBoolean: 'must be YES or NO',
  isTrue: 'must be YES',
  isString: 'must be a textual value',
  isPhoneNumber: 'invalid phone number format',
  isTimeString: 'invalid time format',
  isDateString: 'invalid date format',
  isUrl: 'must be a URL address',
  isNumber: 'must be a numerical value',
  isPositive: 'must be a positive number',
  max: 'cannot be greater than the specified limit',
  min: 'cannot be less than the specified limit',
  isValidOption: 'must be a valid option',
  isArray: 'must be a list separated by ,',
  arrayContains: 'must contain only valid options',
  arrayUnique: 'all elements in the list must be unique',
  arrayNotEmpty: 'the list cannot be empty',
  IsPositionNotExist: 'position not found',
  IsUnityNotExist: 'unity not found',
  IsBondNotExist: 'bond not found',
  IsCompanyNotExist: 'company not found',
  IsRoleNotExist: 'role not found',
  IsReasonNotExist: 'admission reason not found',
  isPersonAlreadExists: 'duplicate record or previously admitted person',
  largeDocument: 'quantity reported above 20 characters',
  isEmail: 'invalid e-mail',
  vagasemcargo:
    'Job cannot be accessed because your position was not found in the system!!!',
  tiposausencias: 'Absences Types',
  motivosausencias: 'Absences Reasons',
  informetipoausencia: 'Enter the absence type',
  informedescricaotipoausencia: 'Enter the description of absence type',
  certezaexcluirtipoausencia:
    'Are you sure you want to delete this absence \n type?',
  motivoausencia: 'Absence reason',
  informemotivoausencia: 'Enter the absence reason',
  cadastreumtipoausencia: 'Register an absence type',
  motivo: 'Reason',
  consideracalculoabsenteismo: 'Does this consider absenteeism calculation?',
  exigedocumentacao: 'Does this require a supporting document?',
  tiposmin: 'types',
  certezaexcluirmotivoausencia:
    'Are you sure you want to delete this absence \n reason?',
  cadastreummotivoausencia: 'Register an absence reason',
  formasdemissao: 'Dismissal Forms',
  tiposdemissao: 'Dismissal Types',
  motivosdemissao: 'Dismissal Reasons',
  forma: 'Form',
  informeformademissao: 'Enter dismissal form',
  iniciativa: 'Initiative',
  informedescricao: 'Enter description',
  certezaexcluirforma: `Are you sure you want to delete this dismissal \n form?`,
  informetipodemissao: 'Enter dismissal type',
  formasdemisssaoassociadas: 'Associated dismissal types',
  formasmin: 'forms',
  certezaexcluirtipodemissao: `Are you sure you want to delete this dismissal \n type?`,
  formacadastrada: 'Dismissal form registered successfully!!!',
  formaatualizada: 'Dismissal form updated successfully!!!',
  formaexcluida: 'Dismissal form deleted successfully!!!',
  motivoadmissao: 'Admission reason',
  motivodemissao: 'Dismissal reason',
  informemotivodemissao: 'Enter the dismissal reason',
  tiposdemisssaoassociados: 'Associated dismissal types',
  motivodemissaoatualizado: 'Dismissal reason updated successfully!!!',
  motivodemissaocadastrado: 'Dismissal reason registered successfully!!!',
  certezaexcluirmotivodemissao: `Are you sure you want to delete this dismissal \n reason?`,
  motivodemissaoexcluido: 'Dismissal reason deleted successfully!!!',
  formademissao: 'Dismissal form',
  placeholderbuscaadmissao:
    'Enter a name, document, admission date, position or structure',
  placeholderbuscaafastamento:
    'Enter a name, document, position or work leave type',
  placeholderbuscaausencia:
    'Enter a name, document, position, type or reason for absence',
  placeholderbuscademissao:
    'Enter a name, document, position, form, type or reason for dismissal',
  placeholderbuscaferias: 'Enter a name, document or position',
  placeholderbuscapromocao:
    'Enter a name, document, position, salary or rise type',
  placeholderbuscaocorrencia:
    'Enter a name, document, position or occurrence type',
  placeholderbuscatransferencia:
    'Enter a name, document, position or transfer reason',
  placeholderbuscacargo: 'Enter position name or description',
  placeholderbuscaformulario: 'Enter form name or description',
  placeholderbuscanomeformulario: 'Enter the form name',
  placeholderbuscaavaliacao: 'Enter evaluation name or description',
  placeholderbuscavaga: 'Enter job name, name or email of approver',
  placeholderbuscacandidato: 'Enter candidate name or document',
  placeholderbuscaagencia: 'Enter agency name, city, state or country',
  placeholderbuscaclinica: 'Enter clinic name, city, state or country',
  placeholderbuscatreinamento:
    'Enter training name, description, keyword or grid',
  placeholderbuscapesquisa: 'Enter research name or description',
  placeholderbuscaciclo: 'Enter the name of the cycle',
  media: 'Medium',
  copiarregistrosde: 'Copy records from',
  formasdemissoes: 'Dismissal forms',
  tiposdemissoes: 'Dismissal types',
  motivosdemissoes: 'Dismissal reasons',
  tiposocorrencias: 'Occurrence types',
  tipospromocoes: 'Rise types',
  tipostransferencias: 'Transfer types',
  copiarde: 'Copy from client',
  copiarpara: 'Copy to client',
  registromin: 'record',
  importante: 'Important',
  textocopiaregistros:
    'If you do not import all the records at once, do the following:',
  tiposausenciaantesmotivo:
    'Import absences types before importing absences reasons',
  formademissaoantesdotipo:
    'Import dismissal forms before importing dismissal types',
  tiposdemissaoantesmotivo:
    'Import dismissal types before importing dismissal reasons',
  copiaconfiguracao: 'Configuration copy',
  erroaocopiarregistro: 'Error copying records',
  selecioneumoumaistiposderegistros: 'Select one or more record types',
  registroscopiados: 'Records copied successfully!!!',
  copiados: 'copied successfully!!!',
  tiposafastamentos: 'Work leave types',
  tiposjornada: 'Workday types',
  tiposvinculo: 'Bond types',
  tiposcontratacao: 'Hiring types',
  visibilidadeseraperdida: 'The visibility of data linked to it will be lost!',
  certezaexcluirunidade: 'Are you sure you want to delete this structure?',
  condicionarexibicao: 'Link to a previous answer',
  exibirse: 'Display if the answer to the question',
  foriguala: 'Equals',
  candidatonaoencontrado: 'No candidate was found with the document provided!',
  tempomaximoemminutos: 'maximum time to send responses (in min.)',
  administradorcliente: 'Client Administrator',
  admin_cliente: 'Client Administrator',
  tipocargo: 'Position type',
  tipocargomin: 'position type',
  tiposcargos: 'Position types',
  tiposcargosmin: 'position types',
  informetipocargo: 'Enter the position type',
  informedescricaotipocargo: 'Enter description of the position type',
  certezaexcluirtipocargo: 'Are you sure want to delete this position type?',
  nomecompleto: 'Full name',
  informelink: 'Enter the link',
  link: 'Link',
  duracao: 'duration',
  informeCPFvalido: 'Enter a valid CPF',
  templatecertificado: 'Certificate Template',
  somenteimagempng: 'only image with png extension',
  imageminvalida: 'The image extension must be .png',
  naopodeexcluiretapavinculadaavaga:
    'You cannot delete this step as it is linked to one or more vacancies',
  configuracoescliente: 'Client Configurations',
  modulosacessar: 'Modules it will access',
  modulosmin: 'modules',
  testecomporatamental: 'Behavioral profile test',
  integracaogeg: 'Integrate with Gente e Gestão',
  pesquisasecuritaria: 'Insurance research',
  realiza: 'Perform',
  modulos: 'Modules',
  configuracaocliente: 'Client configuration',
  somapontuacaoinvalida:
    'The sum of the score of the questions cannot be different from the maximum score of the evaluation. Sum of questions',
  cpfsebrasil: 'CPF number if you are from Brazil',
  erroetapaselecao:
    'You cannot delete this register as it is linked to one or more selection stages',
  arquivosabios: 'Result of the Sabios behavioral profile test',
  concordareceberwhatsapp:
    'Do you agree to receive messages from the selection process by Whatsapp?',
  enviarwhatsapp: 'Send Whatsapp?',
  documentojaregistradoparavaga:
    'There is already registration for this vacancy with the document provided',
  formularioavaliacaoprocessoseletivo: 'Selection process evaluation form',
  descricaoformularioavaliacaoprocessoseletivo:
    'On a scale of 1 to 10, with 1 being the lowest and worst value, how much would our selection process indicate to a friend or family member?',
  descricaonps: 'comment your answer',
  enviarnpsefeedback: 'Submit NPS and process feedback',
  feedbackcandidatoaprovado: 'Message for successful candidates',
  feedbackcandidatoreprovado: 'Mensagem para candidatos aprovados',
  feedbackcandidatoindefinido:
    'Message to other candidates (candidate bank or undefined status)',
  resultadonetpromoterscoretitulo: 'Net Promoter Score result',
  npstotalrespostas: 'Total responses',
  promotores: 'Promoters',
  neutros: 'Neutral',
  detratores: 'Detractores',
  filtrar: 'Filter',
  admitircandidato: 'Admit candidate',
  possuitestecomportamental: 'Does this job have a behavioral profile test?',
  certezaaprovarsemupload:
    'Really want to pass the candidate without uploading the \n behavioral profile test result?',
  etapasmin: 'stages',
  etapas: 'Stages',
  tiposagendamento: 'Schedule types',
  escolhaimagem: 'Choose an image or drag it here...',
  nomelink: 'Name of the link or text to be displayed',
  informenomelink: 'Enter the name of the link or text to be displayed',
  uploadcurriculo: 'Upload resume in the candidate register',
  uploadcurriculoopcao: 'The resume upload will be',
  opcionalmaiuscula: 'Optional',
  curriculo: 'Resume',
  escolhacurriculo: 'Choose a resume file or drag it here...',
  curriculomin: 'resume',
  datainscricao: 'Registration date',
  naopertenceagrade: 'Not linked to any grid',
  justificativasdemissao: 'Dismissal Justifications',
  justificativademissao: 'Dismissal justification',
  motivosdemisssaoassociados: 'Associated dismissal reasons',
  certezaexcluirjustificativademissao: `Are you sure you want to delete this dismissal \n justification?`,
  justificativademissaoatualizada:
    'Dismissal justification updated successfully!!!',
  justificativademissaocadastrada:
    'Dismissal justification registered successfully!!!',
  justificativademissaoexcluida:
    'Dismissal justification deleted successfully!!!',
  informejustificativademissao: 'Enter the dismissal justification',
  motivosdemissaoantesmotivo:
    'Import dismissal reasons before importing dismissal justifications',
  informepis: 'Enter the PIS (if Brazil)',
  cadastrados: ' successfully registered!!!',
  textoarquivos: 'To save add the AFDT file or the ACJEF file or both',
  extensaotxt: 'File extension must be txt',
  cadastradomin: 'registered',
  dataupload: 'Upload date',
  estes: 'these',
  excluidos: 'deleted successfully!!!',
  atualizados: 'updated successfully!!!',
  vernps: 'See NPS of',
  cargosantesetapas: 'Import positions before importing selection stages',
  ano: 'year',
  anomax: 'Year',
  anoreferenciamin: 'reference year',
  mesreferencia: 'reference month',
  enviarviawhatsapp: 'Send messages by Whatsapp',
  datafinalinvalida: 'The end date must be later than the start date',
  datainicial: 'Start date',
  datafinal: 'End Date',
  placeholderbuscahierarquia:
    'Enter a leader name or subordinate name of hierarchy',
  nomelider: 'Leader name',
  estruturalider: 'Leader structure',
  nomeliderado: 'Subordinate name',
  estruturaliderado: 'Subordinate structure',
  cargoliderado: 'Subordinate position',
  lembreatualizarhiearquia: `Update the leadership hierarchy, \nif applicable!!!`,
  novahierarquia: 'New hierarchy',
  liderados: 'Subordinates',
  lideradomin: 'subordinate',
  lidermin: 'leader',
  cadastreumalideranca: 'Register a leader to select',
  liderado: 'Subordinate',
  adicionar: 'Add',
  datapadrao: 'Default date',
  datapadraomin: 'default date',
  limpar: 'Clear',
  datapadraoselecionados: 'default date for selected',
  lideradosmin: 'subordinates',
  errodataadmissao:
    'The date must be greater than the service provision initial date of the subordinate and the leader',
  erroeventocolaborador: 'The employee have an event on the date informed',
  cadastreumliderado: 'Register a subordinate to select',
  estahierarquia: 'this hierarchy',
  aproveitarcandidatonavaga: 'Seize candidate in the job',
  inscricaocopiada: 'Registration copied successfully!!!',
  aproveitarcandidato: 'Seize candidate in another job',
  etapascargo: 'Stages by position',
  etapasantesselecaoporcargos:
    'Import positions, forms, evaluations and general selection stages before importing position selection stages',
  etapasporcargo: 'Position selection stages',
  datafinalmaioratual: 'The end date must be lesser than the current date',
  lideradojatemlideranca:
    'The subordinate already has an active leadership hierarchy',
  etapaanterior: 'Previous stage',
  etapaprimeira: 'This is the first stage',
  tenteacessarnovamente: 'Unable to access the system. Please try again later',
  datanascimentomaioratual:
    'The date of birth must be lesser than the current date',
  dataanoinvalido: 'The year of the date must be greater than 1900',
  etapatempesquisasecuritaria:
    'At this stage, Insurance Research is carried out',
  verresultadopesquisasecuritaria: 'View result of the insurance research',
  enviarpesquisasecuritaria: 'Send insurance research',
  referenciaspessoais: 'Personal References',
  referenciascomerciais: 'Commercial References',
  statusperfilsecutirario: 'Insurance Research Status',
  datavalidade: 'Expiration date',
  dataenvio: 'Send date',
  protocolopesquisa: 'Research protocol',
  status: 'Status',
  codigoperfil: 'Profile code',
  observacoes: 'Comments',
  excluirimagem: 'Delete image',
  imagemsemnome: 'Unnamed image',
  videosemnome: 'Unnamed video',
  comperfilsecuritario: 'With insurance research',
  carregardoformulario: 'Load answers from form',
  naoforamencontradasrespostasvalidas: 'No valid answers were found',
  outro: 'Other',
  solicitarhorarioausencia: 'Request start and end time',
  funcao: 'Role',
  informefuncao: 'Enter the role',
  informedescricaofuncao: 'Enter the role description',
  certezaexcluirfuncao: 'Are you sure want to delete this role?',
  funcoes: 'Roles',
  funcoesmin: 'roles',
  funcaomin: 'role',
  referenciasprofissionais: 'Professional references',
  tiposdocumentos: 'Document Types',
  nomedocumento: 'Document name',
  informenomedocumento: 'Enter the document name',
  informedescricaodocumento: 'Enter the document description',
  tipodocumento: 'Document type',
  certezaexcluirtipodocumento:
    'Are you sure want to delete this document type?',
  cadastreumtipodocumento: 'Register a document type',
  documentomin: 'document',
  certezaexcluirdocumento: 'Are you sure want to delete this document?',
  placeholderbuscadocumento:
    'Enter the collaborator name, structure, document type or send date',
  placeholderbuscacolaborador: 'Enter the collaborator name',
  ciclo: 'Cycle',
  ciclomin: 'cycle',
  elegiveis: 'Eligible',
  comite: 'Committee',
  resultados: 'Results',
  acompanhamento: 'Follow-up',
  de: 'From',
  ate: 'To',
  informeclassificacao: 'Enter the classification',
  informevalorinicial: 'Enter the initial value',
  informevalorfinal: 'Enter the final value',
  parametro: 'Parameter',
  certezaexcluirparametro: 'Are you sure want to delete this parameter?',
  valorincialmaiorigualzero:
    'The initial value must be greater than or equal to 0',
  valorfinalmenorigualcem: 'The final value must be less than or equal to 100',
  valorfinalmenorvalorinicial:
    'The final value must be greater than the initial value',
  faixasdeclassificacaoinvalidas:
    'Heads up!!! Rating ranges must start at 0 and end at 100',
  intervalosdefaixasinvalidos:
    'Heads up!!!! Rating ranges are not in correct sequence',
  enviowhatsapprecrutamento:
    'Sending whatsapp in the Recruitment and Selection module',
  cadastrar: 'Register',
  depositar: 'Deposit',
  consultar: 'Consult',
  adicionardocumento: 'Add document',
  excluirdocumento: 'Delete document',
  depositado: 'deposited successfully!!!',
  depositados: 'deposited successfully!!!',
  enviowhatsappcarreira:
    'Sending whatsapp in the Development and Career module',
  documentoselecionado: 'Selected document',
  workplace: 'Workplace',
  nomeciclo: 'Cycle name',
  tipoavaliacao: 'Evaluation type',
  escolhanome: 'Choose a name',
  haveracomite: 'There will be committee',
  tempodeempresa: 'Time in the company',
  comites: 'Committees',
  novociclo: 'Create New Cycle',
  gerarcomunicado: 'Generate Announcement',
  certezaexcluirciclo: 'Are you sure you want to delete this cycle?',
  zeroatresmeses: '0 to 3 months',
  tresaseismeses: '3 to 6 months',
  seisadozemeses: '6 to 12 months',
  acimadedozemeses: 'Over 12 months',
  tipoavaliacaonoventa:
    '90°: Collaborator evaluates himself - Leadership evaluates collaborator',
  tipoavaliacaocentoeoitenta:
    '180°: Collaborator evaluates himself - Leadership evaluates collaborator - Collaborator evaluates leadership',
  tipoavaliacaotrezentosesessenta:
    '360°: Collaborator evaluates himself - Leadership evaluates collaborator - Collaborator evaluates leadership - Guests can evaluate',
  datainicioinvalida: 'The start date must be greater than the current date',
  iniciodiautil: 'All steps must start on a business day.',
  datainicialmaiorquefinal: 'Start date cannot be greater than end date',
  datafinalmenorqueinicial: 'The end date cannot be less than the start date',
  erroaocadastrarciclo: 'An error occurred while trying to save cycle',
  follow: 'Follow-up',
  temposempresamin: 'home times',
  voltarparalista: 'Back to list',
  certezaexcluirelegivel: `Are you sure you want to delete this eligible?`,
  novoelegivel: 'New eligible',
  sucessoenvioemail: 'Emails sent successfully',
  errosaoenviaremail: 'Error sending emails',
  placeholderplanejamentocomite: 'Search by name, email or date',
  colaboradores: 'Employees',
  convidados: 'Guests',
  convidadosExternos: 'External guests',
  dataehorariorealizacao: 'Date/Time of realization',
  listadeconfirmacao: 'Confirmation list',
  conferencia: 'Conference',
  ou: 'or',
  obrigadocontribuicao: 'Thanks for the contribution',
  comparecerevento: 'Will attend this event?',
  evento: 'Event',
  talvez: 'Tentative',
  tituloModalConfirmacao: 'Confirmation List',
  emespera: 'On hold',
  recusado: 'Refused',
  aceito: 'Accept',
  linkcomite: 'Committee link',
  certezaexcluircomite: 'Are you sure you want to delete this committee?',
  selecionecolaboradoresavaliados: 'Select the colaborators to be evaluated',
  selecionepessoascomite:
    'Select the people who will participate in the committee',
  desajaincluirforaorganizacao:
    'Do you want to include people from outside your organization?',
  informedatahorariocomite: 'Enter the date and time of the committee',
  comitefisicoouconferencia:
    'Will the commission be held in a physical location or through a conference?',
  informeurlcomite:
    'Enter the url of the room where the committee will be held',
  infomerlocalcomite: 'Enter the location where the committee will take place',
  fisico: 'Physical',
  ambos: 'Both',
  colaboradoresmin: 'collaborators',
  datarealizacao: 'Realization date',
  erroaocadastrarcomite: 'An error occurred while trying to save committee',
  horariofinalmaiorqueinicial:
    'The end time must be greater than the start time',
  comitemin: 'committee',
  candidatosememailesemoptin:
    'Candidate did not inform email and did not agree to receive Whatsapp. It is not possible to send messages to him.',
  salveowhatsapp: `Save DMPeople's Whatsapp in your contacts, to facilitate the identification of messages`,
  naopodeagendarentrevistaeform:
    'It is not possible to schedule an Interview and Submission of form/evaluation simultaneously when sending Whatsapp',
  codigo: 'Code',
  informecodigocliente: 'Enter client code',
  informenomecliente: 'Enter client name',
  codigoclienteexiste: 'There is already a client with this code',
  nomeclienteexiste: 'There is already a client with this name',
  configuracoesacesso: 'Access settings',
  urlinvalida: 'Invalid URL',
  colaboradorsemlideranca:
    'There are collaborators without registered leadership.\nThey will not be included in the cycle. \nWould you like to continue?',
  oportunidademelhoria: 'Identified opportunity for improvement',
  motivoacao: 'Reason for action',
  desenvolverpontencializarcompetencia:
    'What are they going to do to develop or enhance the identified competence',
  quempodeajudar: 'Who can help them?',
  selecionecolaborador: 'Select a collaborator',
  adcionarnovaacao: 'New action',
  datafollow: 'Follow-up date',
  adicionarfollow: 'Add follow-up',
  removerfollow: 'Delete follow-up',
  acao: 'Action',
  removeracao: 'Delete action',
  erroaocadastrarplanodeacao: 'Error creating action plan',
  novoplanoacao: 'New action plan',
  certezaexcluirplanoacao: 'Are you sure you want to delete this action plan?',
  planoacaoexcluido: 'Action plan successfully deleted!!!',
  planoacaocadastrado: 'Action plan successfully included!!!',
  erroaoexcluirplanoacao: 'Error deleting action plan',
  semregistros: 'There are no records to show',
  elegivelexcluido: 'Eligible successfully deleted!!!',
  elegivelcadastrado: 'Eligible included successfully!!!',
  carregandoelegiveis: 'Selection of eligible is in progress',
  errocarregarelegiveis: 'Error selecting eligible',
  elegiveiscarregados: 'Selection of eligible completed successfully!!!',
  classificacoesepesos: 'Classifications and Weights',
  veracao: 'View action',
  editarplanoacao: 'Edit Plan',
  verplano: 'View plan',
  follows: 'Follow-ups',
  realizarfollow: 'Do follow-up',
  semfollowsvalidos: 'No valid follow-up so far',
  selecionefollow: 'Select a follow-up date',
  selecionefollowstatus: 'Select the statuses',
  actionstatusnotstarted: 'Not started',
  actionstatuspending: 'In progress',
  actionstatusdone: 'concluded',
  convite: 'Invite',
  avaliados: 'Evaluateds',
  numerodiasprevistos: 'Number of days expected to be carried out',
  informenumerodias: 'Enter the number of days',
  diaspararealizacao: 'Days to perform',
  certezaexcluiretapaciclo: 'Are you sure you want to delete this stage?',
  semautorizacao: 'You are not authorized to perform this operation',
  semAutorizacaoAtualizarFormulario:
    'You are not authorized to alter this form',
  semAutorizacaoAlterarCamposFormulario:
    'You are not authorized to change fields created by admin users',
  configuracoesvisibilidade: 'Visibility Settings',
  grupoempresas: 'Companies Group',
  ecossistema: 'Ecosystem',
  ecossistemamin: 'ecosystem',
  ecossistemas: 'Ecosystems',
  ecossistemasmin: 'ecosystems',
  nomeecossistema: 'Ecosystem name',
  informenomeecossistema: 'Enter the name of ecosystem',
  certezaexcluirecossistema: 'Are you sure you want to delete this ecosystem?',
  nomeecossistemaexiste: 'There is already a ecosystem with this name',
  existemunidadesnesseecossistema:
    'Cannot delete. There are units linked to this ecosystem',
  novousuario: 'New user',
  placeholderbuscausuario: 'Enter user email',
  pesquisar: 'Search',
  tiposusuarios: 'Users types',
  informetipousuario: 'Enter the user type',
  informedescricaotipousuario: 'Enter description of user type',
  tipousuario: 'User type',
  certezaexcluirtipousuario: 'Are you sure you want to delete this user type?',
  actionplan_duplicated:
    'It is not possible to create more than one action plan for the same collaborator per cycle',
  jobstage_blocked_with_candidates:
    'It is not possible to modify stages of vacancies that have registered candidates',
  acessototal: 'Full access',
  somenteleitura: 'Read only',
  semacesso: 'No access',
  cadastrardocumentos: 'Register documents',
  consultardocumentos: 'Consult documents',
  depositardocumentos: 'Deposit documents',
  depositardocumento: 'Deposit document',
  configuracoesgerais: 'General settings',
  controleferias: 'Vacation control',
  documentosferias: 'Vacation documents',
  programacaoferias: 'Vacations schedule',
  tipocontrato: 'Contract type',
  tipousuarioexiste: 'This user type already exists!!!',
  dataalteracaovinculo: 'Bond change date',
  novovinculo: 'New bond',
  vinculoanterior: 'Previous bond',
  arquivosrelacionadosalteracao: 'Chage related files',
  tipovinculoanterior: 'Previous type bond',
  tipovinculoatual: 'Current type bond',
  certezaexcluirvinculacao:
    'Are you sure you want to delete this document link?',
  vinculacaoexcluida: 'Document link deleted successfully!!!',
  vinculacaosalva: 'Document link saved successfully!!!',
  dataalteracao: 'Change date',
  certezaexcluirtipocontrato:
    'Are you sure you want to delete this contract type?',
  tipocontratocadastrado: 'Contract type registered successfully!!!',
  tipocontratoatualizado: 'Contract type updated successfully!!!',
  tipocontratoexcluido: 'Contract type deleted successfully!!!',
  placeholderbuscatipocontrato: 'Enter a name, document or bond type',
  emavaliacao: 'In evaluation',
  datasuperioradmissiao: 'Date must be later than admission date',
  previacampo: 'This is a preview of your field',
  arrastecampos: 'Drag fields to build your form',
  construindoformulario: 'Building form',
  visaopublica: 'Public view',
  mostrar: 'Show',
  ocultar: 'Hide',
  seacondicaofor: 'If the condition is',
  verdadeira: 'True',
  falsa: 'False',
  condicional: 'Conditional',
  seocenarioabaixoacontecer: 'If the scenario below happens',
  infomenomecondicional: 'Enter the conditional name',
  condicionaisemcampos: 'Conditionals onfields',
  exibaouocultecampos: 'Show or hide form fields according to user responses.',
  adcionarnovacondicional: 'Add new conditional',
  adicionarregra: 'Add rule',
  adicionargrupo: 'Add group',
  escolhacampo: 'Choose field',
  camposmostradosdifentesdeocultados:
    'Fields to be hidden must be different from those to be shown',
  novoformulario: 'Create New Form',
  verformulario: 'View Form',
  nomedoformulario: 'Form name',
  certezaexcluirformulario: 'Are you sure you wish to delete this form?',
  textodeajuda: 'Help text',
  textodedica: 'Tip text',
  campocontemcondicional: 'This field already contains a conditional',
  solteabaixo: 'Drop the desired fields below',
  and: 'AND',
  or: 'OR',
  tipogrupo: 'Group type',
  campoduplicadonaoadicionado: 'Duplicate conditional not added',
  certezaexcluircampoformulario:
    'This field is part of a condition. Are you sure you wish to delete this field?',
  certezaexcluirgrupoformulario: 'Are you sure you wish to delete this group?',
  linkinvalido: 'Invalid Link',
  autoCompleteColaborator: 'Select the eligible contributor',
  adicionartexto: 'Add text',
  paragrafo: 'Paragraph',
  informeparagrafo: 'Inform paragraph',
  informepesoresposta: 'Enter the answer weight',
  mostrarcabecalho: 'Do you want the header not to be hidden during replies?',
  formulariosalvo: 'Form saved successfully!!!',
  avaliacaosalva: 'Evaluation saved successfully!!!',
  certezaexcluiravaliacao: 'Are you sure you want to delete this evaluation?',
  usuarioduplicado: 'There is already a registered user with this email',
  certezaexcluirusuario: 'Are you sure you want to delete this user?',
  reativarusuario: 'Reactivate user',
  inativarusuario: 'Inactivate user',
  certezainativarusuario: 'Are you sure you want to inactivate this user?',
  usuarioinativado: 'User inactivated successfully!!!',
  erroaoinativarousuario: 'Unable to inactivate user',
  usuarioreativado: 'User reactivated successfully!!!',
  certezaexcluirconfiguracao:
    'Are you sure you want to delete this configuration?',
  placeholderbuscadocumentocliente: 'Enter the document type or send date',
  acessaanalytics: 'Has access to DMAnalytics reports and/or dashboards',
  descricaodocargo: 'Position description',
  etapasdeselecaodavaga: 'Job selection stages',
  requisicaodevaga: 'Job request',
  cadastroavaliacoes: 'Registration of evaluations',
  cadastroformularios: 'Registration of forms',
  listaciclos: 'List of cycles',
  analisardocumentos: 'Analyze documents',
  vinculardocumentos: 'Link documents',
  analisar: 'Analyze',
  numeroidentificacao: 'Identification number',
  numeroidentificacaocnpj: 'Identification number (CNPJ, if Brazil)',
  informenumeroidentificacao: 'Enter the company identification number',
  informenomeempresa: 'Enter the company name',
  homepage: 'Home page',
  informehomepageempresa: 'Enter the company home page',
  empresas: 'Companies',
  periodicidade: 'Frequency',
  selecioneperiodicidade: 'Select the frequency',
  naocorrencia: 'In the occurrence',
  diario: 'Daily',
  bimestral: 'Bimonthly',
  trimestral: 'Quarterly',
  cadastral: 'Of registration',
  fiscal: 'Fiscal',
  gestao: 'Management',
  saudeeseguranca: 'Health and Security',
  trabalhista: 'Labor',
  selecionecategoria: 'Select the category',
  complexidade: 'Complexity',
  texto: 'Text',
  comoobter: 'How to get',
  normasrelacionadas: 'Related standards',
  duvidasfrequentes: 'Frequently asked questions',
  links: 'Links',
  removerlink: 'Delete link',
  adicionarlink: 'Add link',
  textos: 'Texts',
  removertexto: 'Delete text',
  informetexto: 'Enter the text',
  rotinadiaria: 'Daily routine',
  naohouveregistro: 'There were no records in the reported period',
  naohouveoperacao: 'There was no operation in the reported period',
  periodosemregistro: 'Period without registration',
  periodosemoperacao: 'Period without operation',
  certezaexcluirrotinadiaria:
    'Are you sure you want to delete this daily routine?',
  ocorrencia: 'Occurrence',
  selecioneocorrencia: 'Select occurrence',
  admissional: 'Admission',
  monitoramento: 'Monitoring',
  demissional: 'Dismissal',
  selecionetipodocumento: 'Select document type',
  conternodossie: 'Contain in the dossier',
  novencimento: 'On maturity',
  novencimentoadmissao: 'On the post-admission due date',
  parametrizardocumentos: 'Parameterize documents',
  tiposempresas: 'Company Types',
  tipoempresa: 'Company type',
  informetipoempresa: 'Enter the company type',
  informedescricaotipoempresa: 'Enter the description of the company type',
  certezaexcluirtipoempresa:
    'Are you sure you want to delete this company type?',
  exigirdadosCNH: `Requires driver's license details`,
  avaliacaoRegraPontos:
    'Could not save! The sum of points must be equal to or less than the maximum value',
  confirmarPontosInsuficientes:
    'The sum of the points is less than the maximum value. Are you sure you want to save this form?',
  fieldIsRequired: 'This field is required!',
  invalidFormat: 'Invalid format!',
  numberTooSmall: 'The number is too small! Minimum: {0}',
  numberTooBig: 'The number is too big! Maximum: {0}',
  invalidNumber: 'Invalid number',
  invalidInteger: 'The value is not an integer',
  textTooSmall: 'The length of text is too small! Current: {0}, Minimum: {1}',
  textTooBig: 'The length of text is too big! Current: {0}, Maximum: {1}',
  thisNotText: 'This is not a text!',
  thisNotArray: 'This is not an array!',
  selectMinItems: 'Select minimum {0} items!',
  selectMaxItems: 'Select maximum {0} items!',
  invalidDate: 'Invalid date!',
  dateIsEarly: 'The date is too early! Current: {0}, Minimum: {1}',
  dateIsLate: 'The date is too late! Current: {0}, Maximum: {1}',
  invalidEmail: 'Invalid e-mail address!',
  invalidURL: 'Invalid URL!',
  invalidCard: 'Invalid card format!',
  invalidCardNumber: 'Invalid card number!',
  invalidTextContainNumber:
    'Invalid text! Cannot contains numbers or special characters',
  invalidTextContainSpec: 'Invalid text! Cannot contains special character',
  erroOpcoesIguais: 'Each option must be unique',
  informeumaopcao: 'Please enter at least one option',
  alteracoes: 'Changes',
  empresaatual: 'Current company',
  empresadestino: 'Target company',
  unidadenaovinculadaaempresa: `The selected unit is not linked to the selected company`,
  cadastreumaempresa: 'Register a company',
  empresadestinoinvalida:
    'The target company must be different from the current company',
  empresaselecionadainvalida: `The selected company is not linked to the selected unity`,
  cargoeousalario: 'Position and/or salary',
  funcaoatual: 'Current role',
  novafuncao: 'New role',
  obrigatoriosecargotemfuncao:
    'Mandatory if the position has one or more linked roles',
  certezaexcluircliente: 'Are you sure you want to delete this client?',
  parametrizar: 'Parameterize',
  nomefantasia: 'Trade name',
  informenomefantasiaempresa: 'Enter the trade name of the company',
  certezaexcluirempresa: 'Are you sure you want to delete this company?',
  justificativaspadrao: 'Default justifications',
  adicionarestruturasequencial: 'Add sequential structure',
  funcaoselecionadainvalida:
    'The new role cannot be the same as the current role',
  cadastreumafuncao: 'Register a role',
  datapromocaoanterioradmissao: `The rise date cannot be earlier than the collaborator's admission date`,
  datapromocaoanteriorinicioprestacao: `The rise date cannot be earlier than the collaborator's service provision starting date`,
  papellideranca: 'Leadership role',
  passouserlider: 'Became a leader',
  deixouserlider: 'Ceased to be a leader',
  competencia: 'Competence',
  datavencimento: 'Expiration date',
  tipodedocumento: 'Document type',
  tipodedocumentomin: 'document type',
  statusnaoenviado: 'Not sent',
  statusdepositado: 'Deposited',
  statuscancelado: 'Cancelled',
  statusfinalizado: 'Finished',
  enviardocumento: 'Send document',
  verdocumento: 'View document',
  editardocumento: 'Edit document',
  datepicker_label_prevDecade: 'Previous decade',
  datepicker_label_prevYear: 'Last year',
  datepicker_label_prevMonth: 'Last month',
  datepicker_label_currentMonth: 'Current month',
  datepicker_label_nextMonth: 'Next month',
  datepicker_label_nextYear: 'Next year',
  datepicker_label_nextDecade: 'Next decade',
  datepicker_label_today: 'Today',
  datepicker_label_selected: 'Selected date',
  datepicker_label_noDateSelected: 'No date selected',
  datepicker_label_calendar: 'Calendar',
  datepicker_label_nav: 'Calendar navigation',
  datepicker_label_help: 'Use the arrow keys to navigate the calendar',
  selecionarhoje: 'Select today',
  fechar: 'Close',
  multiselect_placeholder: 'Select one or more options',
  multiselect_select_label: 'Press enter to select',
  multiselect_select_group_label: 'Press enter to select group',
  multiselect_selected_label: 'Selected',
  multiselect_deselect_label: 'Press enter to remove',
  multiselect_deselect_group_label: 'Press enter to deselect group',
  multiselect_max_elements:
    'Maximum of {max} options selected. First remove a selected option to select another',
  feed: 'Feed',
  consultarcolaboradores: 'Consult collaborators',
  acessararquivos: 'Access files',
  cadastrararquivos: 'Register files',
  baseconhecimento: 'Knowledge base',
  certezaexcluirgrade: 'Are you sure you want to delete this grid?',
  formulariocopiado: 'Form copied successfully!!!',
  avaliacaocopiada: 'Evaluation copied successfully!!!',
  dataadmissaoinvalida:
    'The admission date must be less than or equal to the current date',
  datafinalsuperiorinvalida: 'The end date cannot be less than the start date',
  dataproximovencimento: 'Next expiration date',
  tolltipdataproximovencimento: 'Date the document should be re-deposited',
  placeholderbuscanomedocumento: 'Enter the document name',
  formularioparametrizacao: 'Parameterization form',
  trazerdadoscolaboradoresativosmes:
    'Bring data from active collaborators in the month',
  trazerdadoscolaboradoresdesligados: 'Bring data from disconnected employees',
  aceitanaoaplicavel: 'Accepts "Not Applicable"',
  aceitavinculacao: 'Accept Link',
  analisardocumento: 'Analyze Document',
  analisepelocliente: 'Customer review',
  reanalisepelocliente: 'Reanalyse by Client',
  gerabloqueio: 'Generate Block?',
  antecipavel: 'Is it anticipatory?',
  tempovalidacao: 'Validation time',
  parametrizacaodocumento: 'Parametrização de documento',
  informenomearquivo: 'Enter the file name',
  informedescricaoarquivo: 'Enter the file description',
  palavraschavearquivo: 'Keywords',
  videorelacionado: 'Related Video',
  comodisponibilizar: 'Linked to',
  arquivosoulinks: 'Add at least one file or item',
  arquivoporecossistemas: 'Link to ecosystems',
  arquivoporclientes: 'Clients, structures, and positions',
  placeholderbuscaarquivo: 'Enter a file name, description or keywords',
  certezaexcluirarquivo: 'Are you sure you want to delete this file?',
  linkdoform: 'Form Link',
  linkdowebsite: 'Dashboard Link',
  linkdovideo: 'Video Link',
  selecioneagrade: 'Select the grid',
  informacoesporcliente: 'clients, structs and positions',
  selecioneempresa: 'Select company',
  selecionecargo: 'Select position',
  responsavelanalise: 'Responsible for analysis',
  selecionecargoresponsavelporanalise:
    'Select the position responsible for analysis',
  atividadeespecial: 'Special activity',
  vinculacaodocumento: 'Document linking',
  resumidomin: 'summarized',
  completomin: 'full',
  periodicidadedocumento: 'Periodicity of presentation',
  categoriadocumento: 'Document category',
  complexidadeanalise: 'Complexity of Analysis',
  exibirdossiecolaborador: "Display in collaborator's dossier",
  justificativacancelamento: 'Cancellation justification',
  comoobterdocumento: 'How to get this document',
  normasdocumento: 'Document-related standards',
  duvidasdocumento: 'Doubts about the document',
  modelodocumento: 'Document template',
  fichadegente: 'People Sheet',
  remover: 'Remove',
  conteudo: 'Content',
  conteudos: 'Content',
  conteudomin: 'content',
  conteudosmin: 'content',
  informenomeconteudo: 'Enter the content name',
  informedescricaoconteudo: 'Enter the description of the content',
  palavraschaveconteudo: 'Keywords',
  conteudosoulinks: 'Add at least one content or item',
  escolhaconteudos: 'Choose one or more contents or drag them...',
  certezaexcluirconteudo: 'Are you sure you want to delete this content?',
  placeholderbuscaconteudo: 'Enter a name, description or content keywords',
  novaentrevista: 'New interview',
  recuperacaosenha: 'Password recovery',
  recuperarsenha: 'Recover password',
  usuarionaoencontrado: 'User not found',
  linkrecuperasenha: 'Link to reset password',
  termosdeuso: 'Terms of use',
  aceitotermosdeuso: 'I have read and accept the terms of use',
  dossiedocolaborador: 'Employee dossier',
  statusativo: 'Active',
  statusafastado: 'Away',
  statusausente: 'Absent',
  statusferias: 'On vacation',
  statusdesligado: 'Dismissed',
  statusdemitido: 'Fired',
  documentacao: 'Documentation',
  historicomovimentacao: 'Movement history',
  situacaoatualcolaborador: 'Current status of the employee',
  consultahierarquia: 'Consult hierarchy',
  collaborator: 'Collaborator',
  leaderships: 'Liderships',
  subordinates: 'subordinates',
  participantes: 'Participants',
  selecioneparticipantes: 'Select participants',
  liderancas: 'Liderships',
  avaliadoresdaetapa: 'Stage evaluators',
  avaliadonaetapa: 'Evaluated at stage',
  selecioneavaliadores: 'Select evaluators',
  selecioneavaliado: 'Select evaluated',
  adicionarlideradosdahierarquiadeestruturas:
    'Select subordinates from Leader structure hierarchy',
  adicionarlideradosforadahierarquiadeestruturas:
    'Select subordinates from another structure',
  considerarsomentenovasocorrencias:
    'Consider only occurrences made after document registration',
  naoaplicavel: 'Not applicable',
  criteriosdeaceitacao: 'Acceptance criteria',
  criacaocamposanalise: 'Creation of analysis fields',
  existeregistromovimentacao:
    'There are movement records for this employee after admission. Please delete these records before unregistering admission.',
  aceitamaisdeumarespostaporusuario: 'Accepts more than one answer per user',
  analiseanaoanalisada: 'Not analyzed',
  analiseanalisada: 'Approved',
  analisecancelada: 'Cancelled',
  reanalisar: 'Reanalyze',
  documentopossuideposito:
    'There are deposits for this document. If changed, current statuses can be updated. Do you wish to continue?',
  analizadopor: 'Analyzed by',
  dataanalise: 'Analysis date',
  historico: 'History',
  btableemptytext: 'There are no records to show',
  datadeposito: 'Deposit date',
  situacaocandidato: 'Candidate status',
  errocolaboradorativo:
    'Could not delete this structure as there are active collaborators linked to it or its substructures',
  contemanalise: 'Analyze document',
  'aviso-doc-nao-parametrizado':
    'Cannot parse document because it is not parameterized',
  verjustificativarelacionamento: 'See cancellation justification',
  porfavoraguardesuasconfiguracoes:
    'Please, while we load your login settings.',
  tipodedocumentoinvalido: `Invalid document extension! Accepted: {acceptTypes}`,
  statusexcluido: 'Excluded',
  parametrizacaotipodedocumentoexcluido:
    'This parameterization is for a deleted document and cannot be changed!',
  depositotipodedocumentoexcluido:
    'This deposit is for a deleted document and cannot be changed!',
  analisetipodedocumentoexcluido:
    'This analysis is for a deleted document and cannot be changed!',
  statusdocumento: 'Document status',
  datainiciomonitoramento: 'Monitoring start date',
  certezacancelardocumento: 'Are you sure you want to cancel this document?',
  documentocancelado: 'Document canceled successfully!!!',
  erroaocancelardocumento: 'Error canceling document',
  analisedocumento: 'Document analysis',
  impossivelexibirdocumento:
    'It is not possible to display this document, it may have been deleted.',
  documentopossuianalise:
    'This parameterization already contains analyzes made and cannot be changed',
  dadosdodepositante: 'Depositor data',
  dadosdocolaborador: 'Employee data',
  horadeposito: 'Deposit time',
  deposito: 'Deposit',
  analise: 'Analysis',
  verhistorico: 'View history',
  informecomentario: 'Enter the comment',
  pesquisaeanonima: 'The research is anonymous',
  idadeinvalida:
    'The age of the collaborator must be greater than or equal to 14 years',
  idadeadmissaoinvalida:
    'The age of the collaborator must be greater than or equal to 14 years on the date of admission',
  erroanoinferior1920: 'The year cannot be less than 1920',
  datainicioinferioradmissao: `The start date must be greater than the collaborator's service provision initial date`,
  datainiciosuperioratual:
    'The start date cannot be greater than the current date',
  anosuperior2090: 'The year cannot be greater than 2090',
  datainferioradmissao: `The date must be greater than the collaborator's admission date`,
  datainferiorprestacaoservicos: `The date must be greater than the collaborator's service provision starting date`,
  datasuperioratual: 'The date cannot be greater than the current date',
  dataterminoperiodosuperior3anos:
    'The vacation end date cannot be more than 3 years from the current date',
  desejadesvicularcliente:
    'If you unlink any customer, all units of that customer that are linked to the ecosystem will be unlinked. Do you wish to continue?',
  analiseserelatorios: 'Analysis and Reports',
  analises: 'Analysis',
  admissoes: 'Admissions',
  colaboradoresativos: 'Active collaborators',
  vercolaboradoresativos: 'See active collaborators',
  hierarquias: 'Hierarchies',
  rotinasdiarias: 'Daily Routines',
  tiposdecontratos: 'Contract types',
  depositos: 'Deposits',
  consultadedocumentos: 'Document consultation',
  selecioneanovaestruturasuperior: 'Select the new top structure',
  alterarestruturasuperior: 'Change top structure',
  existecandidatonavaga:
    'It is not possible to include, exclude or change stages, as there are already candidates registered for the vacancy',
  pesquisacomresposta: 'Research has linked answer, cannot be changed!',
  statusexcluida: 'Deleted',
  inspecoes: 'Inspections',
  novainspecao: 'New inspection',
  diautilfechamento: 'Closing business day',
  informediautilfechamento: 'Enter the closing business day',
  diautilinvalido: 'The day must be greater than 0 and less than 22',
  certezaexcluirvinculo: 'Are you sure you want to delete this bond?',
  selecionesituacao: 'Select candidate status',
  agendamentodoisoumaiscandidatos: 'Schedule for two or more candidates',
  candidatossememailcadastrado:
    'There are candidates who did not provide their email. Cannot send messages to them.',
  candidatossememailesemoption:
    'There are candidates who did not inform the email and did not agree to receive Whatsapp. Cannot send messages to them.',
  agendamentosemsegundoplano: 'Schedules being made in the background!!!',
  horarios: 'Schedules',
  copiarparatodos: 'Copy for all',
  isValidCPF: 'invalid document',
  isValidBirthDate: 'invalid birth date',
  isValidAdmissionDate: 'invalid admision date',
  datapromocaoposteriordataatual: `The rise date cannot be latter than the current date`,
  numerodediasaposadmissao: 'Number of days after admission',
  informenumerodedias: 'Enter the number of days',
  selecioneecosistema: 'Select ecosystem',
  ecossistemadocumento: 'Document ecosystem',
  realizado: 'Performed',
  realizada: 'Performed',
  organizacaoempresa: 'Organization / Company',
  trazerlistadecolaboradoresativos:
    'Bring list of active collaborators in the reference',
  documentocopiado: 'Document copied successfully!!!',
  aceitadepositovinculado: 'Accepts linked deposit',
  colaboradoresativosnacompetencia: 'Active collaborators in the competence',
  selecionepelomenosumapendencia: 'Select at least one competence',
  depositarpara: 'Deposit for',
  depositarparatodos: 'Deposit for all',
  analisecomdepositovinculado:
    'This analysis refers to a linked deposit, that is, it will analyze for several units. Do you wish to continue?',
  dadosdodeposito: 'Deposit data',
  aceiteparcial: 'Partial acceptance',
  veranaliseparcial: 'View partial analysis',
  colaboradoresnaoconforme: 'Nonconforming employees',
  selecioneostatusunidade: 'Select the status for each unit',
  apenasnovosregistrosaposdocumento:
    'Consider only records made after document registration',
  alterarregistrodeletado: 'Unable to change a deleted record',
  acidentedoencadotrabalho: 'Accident / Occupational Illness',
  salariomaiorpromocaorecente:
    'The salary cannot be greater than lattest rise salary',
  geracobrancadecat: 'Generates CAT requirement',
  admissaocontraida: 'Adm',
  demissaocontraida: 'Dis',
  geracobrancadedocumento: 'Generates document requirement',
  afastamentosuperiordias: 'Absence of more than 30 days',
  unidadescomexigenciadodocumento: 'Units with document requirement',
  hasWorkLeave: 'The employee is away at this time',
  AlreadyExistsWorkleaveError: 'The employee is away at this time',
  hasAbsence: 'The employee is absent during this period',
  AlreadyExistsAbsenceError: 'The employee is absent during this period',
  hasVacation: 'The employee is on vacation at this time',
  AlreadyExistsVacationError: 'The employee is on vacation at this time',
  hasTransfer: 'The employee has a transfer during this period',
  hasRise: 'The employee has a rise at this time',
  hasOccurrence: 'The employee has an occurrence during this period',
  hasBondChange: 'The employee has a bond change at this time',
  hasMovement: 'Unable to save record. There are movements after this movement',
  demissoesnaocadastradas: 'Dismissals not registered',
  ausenciasnaocadastradas: 'Absences not registered',
  superioratodoscolaboradores:
    'The date must be greater than the starting provisioning of all employees',
  demissoesemsegundoplano: 'Dismissals being made in the background!!!',
  ausenciasemsegundoplano: 'Absences being made in the background!!!',
  demissoescadastrado: 'Dismissals registered successfully!!!',
  ausenciascadastrado: 'Absences registered successfully!!!',
  tipodedemissaoobrigatorio: 'Dismissal type is mandatory',
  motivodemissaoobrigatorio: 'Dismissal reason is mandatory',
  feriasultrapassamolimiteconcessivo:
    'Launched vacations exceed the concessive limit, do you want to proceed?',
  acessaconsultaworkplace: `Access a list of collaborators on Workplace, even if they're not a collaborator`,
  naopossivelcancelaradmissao:
    'It is not possible to cancel admission. There are documents deposited for the collaborator',
  nomecampoexiste: 'A field with that name already exists',
  baixarmodelo: 'Download template',
  modeloimportacaoadmissao: 'admission_import_template',
  errodemissao:
    'The record could not be inserted. The collaborator was fired or had her admission canceled',
  exercepapeldegestor: 'Play manager role',
  EmployeeIsFiredError:
    'Unable to register. Employee dismissed or with canceled admission',
  erroexclusaomovimentacaoposterior:
    'The record could not be deleted. There are movements after this movement',
  AbsenceDateCannotBeLessThanAdmissionDateError:
    'Unable to register. Start date of absence is less than date of admission',
  AbsenceDateCannotBeLessThanServiceProvisionDateError:
    'Unable to register. Start date of absence is less than starting date of service provision',
  reabrirvaga: 'Reopen vacancy',
  desejareabrir: 'Do you really want to reopen this job?',
  datadecorte: 'Outage date',
  datacontabilizartempocasa: 'Date considered to account for the time spent',
  asrespostasseraoanonimas: 'Answers will be anonymous',
  possuiplanejamento: 'Has planning',
  tipoderesposta: 'Answer type',
  autoresposta: 'Answer about yourself',
  respostasobreoutrocolaborador: 'Answer about another collaborator',
  respostasobreoutrocolaboradorgeg: 'Answer about Gente e Gestão employees',
  prioridadedeavaliacao: 'Evaluation priority',
  gestor: 'Manager',
  tempomaximodevesermaiorquezero:
    'The maximum response time must be greater than or equal to 0',
  exigeinformacoesatestado: 'Requires certificate information',
  concordareceberwhatsappdaempresa:
    'Agree to receive messages from the company by Whatsapp',
  jaexisteregistroferiasparaessecolaborador:
    'There is already a vacation record for this employee in the informed vesting period. Update the existing record.',
  configuracaodociclo: 'Cycle Configuration',
  execucaodociclo: 'Cycle Execution',
  ciclodeavaliacoes: 'Evaluation Cycle',
  datadefim: 'End Date',
  etapasdociclo: 'Cycle Stages',
  regraseparametrosdociclo: 'Rules and Parameters',
  funcaoobrigatorio: 'The role field is required',
  funcoesobrigatorio: 'The roles field is mandatory',
  funcaoobrigatorioclient: 'The function is mandatory',
  algumregistrovazio: 'All mandatory fields must be completed',
  solicitacoes: 'Requests',
  disponibilizarformworkplace: 'Make form available on Workplace',
  desejaformwokplace:
    'Do you really want to make this form available on Workplace?',
  removerformworkplace: 'Remove from Workplace',
  desejaremoverformwokplace:
    'Do you really want to remove this form from Workplace?',
  erroaodisponibilizarworkplace: 'Could not link this form to Workplace',
  responderformulario: 'Fill form',
  identificacaodocolaborador: 'Collaborator identification',
  formularionaoencontrado: 'Form not found',
  semplanejamentosativos: 'No active planning',
  usuarionaoidentificado: 'User not identified',
  formdataforadoperiodopermitido:
    'Form cannot be answered. The date is outside the allowed period',
  formrespondidomaisdeumavez: 'Form cannot be answered more than once',
  aceitarespostasdatadetermino: 'Accept responses after end date',
  arquivorelacionados: 'Related files',
  tempoduracao: 'Duration',
  informetempoduracao: 'Inform the duration',
  quaispessoasparticiparao: 'Which people will participate in the training?',
  nomedocolaborador: 'Collaborator name',
  documentodocolaborador: 'Collaborator document',
  nomedousuario: 'User name',
  documentodousuario: 'User document',
  estruturadocolaborador: 'Collaborator structure',
  nomedoautor: 'Author name',
  documentodoautor: 'Author document',
  estruturadoautor: 'Author structure',
  naoeliderougerente:
    'You are not allowed to answer this form. Must be a leader or manager',
  formrespondidomaisdeumavezmesmapessoa:
    'Form cannot be answered more than once by the same person',
  selecioneumparticipante: 'Select a participant',
  enviarplanejamentoporparticipante: 'Send planning by participant',
  liderancaimediata: 'Immediate leadership',
  informetempomaximo: 'Enter the maximum time',
  duracaodevesermaiorquezero:
    'The duration time must be greater than or equal to 0',
  confirmarcolaboradoressememail:
    'There are one or more collaborators with no associated email. Do you wish to continue?',
  colaboradoressememail: 'Collaborator has no email associated',
  colaboradoressememailetelefone:
    'Collaborator does not have an email or telephone associated',
  horariodaresposta: 'Answer time',
  'interview-form-answer': {
    nomedocolaborador: "Interviewed collaborator's name",
    documentodocolaborador: "Interviewed collaborator's document",
    estruturadocolaborador: "Interviewed collaborator's structure"
  },
  'health-form-answer': {
    nomedocolaborador: "Inspected collaborator's name",
    documentodocolaborador: "Inspected collaborator's document",
    estruturadocolaborador: "Inspected collaborator's structure"
  },
  avaliadonomedocolaborador: "Evaluated collaborator's name",
  avaliadodocumentodocolaborador: "Evaluated collaborator's document",
  avaliadoestruturadocolaborador: "Evaluated collaborator's structure",
  formulariodisponibilizado: 'Form successfully available',
  enviowhatsappformulario: 'Send whatsapp to access forms',
  confirmarcolaboradoressememailetel:
    'There are one or more collaborators without email or telephone associated. Do you wish to continue?',
  funcoesparticipantes: 'Participating roles',
  quaisfuncoesdevemparticipar: 'Which roles should participate?',
  apartirde: 'From',
  colaboradorsememailesemoptin:
    'Collaborator did not inform email and did not agree to receive Whatsapp. Unable to send messages to him.',
  colaboradoressememailesemoptin:
    'There are one or more collaborators who did not inform email and did not agree to receive WhatsApp. Can not send messages to them.',
  formularioremovido: 'Form successfully removed',
  permitirusuariocomumaddcampo: 'Allow regular users to add fields',
  campoadmin: 'Field created by administrator - Not editable',
  horariossaoobrigatorios: 'Times are required',
  reprovarcandidato: 'Disapprove candidate',
  certezareprovarcandidato:
    'Are you sure you want to disapprove this candidate?',
  candidatoreprovado: 'Candidate successfully disapproved!!!',
  erroaoreprovarcandidato: 'Error disapproving candidate!!!',
  erroexclusaocolaboradornaoexiste:
    'You cannot delete this register because the collaborator is disconnected',
  esteformularioeglobal: 'This form is global',
  visibilidade: 'Visibility',
  vincularatodososclientes: 'Link to all clients',
  vincularaumoumaisecossistemas: 'Link to one or more ecosystems',
  usuarionaocolaborador: 'You are not a client collaborator',
  gerademandadereposicao: 'Generate replacement demand',
  gerademandadecontratacao: 'Generate hiring demand',
  colaboradoressubstituidos: 'Employees to be replaced',
  informecolaboradoressubstituidos: 'Enter the employees who will be replaced',
  colaboradormaiorquequantidadevaga:
    'It is not possible to select more collaborators than the number of jobs',
  informeadatadefechamento: 'Enter the closing date',
  errodatadefechamentoinvalida: 'Invalid closing date',
  erropessoasouestruturasativas:
    'It is not possible to delete this company because there are active collaborators or structures associated with it',
  prontuariodocolaborador: "Collaborator's record",
  tipodemovimentacao: 'Movement type',
  selecioneummovimentacao: 'Select the movement type',
  templatededatacomhorario: 'YYYY/MM/DD [at] HH:mm',
  dataexclusao: 'Deletion Date',
  excluidopor: 'Deleted By',
  erroempresasouestruturasativas:
    'It is not possible to delete this client because there are active companies or structures associated with it',
  days_after_admission: 'Days after admission',
  admitted_until: 'Admitted until',
  general: 'General',
  aplicacaociclo: 'Cycle application',
  aplicacaociclomin: 'cycle application',
  tiposdevinculos: 'Types of Links',
  tiposdevinculosmin: 'types of links',
  informediasaposadmissaoiniciaociclo:
    'Inform how many days after admission the cycle starts',
  admitidosate: 'Admitted until',
  selecionedatacorteadmitidos: 'Select cut-off date for admitted',
  datainicialmenorqueciclo:
    'The start date of the step cannot be earlier than the start date of the cycle',
  impossivelcadastrarciclo:
    'It is not possible to register this cycle. There are employees without a registered leadership hierarchy',
  excluirelegiveis: 'Delete eligible',
  naoutilizair: 'Do not use index of results in this cycle',
  porcentagemir: '(% RI - Results Index)',
  valorinicialporcentagem: 'Enter the initial value in %',
  valorfinalporcentagem: 'Enter the final value in %',
  parecerderesultadoir: 'Result Opinion RI',
  parecerderesultadoirmin: 'result opinion RI',
  informeparecerderesultadosir: 'Inform the name of the opinion of RI results',
  arvoredeclassificacoes: 'Classification tree',
  irciclo: 'RI',
  iccciclo: 'BCI',
  indicederesultados: 'Results index',
  indicederesultadosmin: 'results index',
  result_is_not_unique: {
    results_index: 'There cannot be two result opinions with the same name',
    compliances_index: 'There cannot be two result opinions with the same name',
    classification_tree:
      'There cannot be two classifications with the same name'
  },
  pairs_is_not_unique:
    'There cannot be two classifications with the same RI and BCI',
  invalid_from_or_to_field:
    'Percent ranges cannot overlap already created records',
  certezaexcluirir: 'Are you sure you want to delete this index of results?',
  porcentagemicc: '(% BCI - Behavioral Compliance Index)',
  parecerderesultadoicc: 'Opinion of Results BCI',
  parecerderesultadoiccmin: 'opinion of results BCI',
  informeparecerderesultadosicc: 'Inform the opinion of the results of the BCI',
  pesodaautoavaliacao: 'Self-assessment weight',
  informepesoautoavaliacao: 'Inform the weight (%) of the Self-Assessment',
  pesodaavaliacaodedesempenho: 'Performance Evaluation Weight',
  informeopesodaavaliacaodedesempenho:
    'Inform the weight (%) of the Performance Evaluation',
  valormenorque0oumaiorque100:
    'The value cannot be less than 0 or greater than 100',
  sum_of_weights_different_from_100: 'The sum of weights must total 100',
  indicedeconformidadecomportamental: 'Índice de conformidade comportamental',
  indicedeconformidadecomportamentalmin:
    'índice de conformidade comportamental',
  certezaexcluiricc:
    'Are you sure you want to delete this behavioral compliance index?',
  certezaexcluirarvoredeclassificao:
    'Are you sure you want to delete this classification?',
  result_index_is_required_in_this_cycle:
    'Result index is required in this cycle',
  this_cycle_no_accept_result_index: 'This cycle does not use result index',
  parecerderesultados: 'Opinion of results',
  alterarliderancaparacolaborador: 'I want to change leadership to an employee',
  alterarliderancadeumgrupo: 'I want to change the leadership of a group',
  liderancanociclo: 'Leadership in the cycle',
  selecioneoliderparaociclodeavaliacoes:
    'Select the leader for the evaluation cycle',
  personalizarhierarquia: 'Customize hierarchy',
  placeholderbuscalideranca: 'Enter a hierarchy leader or subordinate name',
  valormaiorquezero: 'Value must be greater than zero',
  estecolaboradornaotemliderança: 'This employee has no leadership',
  hierarquiapersonaliza: 'Custom hierarchy',
  certezaexcluircadastrodelegivel: `Are you sure you want to delete this eligible record?`,
  subordinate_and_cycle_leadership_must_be_different:
    'The subordinate needs to be different from the leadership',
  subordinate_already_exists_for_this_cycle:
    'A custom hierarchy already exists in this cycle for this employee',
  subordinate_hierarchy_not_found: 'Current hierarchy not found',
  subordinate_is_invalid: 'This employee no longer belongs to this client',
  cycle_leadership_is_invalid: 'The leader no longer belongs to this client',
  cycle_leadership_not_is_leadership:
    'The employee selected as a leader is no longer a leader',
  hierarquiaspersonalizadasemsegundoplano:
    'Custom hierarchies being done in the background!!!',
  hierarquiaspersonalizadascadastradas:
    'Custom hierarchies successfully registered!!!',
  selecioneumcolaborador: 'Select an employee',
  capacitacaoesensibilizacao: 'Training and Sensitization',
  autoavaliacao: 'Self-assessment',
  avaliacaodedesempenho: 'Performance Evaluation',
  avaliacaoderesultados: 'Results Evaluation',
  reuniaodegente: 'People Meeting',
  planodeacao: 'Action Plan',
  followplanodeacao: 'Action Plan Follow',
  naovourealizaretapanociclo: 'I will not perform this step in the cycle',
  informediasaposadmissaoiniciaaetapa:
    'Inform how many days after admission the stage starts',
  selecioneoformularioassociadoaetapa:
    'Select the form associated with this step',
  informeclassificacoesqueparticipamdaetapa:
    'Inform the classifications that participate in the stage',
  classificacoes: 'Ratings',
  classificacoesquerealizampdi: 'Classifications that perform PDI',
  classificacoesquerealizampr: 'Ratings that perform PR',
  informeasclassificacoesquerealizampdi:
    'Inform the classifications that carry out PDI',
  informeasclassificacoesquerealizampr: 'Inform the ratings that perform PR',
  alteraclassificacao: 'Change classification',
  quantidadedefollows: 'Number of follows',
  informeaquantidadedefollows: 'Enter the number of follows',
  informeasclassificacoesqueparticipamdaetapa:
    'Inform the classifications that participate in the stage',
  datadeiniciodo: 'Start date of',
  datadeterminodo: 'End date of',
  proximaetapa: 'Next step',
  datafinalmaiorquefinalciclo:
    'The end date cannot be later than the end date of the cycle',
  etapajainiciada:
    'The start date cannot be changed, as this step has already started',
  valordevesermaiorqueum: 'Value must be greater than or equal to 1',
  quantosdiasaposadmissaoocorreraofollow:
    'In how many days after admission will the Follow occur',
  certezaexcluirirs:
    'Registered results indexes will be deleted. Do you really want to delete them?',
  nomedaetapa: 'Step name',
  etapaspersonalizadasdociclo: 'Custom cycle steps',
  incluirnocalculodeaderenciadociclo: 'Include in cycle adherence calculation',
  enviaretapaparatodososcolaboradores: 'Send the step to all collaborators',
  enviaretapaparaclassificacoesselecionadas: 'Send step to selected ratings',
  informeonomedaetapa: 'Inform the name of the step',
  selecioneaetapaanteriordociclo: 'Select the previous stepof the cycle',
  selecioneestruturasenviaretapa:
    'Select one or more structures to which you want to send this step',
  selecioneumoumaiscargosparaenviaretapa:
    'Select one or more jobs to send this step',
  selecionequaiscolaboradoresenviaretapa:
    'Select which employees this step will be sent to',
  selecioneasclassificacoesqueparticipamdaetapa:
    'Select the classifications that participate in the stage',
  days_after_admission_invalid:
    'The number of days after admission cannot be less than that informed in the previous step',
  days_after_admission_invalid_trainingandawareness:
    'The number of days after admission cannot be less than that informed in the cycle registration',
  invalid_end_date:
    'The end date must be less than or equal to the end date of the cycle',
  invalid_start_date:
    'The start date of the step cannot be less than the start date of the previous step',
  invalid_start_date_trainingandawareness:
    'The start date of the stage cannot be earlier than the start date of the cycle',
  other_steps_depend_on_this: 'Other steps depend on this',
  start_date_greater_than_child:
    'The start date must be less than the start date of dependent steps',
  datafollowinferioraantecessor:
    'The start date must be less than or equal to the start date of subsequent follows',
  datafollowsuperioraposterior:
    'The start date of the follow cannot be less than the start date of the previous follow',
  datainicialmenorqueatual:
    'The start date of the step cannot be earlier than the current date',
  dataciclomaiorqueatual:
    'The cycle start date must be greater than the current date',
  diasfollowsuperioraposterior:
    'The days after admission must be greater than or equal to the days after admission of previous follows',
  diasfollowinferioraantecessor:
    'The days after admission must be less than or equal to the days after admission of subsequent follows',
  dataterminoalteradainvalida:
    'As the cycle has already started, the end date can only be changed to a later date than the one informed in the cycle registration',
  cicloincompleto:
    'Please complete the registration of the stages of this cycle before attempting to duplicate or copy',
  clienteinvalido: 'Invalid client',
  has_answers_in_this_step:
    'The form associated with this step already contains answers, it is not possible to change it',
  ciclocopiadosucesso: 'Cycle successfully copied!!!',
  cicloduplicadosucesso: 'Cycle successfully duplicated!!!',
  same_name_as_custom_step: 'Another custom step with this name already exists',
  same_name_as_default_step: 'Cannot save custom steps with default steps name',
  incluir: 'Add',
  informediasaposadmissaoterminaciclo:
    'Inform the limit of days after admission to participate in the cycle',
  valordevesermaiorouigualosdiasaposadmissao:
    'The limit of days after admission must be greater than or equal to the initial value',
  days_after_admission_limit_invalid:
    'The number of days after admission cannot exceed the limit entered in the cycle registration',
  daysAfterAdmission_greater_than_child:
    'The number of days after admission must be less than the number of days after admission for steps dependent on this',
  previous_step_invalid: 'Invalid previous step',
  has_custom_steps_as_child:
    'It is not possible to disable this step, as there are custom steps that depend on it',
  this_client_has_collaborators_without_hierarchy:
    'The client selected for copying has collaborators without registered leadership hierarchy',
  nomedalideranca: 'Leadership name',
  selecioneosliderados: 'Select the followers',
  mensagemcomunicadociclo:
    'Our development and career assessment is about to begin, one of the main People Management processes in our company.\n\nThe development and career process aims to analyze the performance of employees, through self-assessment, assessment of leaders, verification of achievement of goals, through indicators generate a classification and propose actions aimed at personal development.\n\nGet ready, because the process will take place on the following dates:',
  ola: 'Hi',
  nomecolaboradorvinculadoaociclo: 'Name of the employee linked to the cycle',
  colaboradorsemhierarquia:
    'Unable to add this eligible, contributor with no defined leadership hierarchy',
  naopodeexcluirformvinculoaetapa:
    'It is not possible to delete this form because it is linked to one or more cycle stages',
  informacoesdociclo: 'Cycle Information',
  enviodeformulariosporetapa: 'Submission of forms by step',
  etapaspadraodociclo: 'Standard cyclesteps',
  statusetapaspadraodociclo: 'Cycle default steps status',
  selecioneociclo: 'Select the cycle',
  naoliderougerentenaoelegivelciclo:
    'You are not a leader/manager and you are not eligible for a cycle',
  naoepossivelresponderpoisexistemetapasnaoconcluidas:
    'It is not possible to respond to this employee, as there are previous steps that have not been completed',
  faroldeavaliacoes: 'Evaluation Beacon',
  on_delete_rule_and_parameter_cycle_started:
    'It is not possible to delete this record as the cycle has already started',
  on_update_rule_and_parameter_cycle_started:
    'It is not possible to change this record because the cycle has already started',
  this_record_is_being_used_in_classifications_tree:
    'Cannot delete this record as it is being used in the classifications tree',
  salvareimprimir: 'Save and print',
  classificacaocomir:
    'Cannot change result indices while there are classifications',
  ircicloiniciado:
    'It is not possible to change the result indexes because the cycle has already started',
  selecionelideranca: 'Select the leadership',
  selecionestatusdesejavisualizar: 'Select which statuses you want to view',
  realizacao: 'Fulfillment',
  datalimite: 'Deadline',
  sensibilizacao: 'Awareness',
  okforadoprazo: 'OK - Out of Time',
  inputdemetas: 'Goals input',
  exercepapeldegestordeciclo: 'Plays the role of evaluation cycle manager',
  cycle_information_step_status_not_started: 'Step Not Started',
  cycle_information_step_status_progress: 'Step In Progress',
  cycle_information_step_status_complete: 'Step Completed',
  statusetapascustomizadadociclo: 'Custom Cycle Steps',
  cycle_information_modal_title_send_step_form:
    'Submit Form - {formName} per participant',
  cycle_information_modal_title_view_step_form: 'Form - {formName}',
  cycle_information_sent_to_eligible_success: 'Form sent to participants',
  selecionequaisparticipantesenviaretapa:
    'Select which participants this step will be sent to',
  selecione_um_ou_mais_participantes: 'Select one or more participants',
  meta: 'goal',
  atingimento: 'achievement',
  informeameta: 'Inform the goal',
  abreviacaoexemplo: 'E.g.',
  calcular: 'calculate',
  avaliacoeseinspecoes: 'Evaluations and Inspections',
  etapatemtestecomportamental:
    'At this stage, a behavioral profile test is carried out',
  historicodocolaborador: 'Collaborator history',
  cicloanterior: 'Previous cycle',
  consultaravaliacoes: 'Consult evaluations',
  cicloatual: 'Current cycle',
  registrodareuniao: 'Meeting Record',
  classificacaocicloanterior: 'Previous cycle classification',
  classificacaosugerida: 'Suggested rating',
  classificacaofinal: 'Final classification',
  comentariodareuniao: 'Meeting comments',
  resposta: 'Answer',
  selecioneaprimeiraavaliacao: 'Select the first review',
  selecioneasegundaavaliacao: 'Select the second review',
  compararavaliacoes: 'Compare ratings',
  voltarparavisualizacaounica: 'Go back to single view',
  classificacaofinaldociclo: 'Final classification of the cycle',
  informeaclassificacaofinaldociclo:
    'Inform the final classification of the cycle',
  comentariosdareuniao: 'Meeting comments',
  participantesdareuniaodegente: 'People meeting participants',
  the_cycle_has_ended: 'This cycle has already ended',
  cycle_not_found: 'Cycle not found',
  step_not_found: 'Step not found',
  performance_evaluation_soma_dos_pesos_diferente_de_cem:
    'The sum of the weights is different from 100%',
  performance_evaluation_meta_cadastrada_com_sucesso:
    'Goal registered successfully',
  performance_evaluation_meta_atualizada_com_sucesso:
    'Goal updated successfully',
  performance_evaluation_meta_salva_com_sucesso: 'Goal saved successfully',
  performance_evaluation_metas_salvas_com_sucesso: 'Goals saved successfully',
  performance_evaluation_metas_cadastradas_com_sucesso:
    'Goals registered successfully',
  performance_evaluation_meta_excluida_com_sucesso: 'Goal deleted successfully',
  certezaexcluirmeta: 'Are you sure you want to delete this goal?',
  placeholderbuscaplanoacao: 'Enter the plan action name',
  dataaprovacao: 'Approval date',
  aprovarreprovarplano: 'Approve/Disapprove Plan',
  cadastrodoplano: 'Plan registration',
  analisedecausa: 'Cause analysis',
  motivodaacao: 'Reason for action',
  tipodaacao: 'Type of action',
  porquefazeressaacao: 'Why am I going to do this action?',
  comorealizaracao: 'How am I going to perform this action?',
  desenvolvercompetencia: 'Develop competence',
  potencializarcompetencia: 'Enhance competence',
  onthejob: 'On the job',
  interacaonetworking: 'Interaction / Networking',
  treinamentoestudo: 'Training / Study',
  outros: 'Other',
  informeotipodeacao: 'Inform the type of action',
  informemotivoacao: 'Inform the reason for action',
  adicionarnovaacao: 'Add new action',
  aprovarplano: 'Approve Plan',
  concluircadastro: 'Complete registration',
  causasbaixodesempenho: 'Causes of low performance',
  analisecincoporques: 'Analysis of the 5 whys',
  selecioneoformulario: 'Select the form',
  selecioneasquestoesquedescrevemobaixodesempenho:
    'Select the questions that describe the low performance',
  apenassalario: 'Salary only',
  mapeamento: 'mapping',
  mapeamentoassociado: 'Associated mapping',
  potencialbrutoparaaprovacao: 'Minimum raw potential for approval (%)',
  textopotencialbrutoaprovacao:
    'Minimum value of raw potential so that the candidate can advance to the next phase of the selection process',
  informepotencialbrutoparaaprovacao:
    'Enter the minimum amount of raw potential for approval',
  matchparaaprovacao: 'Minimum match for approval (%)',
  textomatchaprovacao:
    'Minimum match value so that the candidate can advance to the next phase of the selection process',
  informematchparaaprovacao: 'Enter the minimum match value for approval',
  potencialbruto: 'Raw potential',
  match: 'Match',
  cliqueaquiparaverorelatorio: 'Click here to see the report',
  oscincoporques: 'THE 5 WHYS',
  causasdoproblema: 'Causes of the problem',
  porque: 'Why',
  aprovar: 'Approve',
  reprovar: 'Disapprove',
  reprovaracao: 'Disapprove action',
  aprovaracao: 'Approve action',
  justificativadareprovacao: 'Justification for Disapproval',
  itemsalvo: 'Item saved!',
  concluir: 'Finish',
  acaoreprovadacomsucesso: 'Action disapproved successfully!!!',
  certezaexcluiracao: 'Are you sure you want to delete this action?',
  cadastrecausasnaabacausasdobaixodesempenho:
    'Register the causes in the "Causes of low performance" tab',
  analisedos5porquesconcluida:
    'Analysis of the 5 Whys successfully completed!!!',
  digitepelomentos3caracteres:
    "Type at least 3 characters to search for the collaborator's name or document",
  placeholderbuscacolaboradornomeedocumento:
    "Type the collaborator's name or document",
  acaoreprovada: 'Action Disapproved',
  historicodeacoesreprovadas: 'History of Disapproved Actions',
  naohaacoesreprovadas: 'There are no actions disapproved',
  datadaacao: 'Action Date',
  hierarquiapersonalizadanaocriada: 'Custom hierarchies not created',
  cargosvinculados: 'Related positions',
  clientnaopossuinenhumcargo: 'The client does not have any of the positions',
  selecioneumfollow: 'Select a follow',
  justificativadareprogramacao: 'Justification of the reprogramming',
  statusdaacao: 'Action status',
  anexar: 'Attach',
  acaonoprazo: 'On time',
  acaoconcluida: 'Completed',
  acaocancelada: 'Cancelled',
  acaoatrasada: 'Delayed',
  comentariosdofollow: 'Follow comments',
  baixararquivo: 'Download file',
  datainicioinferioracriacaodaacao:
    'The start date cannot be earlier than the plan approval date',
  recomendavelseraposreuniaodegente:
    'When sending a stage by classification, it is recommended that the previous stage be a meeting of people or later',
  vocejarealizouestaetapa:
    'You have already completed this step. It is not possible to answer this form again.',
  dadosdodocumento: 'Document data',
  alterarsituacao: 'Change status',
  alterarsituacaoparatodos: 'Change status for everyone',
  alterarsituacaodoscandidatos: 'Change candidate status',
  alterarsituacaopara: 'Change status to',
  candidatosselecionados: 'Selected candidates',
  candidatosatualizadoscomsucesso: 'Candidates updated successfully!!!',
  erroaoatualizarsituacaodecandidatos:
    'An error occurred when trying to update the candidate status',
  parecerfinaldaetapa: 'Final stage opinion',
  monitoramentomin: 'monitoring',
  informepelomenosumaformadecontato:
    'Enter at least one form of contact (email or phone)',
  emailIsRequired: 'Email is a required field',
  emailOrPhoneIsRequired:
    'Enter at least one form of contact, email or telephone',
  emailMustBeAnEmail: 'Invalid email format',
  hasProfileNotAnswered: 'There are fields not filled in on the Profile tab',
  hasExperienceNotAnswered: 'There are unfilled fields in the Experience tab',
  dataterminomonitoramento: 'Monitoring end date',
  datafinalmenorouigualatual:
    'Monitoring end date must be less than or equal to the current date',
  datafinaldomonitoramentoinferioradatadeinicio:
    'Monitoring end date cannot be less than or equal to the monitoring start date',
  colaborators_linked_to_the_unity:
    'Unable to update this structure as there are active collaborators linked to it',
  has_movement_after_monitoring_end_date:
    'It was not possible to update this structure, as there are movements after the monitoring end date',
  unity_already_inactive:
    'It was not possible to update this structure, as it is already inactive',
  has_unity_child_monitored:
    'It was not possible to update this structure, there are child structures still monitored.',
  unidadenaoemaismonitorada:
    'Unable to save data, structure is no longer monitored',
  erroaoselecionarestruturanaomaismonitorada:
    'It was not possible to select the structure, as it is no longer monitored',
  naoepossivelatualizarpoisaestruturanaoemaismonitorada:
    'It is not possible to update as the structure is no longer monitored',
  unityNoLongerMonitored:
    'It is not possible to save, as the structure is no longer monitored',
  oldUnityNoLongerMonitored:
    'It is not possible to save, as the current structure is no longer monitored',
  newUnityNoLongerMonitored:
    'It is not possible to save, as the target structure is no longer monitored',
  unidadeinativa: 'Inactive unity',
  subordinateUnityNoLongerMonitored:
    'Unable to save, as the subordinate structure is no longer monitored',
  leadershipUnityNoLongerMonitored:
    'Unable to save as the leader structure is no longer monitored',
  oldUnityNoLongerMonitoredOnDelete:
    'Unable to delete, as the current structure is no longer monitored',
  newUnityNoLongerMonitoredOnDelete:
    'Unable to delete, as the target structure is no longer monitored',
  unitsNoLongerMonitored:
    'It is not possible to save, as one of the structures is no longer being monitored',
  unityNoLongerMonitoredOnDelete:
    'Unable to delete as the structure is no longer monitored',
  datainicialinferiora: 'Start date less than ',
  adicionardata: 'Add date',
  datainferioraminima:
    'It is not possible to save, as there is a starting date below the minimum allowed',
  datafinalnaopreenchida:
    'It is not possible to save, as there is an unfilled monitoring end date',
  datafinalsuperiordataincial:
    'It is not possible to save, there is some interval with the end date greater than the end date',
  intervalossesobrepondo:
    'Unable to save, there are overlapping monitoring date ranges',
  datainicialmenorqueafinaldeanteriores:
    'The start date must be greater than the end date of previous monitoring',
  opcionalsetelefone: 'optional, if you provide the phone number',
  opcionalseemail: 'optional, if you provide the email',
  erro: 'Error',
  funcaoobrigatoria: 'It is not possible to save, as the function is mandatory',
  alteracaoinvalidadocampo: 'You do not have permission to change all fields',
  categoryCNHNotEmpty: 'CNH category is a mandatory field',
  registerCNHNotEmpty: 'CNH Registration Number is a mandatory field',
  firstCNHNotEmpty: 'First Qualification Date is a mandatory field',
  emissionCNHNotEmpty: 'CNH issuance date is a mandatory field',
  validityCNHNotEmpty: 'CNH validity is a mandatory field',
  renachCNHNotEmpty: 'Renach number is a mandatory field',
  mirrorCNHNotEmpty: 'CNH mirror number is a mandatory field',
  statusCNHNotEmpty: 'CNH status is a mandatory field',
  stateCNHNotEmpty: 'UF CNH is a mandatory field',
  cityCNHNotEmpty: 'Municipality CNH is a mandatory field',
  driverCountryNotEmpty: "Driver's Country of Nationality is a mandatory field",
  driverStateNotEmpty: 'UF Birthplace Driver is a mandatory field',
  driverCityNotEmpty: 'Municipality of Birth Driver is a required field',
  contabilizarnomodulodeperformance: 'Account in the performance module',
  erroadmissao:
    'Unable to insert record. The employee was hired on the date/period informed',
  dismissalTypeIsMandatory:
    'Unable to insert record. The type of dismissal is mandatory.',
  dismissalReasonIsMandatory:
    'Unable to insert record. The reason for dismissal is mandatory.',
  dismissalReasonIsInvalid:
    'Unable to insert record. The reason for dismissal is invalid',
  dismissalReasonJustificationIsMandatory:
    'Unable to insert record. The justification for dismissal is mandatory.',
  resignationDateIsAfterCurrentDate:
    'Unable to insert record. The dismissal date cannot be later than the current one.',
  resignationDateIsBeforeAdmissionDate:
    'Unable to insert record. The dismissal date cannot be less than the starting date of service provision of employee.',
  permitegerarocorrenciasnopreenchimento:
    'Allows for generating occurrences in filling',
  cadastrarumaocorrencia: 'Register an occurrence',
  occurrenceform: 'Occurrence Form',
  indicadorcorrespondentenomodulodeperformance:
    'Corresponding indicator in the performance module',
  selecioneoindicador: 'Select indicator',
  datainicioprestacaoservicos: 'Start date of service provision',
  informedatainicioprestacaoservicos:
    'Enter the start date of service provision',
  datainicioprestacaoservicosinvalida:
    'Start date of service provision is invalid.',
  datainicioprestacaoservicosinferioradmissao:
    'The service provision start date cannot be less than the date of admission',
  hamovimentacoesanterioresadatainicioprestacaoservicos:
    'There cannot be movements prior to the start date of service provision',
  campoinvalido: 'Invalid field',
  isSameOrBeforeNow: 'The date cannot be greater than the current date',
  datainicioprestacaoservicosemaiorquehoje:
    'The service provision start date must be less than or equal to the current date',
  skills_acquisition_process: 'Skill acquisition process',
  days_after_admission_or_rise: 'Days after admission or promotion',
  informediasaposadmissaooupromocaoiniciaociclo:
    'Enter the limit of days after admission or promotion to participate in the cycle',
  feriascadastradas:
    'It is not possible to save, as there are vacations registered for the employee',
  planejamentoemmassajaenviado: 'The bulk planning can only be sent once',
  consultarenvios: 'Check shipments',
  digiteumnomeoudocumento: 'Enter a name or document',
  tipodeenviodoplanejamento: 'Submission type',
  planejamentoenviadoemmassa: 'Sent in bulk',
  planejamentoenviadoseparadamente: 'Individual',
  selecionetipodeenvio: 'Select shipping type',
  horaenvio: 'Shipping time',
  relatoriodeenvios: 'Shipping report',
  vagaduplicada: 'Successfully duplicated vacancy!!!',
  is_not_possible_to_change_the_parent: 'Invalid previous step',
  cadastros: 'Records',
  academy: 'Academy',
  replanejamento: 'Replanning',
  treinamentosagendados: 'Scheduled trainings',
  listadepresenca: 'Attendance list',
  calendario: 'Calendar',
  selecioneonivelnagrade: 'Select the level on the grid',
  dataprevista: 'Expected date',
  naoobrigatorio: 'Non mandatory',
  obrigatoriedade: 'Mandatory',
  aderenciageral: 'General Adherence',
  aderenciaprincipal: 'Main adherence',
  vocepodeselecionaronivelnoqualotreinamentoestaalocadoparafiltraralistadetreinamentos:
    'You can select the level at which the training is allocated to filter the training list.',
  permitircadastrodetreinamentonestenivel:
    'Allow training registration at this level',
  permitirtreinamentosatralados:
    'Allows training to be linked to this level of the grid',
  jaexistetreinamentosnestenivel:
    'It is not possible to disable the training registration at this level, as there are already linked trainings',
  responsaveleinstrutor: 'Responsible/Instructor',
  instrutor: 'Instructor',
  calendariodetreinamentos: 'TRAINING CALENDAR',
  exportar: 'Export',
  buscarpornome: 'Search by name',
  buscarpordescricao: 'Search by description',
  nomedotreinamento: 'Training Name',
  dataprevistadeinicio: 'Expected start date',
  datadetermino: 'End date',
  statusdotreinamento: 'Training Status',
  datadefinal: 'End Date',
  buscarpornomeoudescricao: 'Search by name',
  exercepapeldegestornaareadegente: 'Plays the role of manager of the People area',
  visibilidadegestorformulario: 'Manager visibility in the form',
  concluido: 'Completed',
  agendado: 'Scheduled',
  emandamento: 'In progress',
  atrasado: 'Delayed',
  anoreferencia: 'Reference year',
  campocustomizadoinvalido: 'Some custom field is invalid',
  datadotreinamento: 'Training date',
  presenca: 'Presence',
  mesdotreinamento: 'Training Month',
  anodotreinamento: 'Training year',
  iniciodaprestacaodeservicos: 'Start of service provision',
  atraso: 'Delay',
  previsto: 'Foreseen',
  realizadoem: 'Carried out in',
  selecioneumtreinamento: 'Select a training',
  presente: 'Present',
  visualizarmotivos: 'View reasons',
  deletar: 'Delete',
  placeholderbuscanomeoudescricaotreinamento: 'Search by training name or objective',
  placeholderbuscanometreinamento: 'Search by training name',
  prorrogado: 'Extended',
  marcacaomanual: 'Manual marking',
  pendente: 'Pending',
  faltou: 'Missed',
  valordotreinamento: 'Training value',
  palavraschaverelacionadasaotreinamento: 'Keywords related to training',
  planejamentorecorrente: 'Recurring planning',
  marqueessaopcaoparatreinamentosqueacontecemdeformacontinua:
    'Select this option for training that takes place continuously',
  otreinamentoeobrigatorio: 'Is training mandatory ?',
  tipodevinculo: 'Type of link',
  tipodecargo: 'Type of position',
  quaiskpismetricassaoimpactadospelarealizacaodestetreinamento:
    'Which kpis/metrics are impacted by carrying out this training.',
  valormonetariorecursofinanceiroqueseraaplicadonestetreinamento:
    'Monetary value, financial resource that will be applied in this training.',
  objetivodotreinamento: 'Training objective',
  selecioneocertificado: 'Select the certificate',
  quaisfuncoesparticiparaodotreinamento:
    'Which functions will participate in the training',
  quaiscolaboradoresparticiparaodotreinamento:
    'Which employees will participate in the training?',
  diasaposiniciodaprestacaodeservicos:
    'Days after the start of service provision',
  quantidadedeagendamentos: 'Number of appointments',
  admitidosapartirde: 'Admitted from',
  insiraaquantidadededias: 'Enter the number of days',
  campoparadatadecortedoscolaboradoreselegiveisaotreinamento:
    'Field for cut-off date for employees eligible for training',
  execucao: 'Execution',
  ocamponomedotreinamentodevecontercaracteresvalidos:
    'The training name field must contain valid characters',
  gradessequenciascriadasporadm:
    'It is not possible to exclude, because there are grades of sequences created by an administrator',
  jaexisteumtreinamentocomonomeinformado:
    'There is already training with the name provided',
  rascunho: 'Draft',
  vocenaotempermissaopararealizaressaoperacao:
    'You do not have permission to perform this operation',
  digitenomeparanovotreinamento: 'Enter the name of the new training',
  nomeparanovotreinamento: 'Name for the new training',
  paraserdupliacadootreinamentodeveterumnovonome:
    'Para ser duplicado o treinamento deve ter um novo nome',
  treinamentoduplicadosucesso: 'Duplicate training successfully!!!',
  naopossivelduplicarpoisonomejaeusadoemoutrotreinamento:
    'It is not possible to duplicate because the name is already used in another training',
  treinamentocopiadosucesso: 'Training copied successfully!!!',
  naoepossivelcopiarpoisnaoexistemessesvinculosnoclientedestino:
    'It is not possible to copy, as there are no "links" on the target client',
  naoepossivelcopiarpoisnaoexistemessecargosnoclientedestino:
    'It is not possible to copy, as there are no "jobs" in the target client',
  naoepossivelcopiarpoisnaoexisteagradenoclientedestino:
    'It is not possible to copy, as there is no "grid" on the target client',
  naoepossivelsalvarouatualizarpoisociclojafinalizou:
    'It is not possible to save or update, as the cycle has already ended',
  naoepossivelexcluirpoisociclojafinalizou:
    'It is not possible to delete, as the cycle has already ended',
  documentosnaoanalisados: 'Unanalyzed documents',
  relatoriodocumentosnaoanalizados: 'Report of unanalyzed documents',
  gerarrelatorio: 'Generate report',
  erroaogerarrelatorio: 'Error generating the report',
  informedatacompetenciaate: 'Provide competency data within 15 days.',
  competenciaate: 'Competence up to',
  relatorio: 'Report',
  relatoriosrecentes: 'Recent reports',
  seurelatorioficoupronto: 'Your report is ready',
  cliquenobotaodownloadparabaixarorelatorio:
    'Click the download button to download your report.',
  download: 'download',
  dowloademprogresso: 'In progress',
  downloadfinalizado: 'Complete',
  selecioneaquantidadedeagendamentos: 'Select the number of appointments',
  selecioneumtipodeagendamento: 'Select an appointment type',
  selecioneumoumaistiposdevinculo: 'Select one or more link types',
  ocamponaopodetermaisque500caracteres:
    'The training name field cannot exceed 500 characters',
  selecioneoanoreferencia: 'Select the reference year',
  selecioneoagendamento: 'Select the schedule',
  selecioneaestrutura: 'Select the structure',
  selecioneostatus: 'Select the status',
  selecioneaaderenciageral: 'Select the overall adherence',
  selecioneaaderenciaprincial: 'Select the main adherence',
  selecioneaobrigatoriedade: 'Select the mandatory',
  erroaotentaratualizarpoisexistetreinamentos:
    'Error when trying to update, there are already trainings registered at this level of the grid',
  tempodeduracaodoagendamentoemminutos: 'Schedule duration (in min.)',
  vincularcheckderetencao: 'Link retention check',
  indicarinstrutor: 'Indicate instructor',
  vincularavaliacaodereacao: 'Link reaction assessment',
  exportarhabilitados: 'Export ENABLED',
  datadeprorrogacao: 'Extension Date',
  visualizar: 'View',
  cliqueparaselecionarumformulario: 'Click to select a form',
  cliqueparaselecionarumaavaliacao: 'Click to select a review',
  digiteonomedoinstrutor: 'Enter instructor name',
  atribuirusuariosaestrutura: 'Assign users to the structure.',
  atribuirusuarios: 'Assign users',
  selecioneotipodeusuario: 'Select the user type',
  atribuir: 'Assign',
  scheduledtrainingsalert1:
    'The fields filled in on this screen filter the training options displayed here.',
  scheduledtrainingsalert2:
    'If you are not finding any training, make sure it matches the filters applied',
  totaldecolaboradores: 'Total employees',
  datainicialmaiorouigualquefinal:
    'The start date must be less than the end date',
  datafinaldevesermaiorqueinicial:
    'The end date must be greater than the start date',
  vincularformulario: 'Link form',
  vincularavaliacao: 'Link assessment',
  tipodeagendamento: 'Schedule type',
  naoepossivelatualizarpoisotreinamentojainiciou:
    'It is not possible to update as training has already started',
  naoepossivelatualizarumagendamentojainiciado:
    'It is not possible to update a schedule that has already started',
  erroaotentaratualizarpoisexistetreinamentosnosfilhos:
    'Error when trying to delete, as there are already trainings registered at this level of the grid or in the sequential levels',
  naoepossivelsalvarpoisjaexisteumtreinamentocomestenome:
    'Error when trying to save, as there is already a training registered with this name',
  dataagendamentoinferioraantecessor:
    'The start date must be less than or equal to the start date of subsequent schedules',
  dataagendamentosuperioraposterior:
    'The start date of the schedule cannot be lower than the start date of the previous schedule',
  certezaalterarpresenca:
    "Are you sure you want to change this person's presence?",
  informeadataprevistadetermino: 'Enter the expected end date',
  filtrartabela: 'Filter table',
  informeanovadatadetermino: 'Enter the new end date',
  novadatadetermino: 'New end date',
  nenhumtreinamentofoiencontrado: 'No training was found',
  oagendamentoaindanaoterminou: 'Scheduling is not finished yet',
  naoepossiveleditardatalimiteatingida: 'Unable to edit, deadline reached',
  treinamentosdisponiveis: 'Available Trainings',
  datafinalizacao: 'End Date',
  datafinalmaioratualtrainamento:
    'The expected end date cannot be less than the current date',
  estecolaboradornaopodeserremovido: 'This collaborator cannot be removed!',
  vinculadoaumcolaboradordeoutraunidade:
    'It is not possible to remove, this item is linked to collaborators in another unity',
  dataderealizacao: 'Date of realization',
  uploaddalistadepresenca: 'Attendance list upload',
  anexarlistadepresençadotreinamento: 'Attach training attendance list',
  quaiscolaboradoresparticiparamdotreinamento:
    'Which employees attended the training?',
  arquivosanteriores: 'Previous files',
  visualizarcolaboradores: 'View employees',
  listadecolaboradores: 'Employees list',
  certezaexcluirlistadepresenca:
    'Are you sure you want to delete this attendance list?',
  planejamentopendente: 'Pending planning',
  agendamentodisponivelparainicioimediato:
    'Schedule available for immediate start.',
  parabensvocejaconcluiuesteagendamento:
    'Congratulations, you have now completed this schedule!',
  agendamentorealizadodeformapresencial: 'Scheduling carried out in person.',
  adatadeterminodesteagendamentojapassou:
    'The end date for this schedule has passed.',
  agendamentoindisponivelaguardeadatadeinicio:
    'Schedule unavailable, wait for the start date.',
  nenhumcolaboradorencontrado: 'No collaborators found',
  todososxagendamentosforamconcluidos: 'All {quantity} schedules have been completed',
  voceaindanaorealizouxagendamentos: 'You have not made {quantity} schedules yet',
  voceaindanaorealizouxagendamento: 'You haven’t scheduled {quantity} appointment yet.',
  informeotempodeduracaoemmin: 'Enter the duration in minutes',
  paisdenacionalidadedocondutor: `Driver's Country of Nationality`,
  categoriadacnh: `Driver's License Category`,
  numeroderegistrodacnh: `Driver's License Registration Number`,
  dataprimeirahabilitacao: '1st License Date',
  dataemissaocnh: `Driver's License Issue Date`,
  situacaocnh: `Driver's License Situation`,
  numeroespelhocnh: `Driver's License Mirror Number`,
  numerorenach: 'Renach Number',
  municipiocnh: `City of Driver's License`,
  validadedacnh: `Driver's License Expiry Date`,
  ufdenaturalidadecondutor: `State of Driver's Birthplace`,
  ufcnh: `State of Driver's License`,
  selecioneasituacao: 'Select the Situation',
  municipionaturalidadecondutor: `City/Town of Driver's Birthplace`,
  tipodelicenca: 'Licence Type',
  cnh: 'CNH',
  licencadeconducaoparaestrangeiros: 'Driving License for Foreigners',
  validadelicencadeconducao: 'Driving License Validity',
  validadepermissaodeconducaonobrasil: 'Validity of Driving Permit in Brazil',
  informevalidadelicencadeconducao: 'Enter the driving license Validity',
  informevalidadepermissaodeconducaonobrasil:
    'Enter the validity of the driving permit in Brazil',
  datadevalidadedalicencadeconducaodocolaboradornoseupaisdeorigem: `Validity data for the employee's driving license in their country of origin.`,
  datalimitequeestecolaboradorpoderaconduzirnobrasilusandoapermissaodeconducaosemacnh:
    'Deadline for this employee to be able to drive in Brazil using a Driving Permit, without a CNH.',
  checkderetencao: 'Retention check',
  avaliacaodereacao: 'Reaction evaluation',
  avisoprazoparaconcluiragendamento:
    'Attention - The maximum deadline for completing this schedule is: {date}, complete it by this date to avoid possible inconveniences due to missing the deadline.',
  finalizaravaliacao: 'Finalize evaluation',
  descreveroindicador: 'Describe the indicator',
  descrevaoobjetivodotreinamento: 'Describe the objective of the training',
  nenhumresultadoencontrado: 'No results found',
  nenhumaopcaodisponivel: 'No options avaiable',
  semsubopcoes: 'No sub-options',
  pressioneenterparaselecionar: 'Press enter to select',
  presioneenterpararemover: 'Press enter to remove',
  selecionado: 'Selected',
  resultadodaavaliacaopontos: 'Evaluation result: {score} points',
  vocejarespondeuestaavaliacao: 'You have already responded to this evaluation',
  avisoagendamentofeitopresencial:
    'Attention - The retention check for this schedule was conducted in person. You can access the content below, but it is not possible to submit responses.',
  datadeterminoagendamento: 'Schedule end date',
  naoepossivelcopiarpoisonomejaeusadoemumtreinamentonoclientedestino:
    'It is not possible to copy, as the name is already used in training on the target client',
  seuusuarionaopodeeditarestecampo: 'Your user cannot edit this field',
  voceconcluiuxdeyagendamentos:
    'You have completed {completed} of {total} schedules',
  na: 'N/A',
  prazofinalparaconclusaodoagendamento: 'Deadline for completing the schedule',
  agendamentodisponivelporemforadoprazo: 'Schedule available but late.',
  onomeinformadoparaagradenaoevalido:
    'The name entered for the grid is invalid',
  formcomagendamentosvinculados:
    'It is not possible to delete, as the form is linked to one or more appointments.',
  avaliacaocomagendamentosvinculados:
    'It is not possible to delete, as the assessment is linked to one or more schedules.',
  copiadeformulario: 'Form copy',
  copiadeavaliacao: 'Evaluation copy',
  onomeinformadojaeutilizadoemoutragrade:
    'The name entered is already used in another grid',
  otreinamentocomstatusrascunhonaopodesermanipulado:
    'Training with draft status cannot be manipulated',
  naoepossiveleditarformulariopoisjaexisteresposta:
    'It is not possible to edit, as there is already a response registered for this form',
  naoepossiveleditaravaliacaopoisjaexisteresposta:
    'It is not possible to edit, as there is already a response registered for this review',
  nenhumclienteencontrado: 'No client found',
  naoepossiveldeletarpoiscontemrespostasaosformulariosdestetreinamento:
    'It is not possible to delete it, as it contains answers to the forms in this training',
  dataprevistadeterminoinvalida: 'Invalid expected end date',
  esteagendamentonaoestamaisdisponivel: 'This schedule is no longer available',
  erroessenomejaestaemuso: 'That name is already in use',
  formularioduplicado: 'Form duplicated successfully!!!',
  erroaoduplicarregistro: 'Error duplicating records',
  avaliacaoduplicada: 'Evaluation duplicated successfully!!!',
  naoepossiveldeletarotreinamentopoiscontemrespostasasavaliacoesdomesmo:
    'It is not possible to delete the training as it contains responses to the training assessments',
  naoepossiveldeletarotreinamentopoisexisterespostasdeavaliacoeseformulariorespondidos:
    'It is not possible to delete the training as there are responses from assessments and completed forms',
  vocedeveselecionaronivelnoqualotreinamentoseraalocado:
    'You must select the level at which the training will be allocated',
  consultaprontuariodocondutor: 'Drivers Record Consultation',
  informeodocumentoparaconsultar: 'Submit the document for review',
  consultarprontuario: 'Check record',
  informacoesdocolaborador: 'Employee Information',
  informacoesdocondutor: 'Driver Information',
  pontuacaodeprontuario: 'Record Score',
  motivosdobloqueio: 'Reasons for Block',
  datadobloqueio: 'Block Date',
  bloqueado: 'Blocked',
  endereco: 'Address',
  vencimentodacnh: 'CNH validity',
  previsaodedesbloqueio: 'Unlock forecast',
  datavencimentogr: 'GR due date',
  condutornaoencontradocomestedocumento:
    'Conductor not found with this document',
  grupodeanalise: 'Analysis group',
  selecioneumgrupodeanalise: 'Select an analysis group',
  selecioneumrelatorio: 'Select a report',
  selecioneumaanalise: 'Select an analysis',
  naofoipossivelcarregarodashboard: 'It was not possible to load the dashboard, try again later',
  verdashboardemtelacheia: 'View dashboard in full screen',
  exportarparapdf: 'Export data to PDF',
  integracombeedoo: 'Integration with BEEDOO?',
  documentoexcluido: '(Deleted document)',
  limparfiltros: 'Clean filters',
  unidadeinvalida: 'Invalid unit',
  modelosdeplano: 'Plan templates',
  meusplanos: 'My plans',
  compartilhadoscomigo: 'Shared with me',
  planospublicos: 'Public plans',
  minhasacoes: 'My actions',
  '+novoplanodeacao': '+ New action plan',
  placeholderbuscarpelonomedoplano: 'Search by action plan name',
  placeholderbuscarpelonomedoplanoouproprietario: 'Search by owner name or action plan name',
  placeholderbuscarpelonomedoplanoouacao: 'Search by action or action plan name',
  criarplanodeacao: 'Create action plan',
  definaseusobjetivosidentifiqueasetapasestabelecaprazosatribuaresponsaveis:
    'Define your goals, identify the steps, set deadlines, assign responsibilities.',
  dicasejaclaroeespecificoparafacilitaroacompanhamentodoprogresso: 'Tip: Be clear and specific to make it easier to track progress.',
  nomedoplanodeacao: 'Action plan name',
  placeholdernomeplanodeacao: 'Ex: Action Plan - Turnover',
  acesso: 'Access',
  selecioneomodelodoplanodeacao: 'Select the action plan model',
  asacoesdoplanoexigemaprovacao: 'Plan actions require approval.',
  obrigatoriedadedepreencherevidenciasnasacoes: 'Mandatory completion of evidence in actions.',
  selecioneotipodeacesso: 'Select access type',
  visualizaracoes: 'View actions',
  atualizardadoscadastrais: 'Update registration data',
  atualizacaodedadosdocolaborador: 'Collaborator data update',
  cancelaratualizacaodedados: 'Cancel data update',
  mensagemconfirmacaocancelardadoscadastrais: 'By confirming the cancellation of this action, all edits made will be discarded, and the fields will revert to displaying the original information.',
  atualizacaodosdados: 'data update',
  desejarealmentecancelaraatualizacaodosdados: 'Do you really want to cancel the',
  confirmarcancelamento: 'Confirm cancellation',
  continuareditando: 'Continue editing',
  atualizacaodedadospessoaisconcluida: 'Personal data update completed',
  atualizacaodedadossociaisconcluida: 'Social data update completed',
  atualizacaodedadosfuncionaisconcluida: 'Functional data update completed',
  conteudomodalsalvardadoscadastrais1: 'The data in the tab',
  conteudomodalsalvardadoscadastrais2: 'were successfully updated.',
  perguntamodalsalvardadoscadastrais: 'What would you like to do now?',
  voltarparaoprontuario: 'Return to the record',
  editardadosfuncionais: 'Edit functional data',
  editardadossociais: 'Edit social data',
  seuusuarionaopossuiprivilegioparaeditarestecampo: 'Your user does not have the privilege to edit this field.',
  naohadadosfuncionaisparaseremeditados: 'There is no functional data to edit',
  unidadenaoaceitamovimentacaonessenivel: 'Unity does not accept movements at this level',
  permitircadastrodemovimentacoesnestenivel: 'Allow registration of movements at this level',
  permitequesejamatreladoscolaboradoresnesteniveldaestrutura: 'Allows employees to be attached to this level of the structure',
  colaborators_linked_to_the_unity_for_no_allow_movements: 'It is not possible to lock this structure level. There are employees allocated at this level and they must be moved before locking the structure',
  unityNotAllowMovements: 'The unity does not allow movements',
  numerocelularinvalido: 'Invalid cell phone number',
  certezacopiartreinamento: 'Are you sure you want to copy this training?',
  existemusuarioscadastradoscomestetipo: 'It is not possible to delete, as there are already registered users with this type',
  isFormManagerVisibilityHelpText: 'Will impact forms registered in "Evaluations and Inspections" and "Monitoring and Retention"',
  admitido: 'Admitted',
  renomearplano: 'Rename plan',
  titulodoplano: 'Title of the plan',
  nomeieoplano: 'Name the plan',
  exclusaodeacao: 'Action deletion',
  exclusaodeplanodeacao: 'Action plan deletion',
  desejaexcluirestaacao: '\nAre you sure you want to <strong style="color: red;">delete this action</strong>?',
  veracoes: 'View actions',
  emprogresso: 'In progress',
  naoiniciado: 'Not started',
  atrasada: 'Late',
  proprietario: 'Owner',
  gerenciarusuarios: 'Manage users',
  planopublico: 'Public plan',
  planoprivado: 'Private plan',
  encerrarplano: 'Close plan',
  oqueestasendofeitoouserafeito: 'What is being done or will be done?',
  restantedecaracteres: '{count} characters remaining.',
  porqueissoestasendofeito: 'Why is this being done?',
  ondeissoestaacontecendo: 'Where is this happening?',
  quemestaenvolvido: 'Who is involved?',
  quandoissoacontecera: 'When will this happen?',
  comoissoserarealizado: 'How will this be accomplished?',
  oquelabel: 'What?',
  porquelabel: 'Why?',
  ondelabel: 'Where?',
  quemlabel: 'Who?',
  quandolabel: 'When?',
  comolabel: 'How?',
  certezaexcluirmodelodeplano: 'Are you sure you want to delete this plan model?',
  exclusaodemodelodeplano: 'Plan model deletion',
  nomedomodelo: 'Model name',
  nomedomodeloplaceholder: 'Ex: Action plan model - Turnover',
  salvarmodelo: 'Save model',
  caracteresrestantes: '{counter} characters remaining',
  adicionarnovacoluna: 'Add new column',
  nomedacoluna: 'Column name',
  nomeieacoluna: 'Name the column',
  textodeapoio: 'Support text',
  textoopcionalparaexplicarafinalidadedacoluna: 'Optional text to explain the purpose of the column',
  tipodacoluna: 'Column type',
  adicionarcoluna: 'Add column',
  publico: 'Public',
  privado: 'Private',
  sovocepodeveresseplanovocepodecompartilharelecomusuariosmaistarde: 'Only you can see this plan. You can share it with individual users later.',
  todospodemverseuplanovocepoderestringiroacessoausuariosmaistarde: 'Everyone can see your plan. When an action plan is of the\npublic type, any user can edit it.',
  digiteonomedomodelo: 'Enter model name',
  modelosdeplanopersonalizado: 'Custom plan templates',
  editaracao: 'Edit action',
  editarnomedoplano: 'Edit plan name',
  tipodecoluna: 'Column type',
  monetario: 'Monetary',
  visualizaroplanocompleto: 'View full plan',
  naoiniciada: 'Not started',
  acoesaguardandoaprovacao: 'Actions awaiting approval ( {count} )',
  acoesconcluidas: 'Completed actions ( {count} )',
  acoesreprovadas: 'Disapproved actions ( {count} )',
  concluiracao: 'Complete action',
  adicionarevidencia: 'Add evidence',
  adicioneaevidencia: 'Add the evidence',
  procurararquivo: 'Search for file',
  reprogramar: 'Reprogram',
  atribuircolaborador: 'Assign collaborator',
  reprogramaracao: 'Reprogram action',
  selecioneanovadata: 'Select new date',
  novonomedoplano: 'New plan name',
  aposaexclusaotodohistoricodemovimentacoesassociadoaesteplano: 'After deletion, all movement history associated with the actions of this plan will be lost, and the plan will no longer be visible to the collaborators to whom it was assigned.',
  onomedoplanoduplicadonaopodeserigualaooriginal: 'Duplicate plan name cannot be the same as original',
  acaoadicionadanaabaacoesaguardandoaprovacao: 'Action added to the tab: <span style="color: red">Actions awaiting approval.</span>',
  enviarnotificacao: 'Send notification',
  justifiqueareprovacao: 'Justify the failure',
  informeomotivodareprovacaodestaacao: 'Provide the reason for the action being rejected',
  digiteouselecioneonome: 'Enter or select name',
  salvaracao: 'Save action',
  evidenciadaacao: 'Evidence of action',
  datadeupload: 'Upload date',
  editouocampo: '<strong>{user}</strong> edited the field "<strong>{field}</strong>"',
  as: 'at',
  atualizouostatuspara: '<strong>{user}</strong> updated the status to <strong>{status}</strong>',

  adicionaracao: 'Add action',
  salvarcomomodelo: 'Save as template',
  copiarparameusplanos: 'Copy to my plans',
  plano: 'Plan',
  selecioneotipodacoluna: 'Select the column type',
  colunadotipotexto: 'Text',
  colunadotipodata: 'Date',
  colunadotipomoeda: 'Currency',
  planejarasdemandasdosemestre: 'Plan semester demands',
  escrevaaqui: 'Write here',
  definirdata: 'Set date',
  R$: '$0,000.00',
  '+novomodelodeplano': '+ New plan model',
  criarplanocomestemodelo: 'Create plan with this template',
  placeholderbuscarpelonomedomodelo: 'Search by template name',
  informeonovonomedoplano: 'Enter the new plan name',
  ocorreuumerroporfavortentenovamente: 'An error occurred, please try again',
  jaexisteumregistrocomestenome: 'There is already a record with this name',
  nomearnovomodelo: 'Name new model',
  nomeieomodelo: 'Name the model',
  aosalvaresteplanocomomodelovocepoderaacessaloapartirdaabameusmodelos: 'By saving this plan as a template you will be able to access it from the tab<br><span class="greenWord">My models</span>.',
  podevisualizar: 'Can view',
  adicionarusuario: 'Add user',
  leitura: 'Read',
  podeeditar: 'Can edit',
  informeonomedousuario: 'Enter the name of the user',
  quandoissoocorrera: 'When will this happen?',
  quantoissocustara: 'How much will this cost?',
  quantocustalabel: 'How much?',
  umavezexcluidoocolaboradorperdeavisualizacaoeoacessoatodososrecursosdesteplano: 'Once deleted, the collaborator loses visibility and access to all\nresources in this action plan. If the collaborator is assigned to one or\nmore actions within the plan, they will be automatically removed from them.',
  exclusaodeusuarios: 'Deleting users',
  desejarealmente: 'Do you really want to',
  excluiresseplanodeacao: 'delete this action plan',
  editouaacao: '<strong>{name}</strong> edited the action',
  planodeacaoduplicadocomsucesso: 'Action plan duplicated successfully!!!',
  planodeacaoexcluidocomsucesso: 'Action plan deleted successfully!!!',
  planodeacaocopiadocomsucesso: 'Action plan copied successfully!!!',
  planodeacaosalvocomomodelo: 'Action plan saved as template!!!',
  acaoadicionada: 'Action added',
  acaoadicionadanaaba: 'Action added in the tab',
  acoesaguardandoaprovaçao: 'Actions awaiting approval',
  erroaocopiarplanodeacao: 'Error copying action plan',
  erroaosalvarcomomodelo: 'Error saving action plan as template',
  acaosalvacomsucesso: 'Action saved successfully!!!',
  erroaosalvaracao: 'Error saving action',
  evidenciaexcluida: 'Evidence deleted!!!',
  erroaoexluirevidencia: 'Error deleting evidence',
  acaoexcluidacomsucesso: 'Action deleted successfully!!!',
  erroaoexcluiracao: 'Error deleting action',
  erroaoexcluirplanodeacao: 'Error deleting action plan',
  erroaoduplicaplanodeacao: 'Error duplicating action plan',
  notificacaoenviadacomsucesso: 'Notification sent successfully!',
  IN_PROGRESS: 'In progress',
  NOT_STARTED: 'Not started',
  naohaacoescadastradas: 'No actions registered',
  semdadosparaexportar: 'No data to export',
  verjustificativa: 'See justification',
  mensagemencerramentoplanodeacao: 'Once the plan is closed, it will not be possible to add, approve, complete, or disapprove actions. All fields will be locked for editing, allowing only viewing of the plan content.',
  desejaencerraroplanodeacao: '\nAre you sure you want to <strong>close this plan</strong>?',
  naoencerrarplano: 'Do not close plan',
  encerramentodeplano: 'Closing plan',
  reativaroplano: 'Reactivate plan',
  nomesalvocomsucesso: 'Plan name saved successfully',
  voltarparaemandamento: 'Back to in progress',
  voltarparanaoiniciada: 'Back to not started',
  jaexistecolunacomestenome: 'There is already a column with this name',
  modeloplanoacaoexcluidocomsucesso: 'The action plan template was successfully deleted.',
  nomeatingiulimitemaximocaracteres: 'The name has reached the maximum limit of 250 characters.',
  camponomenaopodeestarvazio: 'The name field cannot be empty.',
  aindanaoexistemacoesaguardandoaprovacao: 'There are still no actions awaiting approval.',
  aindanaoexistemacoesconcluidas: 'There are no completed actions yet.',
  aindanaoexistemacoesreprovadas: 'There are no disapproved actions yet',
  COMPLETED: 'Completed',
  acaoadicionadacomsucesso: 'Action added successfully.',
  limitedecaracteresatingido: '{qtd} character limit reached.',
  duplicateColumnName: 'Duplicate Column Name',
  columnAlreadyExists: 'A column with the name <strong>{columnName}</strong> already exists, please rename it to continue.',
  rename: 'Rename',
  planodeacaocapitalizado: 'Action plan',
  ultimaevidencianaopodeserremovida: 'The last evidence cannot be removed, it is necessary to keep at least one piece of evidence for the action',
  userisresponsibleforactions: 'The user cannot be removed or changed as it is linked to an action',
  limitederesponsaveisdaacaoatingido: 'The limit of responsible for the action has been reached',
  oarquviopodeternomaximo: 'The file must have a maximum size of {size}Mb',
  tipodedeevidenciainvalida: `Invalid evidence extension! Accepted: {acceptTypes}`,
  acaoatrasadatextocompleto: 'Late action',
  informeomodelodesejado: 'Please inform the desired model',
  naofoipossivelsalvaressemodeloonomeescolhidojaestaemuso: 'It was not possible to save this model, the chosen name is already in use',
  naofoipossivelsalvaresseplanodeacaoonomeescolhidojaestaemuso: 'It was not possible to copy this action plan, the chosen name is already in use',
  naofoipossivelduplicaresseplanodeacaoonomeescolhidojaestaemuso: 'It was not possible to duplicate this action plan, the chosen name is already in use',
  naohaatualizacoesparamostrar: 'No updates to show',
  estaaçaonaopodeseralteradaporqueoplanojafoiconcluido: 'This action cannot be changed because the plan has already been completed.',
  edicaonaosalva: 'Edit not saved',
  desejarealmentefecharsemsalvar: 'Do you really want <strong>to close without saving</strong>?',
  edicoespendentes: 'There are pending edits in this action. If you close now, the changes made will be lost.',
  usuariosatualizadoscomsucesso: 'Users updated successfully',
  justificativadareprovacaomin: 'Justification for failure',
  aposaexclusaotodoohistoricodemovimentacoesrelacionadoaestaacao: 'After deletion, all movement history related to this action will be lost, and it will no longer be visible to the collaborators to whom it was assigned.',
  umavezexcluidanaoserapossivelreutilizaloemplanofuturos: 'Once deleted, it will not be possible to reuse it in future plans',
  erroentreemcontatocomadministrador: 'If you believe this is an error, please contact your system administrator.',
  naopossuiacesso: 'You do not have access to this feature.',
  entraremcontato: 'Contact us',
  naohamarcacaodeponto: 'There is no time tracking',
  confirmarexclusaodecargo: 'Confirm position deletion',
  temcertezadequedesejaexcluirestecargoessaacaoeirreversivel: 'Are you sure you want to delete this position? This action is irreversible.',
  exclusaonaopermitida: 'Deletion not allowed',
  estecargopossuicolaboradorescomregistrodepontohtml: "This job position has employees linked with the <strong>No time clock record</strong> flag active. Adjust the employees' schedule settings in: <strong>Working hours > Bookings</strong> before deleting the job position.",
  estecargopossuicolaboradorescomregistrodeponto: "This job position has employees linked with the no time clock record flag active. Adjust the employees' schedule settings in: Working hours > Bookings before deleting the job position.",
  entendido: 'Understood',
  positionmin: 'position',
  semregistrodeponto: 'No time clock record',
  collaboratormin: 'collaborator',
  confirmarexclusaoderegistro: 'Confirm deletion of record',
  temcertezaquedesejaexcluiressaacaonaopoderaserdesfeita: 'Are you sure you want to delete this record? This action cannot be undone.',
  excluirregistro: 'Delete record',
  editarregistro: 'Edit record',
  datadeterminodefinida: 'End date set.',
  errointervalodetempomarcacao: 'El intervalo de tiempo especificado no puede superponerse con períodos de otras marcas ya registradas y no puede exceder el período durante el cual el empleado ocupó este cargo.',
  marcacaoatualsemdatadetermino: 'This record cannot be saved. It is necessary to enter the end date for the current record',
  admissaoobrigatoriaporrecrutamentoeselecao: 'Mandatory admission by Recruitment and Selection',
  canditadoadmitidonaopodesereditado: 'Admitted candidate cannot be edited',
  somenteocriadordoplanopodeencerralo: 'Only the creator of the plan can terminate it.',
  somenteocriadordoplanoouumadministradorpodeencerralo: 'Only the plan creator or an administrator can terminate the plan.',
  somenteocriadordoplanopodereativalo: 'Only the plan creator can reactivate it.',
  somenteocriadordoplanoouumadministradorpodereativalo: 'Only the plan creator or an administrator can reactivate it.',
  configuracaosalvacomsucesso: 'Configuration saved successfully',
  rgcrnm: 'RG/CRNM',
  dataultimavalidaderg: 'Last RG validity date',
  orgaoexpedidorrg: 'RG issuing agency',
  informeorgoucrnm: 'Enter the RG or CRNM',
  informeorgaoexpedidor: 'Enter the issuing agency',
  ocampodevecontercaracteres: 'The field must contain {minChars} to {maxChars} characters',
  avisoexclusaobloqueadaenecessariodeletaroregistromaisrecente: 'This record cannot be deleted. It is necessary to delete the most recent record and create new records in chronological order.',
  avisocargopossuimarcacoes: 'This position has employees linked with the "No time clock record" flag active. Adjust the employees work schedule settings at: Working hours > Bookings.',
  avisomudancadecargopossuimarcacoessemdatadetermino: "The change in position will be made from defining the end date for the 'No time clock record' flag, configured under Working hours > Bookings.",
  avisomudancadecargopossuimarcacoes: "To make the position change, set a start date that is later than the end date entered for the 'No time clock record' flag configured under Working hours > Bookings.",
  avisodemissaopossuimarcacoessemdatadetermino: "The termination will be processed based on the end date defined for the 'No time clock record' flag, configured under Working hours > Bookings.",
  avisodemissaopossuimarcacoes: "The end date entered in terminations must be later than the end date of the 'No time clock record' flag.",
  avisoexclusaobloqueadapessoademetida: 'This record cannot be deleted because its contributor is terminated.',
  conflitodedatasdetectado: 'Conflict of dates detected',
  corrijaadataparacontinuar: 'Correct the date to proceed',
  corrigirdata: 'Correct date',
  alteracaonaopermitida: 'Change not allowed',
  estecargopossuicolaboradorescomregistrodepontoalteracaohtml: "This job position has employees linked with the <strong>No time clock record</strong> flag active. Adjust the employees' schedule settings in: <strong>Working hours > Bookings</strong> before saving the job position.",
  relatorioderespostasdoformulario: 'Form responses report',
  statusdocolaborador: 'Employee status',
  dataanomaximoinvalido: 'The year of the date must be less than 2100',
  quantidadederegistros: 'Number of records',
  liberado: 'Released',
  ocorreuerroaobuscardadosdmpeople: 'An error occurred while searching for DMPeople data',
  ocorreuerroaobuscardadosfrotalegal: 'An error occurred while searching for FrotaLegal data',
  informeoutro: 'Enter another'
}
