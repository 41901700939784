export default {
  bind(el, _, vnode) {
    el.addEventListener('blur', function (e) {
      const inputValue = e.target.value
      if (inputValue.length < 10) {
        return
      }

      const date = new Date(inputValue)
      const isValidDate = date instanceof Date && !isNaN(date)

      // Verifica se a data é válida e está dentro do intervalo desejado
      if (
        isValidDate &&
        date >= new Date('1900-01-01') &&
        date <= new Date('2099-12-31')
      ) {
        // Caso seja válida, remove a classe de erro, se estiver presente
        e.target.classList.remove('dm-input-date-limit-error')
      } else {
        // Caso não seja válida ou esteja fora do intervalo, limpa o valor e adiciona classe de erro
        e.target.classList.add('dm-input-date-limit-error')

        setTimeout(() => {
          e.target.value = ''

          vnode.elm.dispatchEvent(new Event('input')) // Dispara evento 'input' para atualizar o modelo

          e.target.classList.remove('dm-input-date-limit-error')
        }, 1000)
      }
    })
  }
}
