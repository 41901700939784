const defaultCurrentNewActionPlanTabIndex = 0

const state = {
  currentNewActionPlanTabIndex: defaultCurrentNewActionPlanTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTNEWACTIONPLANTABINDEX: (state, payload) => {
    state.currentNewActionPlanTabIndex = payload
  }
}

const actions = {
  setCurrentNewActionPlanTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTNEWACTIONPLANTABINDEX', currentTabIndex)
  },
  clearCurrentNewActionPlanTabIndex({ commit }) {
    const defCurrentActionPlanTabIndex = 0
    commit('SET_CURRENTNEWACTIONPLANTABINDEX', defCurrentActionPlanTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
