<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          :client-configuration="clientConfiguration"
          @clicked="itemClicked"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import { clientConfigurationService } from '@/services/client-configuration-service'
import { ability, defineAbilityFor } from '@/config/ability'

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      clientConfiguration: []
    }
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar', 'userProfile', 'isMaster']),
    ...mapState({
      currentManagementTabIndex: (state) =>
        state.currentManagementTabIndex.currentManagementTabIndex,
      currentConfigurationTabIndex: (state) =>
        state.currentConfigurationTabIndex.currentConfigurationTabIndex,
      currentRecruitmentTabIndex: (state) =>
        state.currentRecruitmentTabIndex.currentRecruitmentTabIndex,
      currentClientTabIndex: (state) =>
        state.currentClientTabIndex.currentClientTabIndex,
      currentCompanyTabIndex: (state) =>
        state.currentCompanyTabIndex.currentCompanyTabIndex
    }),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return true
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  watch: {
    activeMenu: function () {
      console.log('Active menu: ', this.activeMenu)
      if (this.activeMenu === '/management/index') {
        this.setCurrentManagementTabIndex(0)
      } else if (this.activeMenu === '/configuration/index') {
        this.setCurrentConfigurationTabIndex(0)
      } else if (this.activeMenu === '/recruitment/index') {
        this.setCurrentRecruitmentTabIndex(0)
      } else if (this.activeMenu === '/researches/index') {
        this.setCurrentResearchTabIndex(0)
      } else if (this.activeMenu === '/client/index') {
        this.setCurrentClientTabIndex(0)
        if (this.userProfile === 'admin_sistema' || this.isMaster) {
          this.setCurrentCompanyTabIndex(0)
        } else {
          this.setCurrentCompanyTabIndex(1)
        }
      }
    },
    '$store.getters.userClient': function () {
      this.fetchClientConfiguration()
    }
  },
  created() {
    this.fetchClientConfiguration()
  },
  methods: {
    ...mapActions('clientConfiguration', ['setLoaded']),
    ...mapActions('currentManagementTabIndex', [
      'setCurrentManagementTabIndex',
      'clearCurrentManagementTabIndex'
    ]),
    ...mapActions('currentResearchTabIndex', [
      'setCurrentResearchTabIndex',
      'clearCurrentResearchTabIndex'
    ]),
    ...mapActions('currentConfigurationTabIndex', [
      'setCurrentConfigurationTabIndex',
      'clearCurrentConfigurationTabIndex'
    ]),
    ...mapActions('currentRecruitmentTabIndex', [
      'setCurrentRecruitmentTabIndex',
      'clearCurrentRecruitmentTabIndex'
    ]),
    ...mapActions('currentClientTabIndex', [
      'setCurrentClientTabIndex',
      'clearCurrentClientTabIndex'
    ]),
    ...mapActions('currentCompanyTabIndex', [
      'setCurrentCompanyTabIndex',
      'clearCurrentCompanyTabIndex'
    ]),
    ...mapActions('newActionPlan', ['setActiveTab']),
    async fetchClientConfiguration() {
      this.setLoaded(false)
      const { data } = await clientConfigurationService.getByClient(
        this.$store.getters.userClient
      )
      this.clientConfiguration = data
        ? data.modules.map((module) => module.name)
        : []
      this.setLoaded(true)
      const userModules = {
        client: this.$store.getters.userClient,
        modules: data.modules
      }
      ability.update(
        defineAbilityFor(this.$store.getters.userProfile, userModules, data)
      )
      this.$router.push('/')
    },
    itemClicked(tab) {
      if (tab === '/actionPlan/index') {
        this.setActiveTab(0)
      }
    }
  }
}
</script>
<style></style>
