import qs from 'querystring'
import store from '@/store'
import { BaseService, service } from '../base-service'

export class NewActionPlanService extends BaseService {
  constructor() {
    super('/v2/action-plan')
  }

  async createActionPlan(actionPlan) {
    return service.post(this.baseURL, actionPlan)
  }

  async copy(actionPlanId, newName) {
    if (!newName) throw new Error('New name is required!')
    const url = `${this.baseURL}/${actionPlanId}/copy`
    return service.post(url, { newName, lang: store.getters.locale })
  }

  async duplicate(actionPlanId, newName) {
    if (!newName) throw new Error('New name is required!')
    const url = `${this.baseURL}/${actionPlanId}/duplicate`
    return service.post(url, { newName })
  }

  async addUser(actionPlanId, user) {
    const url = `${this.baseURL}/${actionPlanId}/add-user`
    return service.put(url, { newUser: user })
  }

  async removeUser(actionPlanId, userId) {
    const url = `${this.baseURL}/${actionPlanId}/remove-user`
    return service.put(url, { userIdToRemove: userId })
  }

  async changeUserType(actionPlanId, user) {
    const url = `${this.baseURL}/${actionPlanId}/change-user-type`
    return service.put(url, { newUser: user })
  }

  async removeActionPlan(actionPlanId) {
    const url = `${this.baseURL}/${actionPlanId}`
    return service.delete(url)
  }

  async updateActionPlan(actionPlanId, data) {
    const url = `${this.baseURL}/${actionPlanId}`
    return service.put(url, data)
  }

  async updateAction(actionPlanId, actionId, data) {
    const {
      templateResponses,
      customQuestionsResponses,
      status,
      whoIds,
      file
    } = data
    const url = `${this.baseURL}/${actionPlanId}/${actionId}/update`

    const formData = new FormData()
    formData.append(
      'data',
      JSON.stringify({
        whoIds,
        status,
        templateResponses,
        customQuestionsResponses,
        lang: store.getters.locale
      })
    )

    if (file) {
      formData.append('file', file)
    }

    return service.put(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  async changeCustomQuestions(actionPlanId, customQuestions) {
    const url = `${this.baseURL}/${actionPlanId}/change-custom-questions`

    return service.put(url, {
      customQuestions
    })
  }

  async addAction(actionPlanId, action) {
    const url = `${this.baseURL}/${actionPlanId}/add-action`
    return service.put(url, { ...action, lang: store.getters.locale })
  }

  async removeAction(actionPlanId, actionId) {
    const url = `${this.baseURL}/${actionPlanId}/${actionId}/remove-action`
    return service.delete(url)
  }

  async removeEvidence(actionPlanId, actionId, fileReference) {
    const data = { fileReference }
    const url = `${this.baseURL}/${actionPlanId}/${actionId}/remove-evidence`
    return service.put(url, data)
  }

  async approveAction(actionPlanId, actionId) {
    return service.put(
      `${this.baseURL}/${actionPlanId}/${actionId}/approve`,
      {}
    )
  }

  async disapproveAction(actionPlanId, actionId, reason) {
    return service.put(
      `${this.baseURL}/${actionPlanId}/${actionId}/disapprove`,
      { reason }
    )
  }

  async replanAction(actionPlanId, actionId, when) {
    return service.put(`${this.baseURL}/${actionPlanId}/${actionId}/replan`, {
      when
    })
  }

  async changeResponsibleUsers(actionPlanId, actionId, whoIds) {
    return service.put(
      `${this.baseURL}/${actionPlanId}/${actionId}/change-responsible-users`,
      { whoIds, lang: store.getters.locale }
    )
  }

  async completeAction(actionPlanId, actionId, file) {
    const url = `${this.baseURL}/${actionPlanId}/${actionId}/complete`

    const formData = new FormData()
    formData.append('file', file)

    return service.put(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  async uncompleteAction(actionPlanId, actionId, newStatus) {
    return service.put(
      `${this.baseURL}/${actionPlanId}/${actionId}/uncomplete`,
      { newStatus }
    )
  }

  async notifyResponsibles(actionPlanId, actionId) {
    return service.post(
      `${this.baseURL}/${actionPlanId}/${actionId}/notify-responsibles`,
      { lang: store.getters.locale }
    )
  }

  async closeActionPlan(actionPlanId) {
    const url = `${this.baseURL}/${actionPlanId}/close`

    return service.put(url)
  }

  async reopenActionPlan(actionPlanId) {
    const url = `${this.baseURL}/${actionPlanId}/reopen`

    return service.put(url)
  }

  async getActionEvidenceFileReference(fileReference) {
    const url = `${this.baseURL}/action-evidence/${fileReference}`
    return service.get(url)
  }

  async getActionsByActionPlan(actionPlanId) {
    const url = `${this.baseURL}/actions-by-action-plan/${actionPlanId}`
    return service.get(url)
  }

  async getActionById(actionId) {
    const url = `${this.baseURL}/action/${actionId}`
    return service.get(url)
  }

  async getMyActionPlans(client, name, limit, page) {
    let skip = (page - 1) * limit
    if (skip < 0) {
      skip = 0
    }

    const query = qs.stringify({ client, name, limit, skip })

    const url = `${this.baseURL}/my-action-plans?${query}`

    return service.get(url)
  }

  async getActionPlansSharedWithMe(
    client,
    createdByOrActionPlanName,
    limit,
    page
  ) {
    let skip = (page - 1) * limit
    if (skip < 0) {
      skip = 0
    }

    const query = qs.stringify({
      client,
      createdByOrActionPlanName,
      limit,
      skip
    })

    const url = `${this.baseURL}/action-plans-shared-with-me?${query}`

    return service.get(url)
  }

  async getMyActions(client, actionOrActionPlanName, status, limit, page) {
    let skip = (page - 1) * limit
    if (skip < 0) {
      skip = 0
    }

    const query = qs.stringify({
      client,
      actionOrActionPlanName,
      status,
      limit,
      skip
    })

    const url = `${this.baseURL}/my-actions?${query}`

    return service.get(url)
  }

  async getPublicActionPlans(client, createdByOrActionPlanName, limit, page) {
    let skip = (page - 1) * limit
    if (skip < 0) {
      skip = 0
    }

    const query = qs.stringify({
      client,
      createdByOrActionPlanName,
      limit,
      skip
    })

    const url = `${this.baseURL}/public-action-plans?${query}`

    return service.get(url)
  }

  async getCustomQuestionsAndActionPlanTypeById(actionPlanId) {
    const url = `${this.baseURL}/custom-questions-and-action-plan-type-by-id/${actionPlanId}`
    return service.get(url)
  }

  async exportActionsByActionPlan(actionPlanId, lang) {
    return service.get(
      `${this.baseURL}/actions-by-action-plan/${actionPlanId}`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer',
        params: {
          exportType: 'xlsx'
        }
      }
    )
  }
}

export const newActionPlanService = new NewActionPlanService()
