const defaultActionPlanTemplate = {
  id: '',
  name: ''
}

const state = {
  tabIndex: 0,
  actionPlanTemplate: defaultActionPlanTemplate
}

const getters = {}

const mutations = {
  SET_TAB_INDEX: (state, payload) => {
    state.tabIndex = payload
    state.actionPlanTemplate = defaultActionPlanTemplate
  },

  SET_ACTION_PLAN_TEMPLATE: (state, payload) => {
    state.actionPlanTemplate = payload
  }
}

const actions = {
  setTabIndex({ commit }, currentTabIndex) {
    commit('SET_TAB_INDEX', currentTabIndex)
  },
  goToCreateActionPlanTemplate({ commit }) {
    commit('SET_TAB_INDEX', 1)
  },
  goToUpdateActionPlanTemplate({ commit }, selectedActionPlanTemplate) {
    commit('SET_TAB_INDEX', 1)
    commit('SET_ACTION_PLAN_TEMPLATE', selectedActionPlanTemplate)
  },
  goToActionPlanTemplateList({ commit }) {
    commit('SET_TAB_INDEX', 0)
  },
  clear({ commit }) {
    commit('SET_TAB_INDEX', 0)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
