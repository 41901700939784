import * as personBondService from '@/services/person-bond-service'

const defaultPersonBond = () => ({
  _id: null,
  person: null,
  document: null,
  admissionId: null,
  email: null,
  client: null,
  admissionDate: null,
  unity: null,
  position: null,
  salary: null,
  workday: null,
  bond: null,
  company: null,
  role: null,
  customFields: null,
  dismissalDate: null,
  isLeadership: false,
  categoryCNH: null,
  registerCNH: null,
  firstCNH: null,
  emissionCNH: null,
  validityCNH: null,
  renachCNH: null,
  mirrorCNH: null,
  statusCNH: null,
  stateCNH: null,
  cityCNH: null,
  driverCountry: null,
  driverState: null,
  driverCity: null,
  driverLicenceType: null,
  driverLicenceExpirationDate: null,
  driverPermissionInBrazilExpirationDate: null
})

const state = {
  personBond: Object.assign({}, defaultPersonBond())
}

const getters = {}

const mutations = {
  SET_PERSONBOND: (state, payload) => {
    state.personBond = payload
  }
}

const actions = {

  loadPersonBond({ commit }, personBondData) {
    commit('SET_PERSONBOND', personBondData)
  },

  clearPersonBond({ commit }) {
    commit('SET_PERSONBOND', defaultPersonBond())
  },

  async savePersonBond({ rootState, commit }, payload) {
    const { persist } = payload
    let { personBond } = payload
    if (persist) {
      if (!payload.client) {
        payload.client = rootState.user.userClient
      }

      const { data } = await personBondService.savePersonBond(personBond)
      personBond = Object.assign(personBond, data)
    }
    commit('SET_PERSONBOND', personBond)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
