
const state = {
  tabIndex: 0
}

const getters = {}

const mutations = {
  SET_TAB_INDEX: (state, payload) => {
    state.tabIndex = payload
  }
}

const actions = {
  setTabIndex({ commit }, currentTabIndex) {
    commit('SET_TAB_INDEX', currentTabIndex)
  },
  goToUpdateActionPlan({ commit }) {
    commit('SET_TAB_INDEX', 1)
  },
  goToActionPlanLists({ commit }) {
    commit('SET_TAB_INDEX', 0)
  },
  clear({ commit }) {
    commit('SET_TAB_INDEX', 0)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
