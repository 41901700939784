import { newActionPlanService } from '@/services/v2/new-action-plan-service'

const defaultActionPlanToUpdate = () => ({
  id: null
})

const defaultActionPlanToViewActions = () => ({
  id: null,
  name: null
})

const defaultActionToUpdate = () => ({
  id: null,
  name: null,
  actionPlan: {
    id: null,
    name: null,
    template: null
  }
})

const state = {
  actionPlanToUpdate: defaultActionPlanToUpdate(),
  actionPlanToViewActions: defaultActionPlanToViewActions(),
  actionToUpdate: defaultActionToUpdate(),
  activeTab: null
}

const mutations = {
  SET_ACTION_PLAN_TO_UPDATE: (state, actionPlanToUpdate) => {
    state.actionPlanToUpdate = actionPlanToUpdate
  },
  SET_ACTION_PLAN_TO_VIEW_ACTIONS: (state, actionPlanToViewActions) => {
    state.actionPlanToViewActions = actionPlanToViewActions
  },
  SET_ACTION_TO_UPDATE: (state, actionToUpdate) => {
    state.actionToUpdate = actionToUpdate
  },
  SET_ACTIVE_TAB: (state, activeTab) => {
    state.activeTab = activeTab
  }
}

const actions = {
  async setActionPlanToUpdate({ commit, rootGetters }, data) {
    const hasIsPrivateKeyInData = Object.keys(data).includes('isPrivate')

    if (hasIsPrivateKeyInData) {
      const userId = rootGetters.userId

      if (data.isPrivate) {
        const user = data.users.find((user) => user.userId === userId)
        if (user) {
          data.userPermission = user.type
        }
      } else {
        data.userPermission = 'ADMIN'
      }
    }

    commit('SET_ACTION_PLAN_TO_UPDATE', data)
  },
  async refreshAcionPlanToUpdate({ dispatch }, actionPlanId) {
    const { data: actionPlan } = await newActionPlanService.getById(
      actionPlanId
    )
    await dispatch('setActionPlanToUpdate', { ...actionPlan, id: actionPlan._id })
  },

  clearActionPlanToUpdate({ commit }) {
    commit('SET_ACTION_PLAN_TO_UPDATE', defaultActionPlanToUpdate())
  },

  async setActionPlanToViewActions({ commit }, data) {
    commit('SET_ACTION_PLAN_TO_VIEW_ACTIONS', data)
  },

  clearActionPlanToViewActions({ commit }) {
    commit('SET_ACTION_PLAN_TO_VIEW_ACTIONS', defaultActionPlanToViewActions())
  },

  async setActionToUpdate({ commit }, data) {
    commit('SET_ACTION_TO_UPDATE', data)
  },

  clearActionToUpdate({ commit }) {
    commit('SET_ACTION_TO_UPDATE', defaultActionToUpdate())
  },

  setActiveTab({ commit }, payload) {
    commit('SET_ACTIVE_TAB', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
