export default {
  titletreinamentos: 'Treinamentos',
  sair: 'Sair',
  novotreinamento: 'Novo treinamento',
  infotreinamento: 'Informações do treinamento',
  codigotreinamento: 'Código do treinamento',
  nometreinamento: 'Nome do treinamento',
  descricaotreinamento: 'Descrição resumida do treinamento',
  informecodigotreinamento: 'Informe o código do treinamento',
  informenometreinamento: 'Informe o nome do treinamento',
  informedescricaotreinamento: 'Faça uma descrição do treinamento',
  salvar: 'Salvar',
  cancelar: 'Cancelar alteração',
  camposnaopreenchidos: 'Há campo(s) obrigatório(s) não preenchido(s)',
  editartreinamento: 'Editar treinamento',
  excluirtreinamento: 'Excluir treinamento',
  treinamentocadastrado: 'Treinamento cadastrado com sucesso!!!',
  treinamentoatualizado: 'Treinamento atualizado com sucesso!!!',
  atencao: 'Atenção!!!',
  aviso: 'Aviso!!!',
  certezaexcluirtreinamento: 'Tem certeza que deseja excluir esse treinamento?',
  treinamentoexcluido: 'Treinamento excluído com sucesso!!!',
  treinamentos: 'Treinamentos',
  novo: 'Novo',
  referencias: 'Referências',
  cargo: 'Cargo',
  acoes: 'Ações',
  imprimir: 'Imprimir',
  exportarpdf: 'Exportar PDF',
  exportarexcel: 'Exportar Excel',
  editar: 'Editar',
  excluir: 'Excluir',
  treinamento: 'Treinamento',
  cadastrado: 'cadastrado com sucesso!!!',
  cadastrada: 'cadastrada com sucesso!!!',
  cadastradas: 'cadastradas com sucesso!!!',
  atualizado: 'atualizado com sucesso!!!',
  atualizada: 'atualizada com sucesso!!!',
  atualizadas: 'atualizadas com sucesso!!!',
  excluido: 'excluído com sucesso!!!',
  excluida: 'excluída com sucesso!!!',
  certezaexcluir: 'Tem certeza que deseja excluir',
  certezacancelar: 'Tem certeza que deseja cancelar',
  este: 'este',
  esta: 'esta',
  cliqueparaselecionar: 'Clique para selecionar',
  selecione: 'Selecione',
  umoumais: 'um ou mais',
  cargomin: 'cargo',
  cargosmin: 'cargos',
  cadastrorealizado: 'Cadastro realizado com sucesso!!!',
  updaterealizado: 'Atualização realizada com sucesso!!!',
  arquivo: 'Arquivo',
  arquivomin: 'arquivo',
  do: 'do',
  da: 'da',
  escolhaarquivo: 'Escolha um arquivo ou arraste-o...',
  escolhaarquivos: 'Escolha um ou mais arquivos ou arraste-os...',
  arquivocadastrado: 'Arquivo cadastrado',
  ver: 'Ver',
  baixar: 'Baixar',
  maodeobra: 'Mão-de-obra',
  arquivorelacionadolocal: 'Documentos / Imagens relacionados ao local',
  textoarquivo:
    'Caso seja aplicável, podem ser cadastradas fotos ou outros arquivos para melhor demonstrar quais as condições ideais para a execução desta tarefa.',
  ambiente: 'Meio ambiente',
  oqueusar: 'O que precisa usar na tarefa?',
  textomateriais:
    'Podem ser incluídos diversos materiais. Devem ser cadastrados um a um.',
  informemateriais: 'Informe os materiais da tarefa',
  arquivorelacionadomaterial: 'Documentos / Imagens relacionados ao material',
  videorelacionadomaterial: 'Vídeo relacionado ao material',
  informeummaterial: 'Informe pelo menos um material',
  material: 'Material',
  materialmin: 'material',
  precisausar: 'O que precisa usar',
  oquesaber: 'O que é preciso saber para realizar o treinamento?',
  textometodo:
    'Podem ser incluídos diversos conhecimentos necessários. Devem ser cadastrados um a um.',
  informesaber: 'Informe o que precisa saber',
  arquivorelacionadometodo: 'Documentos / Imagens relacionados ao método',
  videorelacionadometodo: 'Vídeo relacionado ao método',
  informeummetodo: 'Informe pelo menos um método',
  metodo: 'Método',
  metodomin: 'método',
  precisasaber: 'O que precisa saber',
  oquecontrolar: 'O que precisa controlar no treinamento?',
  textocontrolar:
    'Podem ser incluídos diversas medidas. Devem ser cadastradas uma a uma.',
  informemedidas: 'Informe as medidas da tarefa',
  arquivorelacionadomedida: 'Documentos / Imagens relacionados à medida',
  videorelacionadomedida: 'Vídeo relacionado à medida',
  precisacontrolar: 'O que precisa controlar',
  medida: 'Medida',
  informeumamedida: 'Informe pelo menos uma medida',
  oqueoperar: 'O que precisa operar na tarefa?',
  textooperar:
    'Podem ser incluídos diversas máquinas. Devem ser cadastradas uma a uma.',
  informemaquinas: 'Informe as maquinas utilizadas na tarefa',
  arquivorelacionadomaquina: 'Documentos / Imagens relacionados à máquina',
  videorelacionadomaquina: 'Vídeo relacionado à máquina',
  precisaoperar: 'O que precisa operar',
  maquina: 'Máquina',
  maquinamin: 'máquina',
  informeumamaquina: 'Informe pelo menos uma máquina',
  linguagem: 'Linguagem',
  portugues: 'Português',
  ingles: 'Inglês',
  espanhol: 'Espanhol',
  linkdash: 'Dashboard a ser consultado',
  nomedash: 'Nome do Dashboard',
  linkform: 'Formulário a ser preenchido',
  linkdmforms: 'Formulário DMForms a ser preenchido',
  nomeform: 'Nome do formulário',
  informelinkdash: 'Informe o link do dashboard',
  informenomedash: 'Informe nome do dashboard',
  informelinkform: 'Informe o link do formulário',
  informenomeform: 'Informe o nome do formulário',
  formulariomin: 'formulário',
  formulario: 'Formulário',
  palavraschave: 'Palavras-chave relacionadas ao treinamento',
  informepalavrachave: 'Informe as palavras-chave',
  palavrasadicionadas: 'Palavras-chave adicionadas:',
  excluirpalavra: 'Excluir palavra-chave',
  logomarca: 'Logomarca',
  obrigadoparticipacao: 'Obrigado pela participação',
  respostaenviada: 'Sua resposta foi enviada',
  enviaroutraresposta: 'Enviar outra resposta',
  compartilhar: 'Compartilhar',
  copiadosucesso: 'Copiado com sucesso!!!',
  duplicadosucesso: 'Duplicado com sucesso!!!',
  copiar: 'Copiar',
  convidado: 'Convidado',
  dashboardousite: 'Dashboard / Site',
  obrigatorio: 'Obrigatório',
  cliqueparabaixar: 'Clique para baixar',
  cliqueparaabrir: 'Clique para abrir',
  cliqueparaassistir: 'Clique para assistir',
  arquivos: 'Arquivos',
  arquivosmin: 'arquivos',
  verouocultar: 'Ver ou ocultar',
  videorelacionadolocal: 'Vídeo relacionado ao local',
  maquinas: 'Máquinas',
  materiais: 'Materiais',
  cliente: 'Cliente',
  clientemin: 'cliente',
  clientes: 'Clientes',
  clientesmin: 'clientes',
  umaoumais: 'uma ou mais',
  sim: 'Sim',
  nao: 'Não',
  emminutos: 'em minutos',
  pesquisa: 'Pesquisa',
  pesquisamin: 'pesquisa',
  pesquisas: 'Pesquisas',
  pesquisasmin: 'pesquisas',
  pessoa: 'Pessoa',
  pessoamin: 'pessoa',
  pessoas: 'Pessoas',
  pessoasmin: 'pessoas',
  atribuicoes: 'Atribuições',
  recursos: 'Recursos',
  empresa: 'Empresa',
  operacao: 'Operação',
  atividade: 'Atividade',
  treinamentocomplementar: 'Treinamento complementar',
  datainicio: 'Data de início',
  datatermino: 'Data de término',
  treinamentoe: 'O treinameto é',
  periodoaplicacao: 'Período de aplicação',
  existepontuacaoaprovacao: 'Existe uma pontuação mínima para aprovação?',
  pilar: 'Pilar',
  bloco: 'Bloco',
  item: 'Item',
  origem: 'Origem',
  indicadorassociado: 'Indicador associado',
  enviar: 'Enviar',
  pilares: 'Pilares',
  blocos: 'Blocos',
  itens: 'Itens',
  origens: 'Origens',
  avaliacao: 'Avaliação',
  cargos: 'Cargos',
  locais: 'Locais',
  estrutura: 'Estrutura',
  estruturas: 'Estruturas',
  estruturamin: 'estrutura',
  estruturasmin: 'estruturas',
  usuarios: 'Usuários',
  local: 'Local',
  caracteristicas: 'Características',
  informelocal: 'Informe o local',
  informecaracteristicaslocal: 'Informe as características do local',
  localcadastrado: 'Local cadastrado com sucesso!!!',
  localatualizado: 'Local atualizado com sucesso!!!',
  localexcluido: 'Local excluído com sucesso!!!',
  certezaexcluirlocal: 'Tem certeza que deseja excluir esse local?',
  descricao: 'Descrição',
  informecargo: 'Informe o cargo',
  informedescricaocargo: 'Informe a descrição do cargo',
  cargocadastrado: 'Cargo cadastrado com sucesso!!!',
  cargoatualizado: 'Cargo atualizado com sucesso!!!',
  cargoexcluido: 'Cargo excluído com sucesso!!!',
  certezaexcluircargo: 'Tem certeza que deseja excluir esse cargo?',
  certezaduplicarvaga:
    'Tem certeza que deseja duplicar' + '\n' + 'essa vaga de emprego?',
  grupo: 'Grupo',
  grupos: 'Grupos',
  grupomin: 'grupo',
  informegrupo: 'Informe o nome do grupo',
  grupocadastrado: 'Grupo cadastrado com sucesso!!!',
  grupoatualizado: 'Grupo atualizado com sucesso!!!',
  grupoexcluido: 'Grupo excluído com sucesso!!!',
  escondermaquinas: 'Clique para mostrar ou ocultar as máquinas desse grupo',
  cadastrogrupos: 'Cadastro de grupos de máquinas',
  cadastromaquinas: 'Cadastro de máquinas',
  informemaquina: 'Informe o nome da máquina',
  naohamaquinas: 'Não há máquinas cadastradas nesse grupo',
  cadastrogruposrecursos: 'Cadastro de grupos de recursos',
  cadastrorecursos: 'Cadastro de recursos',
  naohamateriais: 'Não há materiais cadastrados nesse grupo',
  informematerial: 'Informe o nome do material',
  empresamin: 'empresa',
  empresasmin: 'empresas',
  nome: 'Nome',
  informenomeusuario: 'Informe o nome do usuário',
  email: 'Email',
  informeemailusuario: 'Informe o email do usuário',
  celular: 'Celular',
  informecelularusuario: 'Informe o celular do usuário',
  informecargousuario: 'Informe o cargo do usuário',
  ativo: 'Ativo',
  usuariocadastrado: 'Usuário cadastrado com sucesso!!!',
  codigounidade: 'Código da unidade',
  informecodigounidade: 'Informe o código da unidade',
  senha: 'Senha',
  informesenhausuario: 'Informe a senha do usuário',
  tamanhosenha: 'A senha deve ter pelo menos 8 caracteres',
  perfil: 'Perfil',
  perfilmin: 'perfil',
  unidade: 'Unidade',
  unidademin: 'unidade',
  unidades: 'Unidades',
  unidadesmin: 'unidades',
  temacessograde: 'Tem acesso à grade',
  biblioteca: 'Biblioteca',
  bibliotecamin: 'biblioteca',
  bibliotecasmin: 'bibliotecas',
  usuarioscadastrados: 'Usuarios registrados',
  administradordemarco: 'Administrador Demarco',
  cadastroestruturainicial: 'Cadastro de estrutura inicial',
  cadastroestruturasequencial: 'Cadastro de estruturas sequenciais',
  informenomeestrutura: 'Informe o nome da estrutura',
  informedetalhesestrutura: 'Informe detalhes da estrutura',
  ativa: 'Ativa',
  ativamin: 'ativa',
  detalhes: 'Detalhes',
  opcional: 'opcional',
  cadastrobibliotecainicial: 'Cadastro de biblioteca inicial',
  cadastrobibliotecasequencial: 'Cadastro de bibliotecas sequenciais',
  cadastreumcargo: 'Cadastre um cargo para selecionar',
  administrador: 'Administrador',
  usuario: 'Usuário',
  tituloambiente: 'Meio Ambiente (ONDE será realizado)',
  titulomaodeobra: 'Mão-de-obra (POR QUEM será realizado)',
  titulomaterial: 'Material (o que precisa USAR)',
  titulometodo: 'Método (o que precisa SABER)',
  titulomedida: 'Medida (o que precisa CONTROLAR)',
  titulomaquina: 'Máquina (o que precisa OPERAR)',
  cadastro: 'Cadastro',
  planejamento: 'Planejamento',
  tituloplano: 'O QUE, PORQUE, ONDE e POR QUEM será feito',
  titulocomo: 'COMO será feito',
  tituloquando: 'QUANDO será feito',
  titulocustos: 'Componentes de CUSTOS do treinamento',
  infopessoa: 'Informações da pessoa',
  catalogo: 'Catálogo',
  catalogomin: 'catálogo',
  cadastrogradesequencial: 'Cadastro de grades sequenciais',
  cadastrogradeinicial: 'Cadastro de grade inicial',
  informenomegrade: 'Informe o nome da grade',
  informedetalhesgrade: 'Informe os detalhes da grade',
  cpf: 'CPF',
  informecpf: 'Informe o CPF',
  cadastropessoa: 'Cadastro de pessoa',
  novapessoa: 'Nova pessoa',
  pessoais: 'Dados pessoais',
  funcionais: 'Dados funcionais',
  sociais: 'Dados sociais',
  transacionais: 'Dados transacionais',
  datanascimento: 'Data de nascimento',
  nomepai: 'Nome do pai',
  nomemae: 'Nome da mãe',
  informedatanascimento: 'Informe a data de nascimento',
  informenomepai: 'Informe o nome do pai',
  informenomemae: 'Informe o nome da mãe',
  dataadmissao: 'Data de admissão',
  informedataadmissao: 'Informe a data de admissão',
  salario: 'Salário',
  informesalario: 'Informe o salário',
  codigopostal: 'Código postal',
  cep: 'CEP',
  informecodigopostal: 'Informe o código postal',
  logradouro: 'Logradouro',
  informelogradouro: 'Informe o logradouro',
  numero: 'Número',
  informenumero: 'Informe o número',
  bairro: 'Bairro',
  informebairro: 'Informe o bairro',
  cidade: 'Cidade',
  cidademin: 'cidade',
  informecidade: 'Informe a cidade',
  estado: 'Estado',
  estadomin: 'estado',
  informeestado: 'Informe o estado',
  pais: 'País',
  informepais: 'Informe o país',
  jornadatrabalho: 'Jornada de Trabalho',
  informejornadatrabalho: 'Informe a jornada de trabalho',
  informenome: 'Informe o nome',
  notaminima: 'Pontuação mínima para aprovação',
  informenotaminima: 'Informe a pontução mínima para aprovação',
  informenotamaxima: 'Informe a pontuação máxima',
  notamaxima: 'Pontuação máxima',
  tempontuacao: 'Existe pontuação mínima para aprovação',
  vinculadograde: 'Vinculado à grade',
  cadastre: 'Cadastre',
  um: 'um',
  uma: 'uma',
  incluircampo: 'Incluir campo',
  ondeserarealizado: 'Onde será realizado',
  localmin: 'local',
  desejaadicionar: 'Deseja adicionar / remover algum item?',
  selecioneparaadicionar: 'Selecione um ou mais itens para adicionar',
  imagemoudocumento: 'Documentos / Imagens',
  formularioexterno: 'Formulário externo',
  video: 'Vídeo',
  videomin: 'vídeo',
  quemparticipara: 'Quem participará do treinamento?',
  quemparticipa: 'Quem participa',
  naoaplicatreinamento: 'Não se aplica a esse treinamento',
  preenchermanualmente: 'Preencher informações',
  copiardadostreinamento: 'Copiar informações do treinamento',
  copiardados: 'Copiar informações de outro treinamento',
  informelinkvideo: 'Informe o link do vídeo no Youtube, Vimeo ou Google Drive',
  invalidLinkVideoURL:
    'O link do vídeo deve ser do Youtube, Vimeo ou Google Drive',
  nomevideo: 'Nome do vídeo',
  informenomevideo: 'Informe o nome do vídeo',
  oqueserafeito: 'O que será feito no treinamento?',
  informeoqueserafeito: 'Informe o que será feito no treinamento',
  porqueserafeito: 'Por que o treinamento deve ser realizado?',
  informeporqueserafeito: 'Informe o motivo da realização do treinamento',
  quemeresponsavel: 'Quem é o responsável pela realização do treinamento?',
  responsavelmin: 'responsável',
  responsavel: 'Responsável',
  passoexecucao: 'Passo para realização do treinamento',
  informeopassoexecucao: 'Informe o passo para realização do treinamento',
  frequenciamin: 'frequência',
  frequencia: 'Frequência',
  comquefrequencia: 'Com que frequência será realizado o treinamento?',
  prazoexecucao: 'Prazo para execução',
  prazoexecucaomin: 'prazo para execução',
  emdias: '(em dias)',
  pordemanda: 'Por demanda',
  diaria: 'Diária',
  semanal: 'Semanal',
  quinzenal: 'Quinzenal',
  mensal: 'Mensal',
  semestral: 'Semestral',
  anual: 'Anual',
  segunda: 'Segunda-feira',
  terca: 'Terça-feira',
  quarta: 'Quarta-feira',
  quinta: 'Quinta-fira',
  sexta: 'Sexta-feira',
  sabado: 'Sábado',
  domingo: 'Domingo',
  janeiro: 'Janeiro',
  fevereiro: 'Fevereiro',
  marco: 'Março',
  abril: 'Abril',
  maio: 'Maio',
  junho: 'Junho',
  julho: 'Julho',
  agosto: 'Agosto',
  setembro: 'Setembro',
  outubro: 'Outubro',
  novembro: 'Novembro',
  dezembro: 'Dezembro',
  informediavalido: 'Informe um dia válido',
  informeprazovalido: 'Informe um prazo válido',
  emmin: '(em min.)',
  fatogerador: 'Fato gerador da demanda',
  informefatogerador: 'Informe o fato gerador da demanda',
  diaprimeiraquinzena: 'Dia de execução na primeira quinzena',
  informediaprimeiraquinzena: 'Informe o dia de execução na primeira quinzena',
  diasegundaquinzena: 'Dia de execução na segunda quinzena',
  informediasegundaquinzena: 'Informe o dia de execução na segunda quinzena',
  mesprimeirosemestre: 'Mês do primeiro semestre',
  messegundosemestre: 'Mês do segundo semestre',
  horadia: 'Hora do dia',
  diasemana: 'Dia da semana',
  diasemanamin: 'dia da semana',
  diaexecucao: 'dia de execução',
  primeiro: 'Primeiro',
  segundo: 'Segundo',
  primeiromin: 'primeiro',
  segundomin: 'segundo',
  diames: 'Dia do mês',
  diamesmin: 'dia do mês',
  mes: 'Mês',
  mesmin: 'mês',
  mesano: 'Mês do ano',
  tempoduracaotreinamento: 'Tempo de duração do treinamento',
  informetempoduracaotreinamento: 'Informe o tempo de duração do treinamento',
  informetempoduracaopesquisa: 'Informe o tempo de duração da pesquisa',
  informediames: 'Informe o dia do mês',
  naoinformado: 'Não informado',
  treinamentoobrigatorio: 'O treinamento é obrigatório',
  quaispessoasparticiparaotreinamento:
    'Quais pessoas participarão do treinamento?',
  quaispessoasparticiparaopesquisa: 'Quais pessoas participarão da pesquisa?',
  recurso: 'Recurso',
  informerecurso: 'Informe o nome do recurso',
  recursomin: 'recurso',
  grade: 'Grade',
  grademin: 'grade',
  adicionargradesequencial: 'Adicionar grade sequencial',
  dataespecifica: 'Data específica',
  avaliacaomin: 'avaliação',
  gruposrecursos: 'Grupos de recursos',
  gruposmin: 'grupos',
  recursosmin: 'recursos',
  diariamente: 'Diariamente às',
  toda: 'Toda',
  dias: 'Dias',
  e: 'e',
  dia: 'Dia',
  nosmesesde: 'Nos meses de',
  nomesde: 'Anualmente, em',
  a: 'a',
  o: 'o',
  decadames: 'de cada mês',
  quandoacontecera: 'Quando acontecerá',
  naoidentificado: 'Não identificado',
  unidadesparticipantes: 'Unidades participantes',
  cargosparticipantes: 'Cargos participantes',
  planejamentomin: 'planejamento',
  arquivorelacionadotreinamento:
    'Documentos / Imagens relacionados ao treinamento',
  quaiscargosdevemparticipar: 'Quais cargos devem participar?',
  videorelacionadotreinamento: 'Vídeo relacionado ao treinamento',
  avaliacoes: 'Avaliações',
  avaliacoesmin: 'avaliações',
  novaavaliacao: 'Nova avaliação',
  campo: 'Campo',
  campomin: 'campo',
  cadastroavaliacao: 'Cadastro de avaliação',
  nomeavaliacao: 'Nome da avaliação',
  informenomeavaliacao:
    'Informe o nome da avaliação (nome curto preferencialmente)',
  descricaoavaliacao: 'Descrição da avaliação',
  informedescricaoavaliacao: 'Informe a descrição da avaliação',
  adicionarsecao: 'Adicionar seção',
  adicionarcampo: 'Adicionar campo',
  nomesecao: 'Nome da seção',
  informenomesecao: 'Informe o nome da seção',
  salvarsecao: 'Salvar seção',
  cancel: 'Cancelar',
  nomecampo: 'Nome do campo ou pergunta a ser respondida',
  informenomecampo: 'Informe o nome do campo',
  textonomecampo:
    'Texto que aparecerá como rótulo na avaliação. Não poderá ser alterado após respostas serem gravadas',
  textoincludecampo: 'Texto que aparecerá como rótulo no campo',
  tipocampo: 'Tipo do campo',
  selecionetipocampo: 'Selecione o tipo de campo',
  listaopcoes: 'Lista de opções',
  informeopcao: 'Informe a opção',
  excluiropcao: 'Excluir opção',
  campoobrigatorio: 'Campo obrigatório',
  campos: 'Campos',
  camposmin: 'campos',
  secao: 'Seção',
  secaomin: 'seção',
  escolhaopcao: 'Escolha uma opção',
  avaliacaoatualizada: 'Avaliação atualizada com sucesso!!!',
  avaliacaocadastrada: 'Avaliação cadastrada com sucesso!!!',
  avancar: 'Avançar',
  preencher: 'Preencher',
  data: 'Data',
  opcao: 'Opção',
  selecaounica: 'Seleção de único item',
  selecaomultipla: 'Seleção de múltipos itens',
  textocurto: 'Texto curto',
  textolongo: 'Texto longo',
  cliqueeditar: 'Clique para editar',
  consultarinformacoes: 'Consultar informações',
  informacoesenviadas: 'Informações enviadas com sucesso!!!',
  informacoesexcluidas: 'Informações excluídas com sucesso!!!',
  preenchidopor: 'Preenchido por',
  datapreenchimento: 'Data do preenchimento',
  estas: 'estas',
  informacoesmin: 'informações',
  telefone: 'Telefone',
  hora: 'Hora',
  informeemailvalido: 'Informe um email válido',
  adicionarimagem: 'Adicionar imagem',
  adicionarvideo: 'Adicionar vídeo',
  nomevideopreview: 'Nome do vídeo ou texto a ser exibido',
  informenomevideopreview: 'Informe o nome do vídeo ou texto a ser exibido',
  nomearquivopreview: 'Nome do arquivo ou texto a ser exibido',
  informenomearquivopreview: 'Informe o nome do arquivo ou texto a ser exibido',
  informenomearquivo: 'Informe o nome do arquivo',
  informedescricaoarquivo: 'Informe a descrição do arquivo',
  pequena: 'Pequena',
  grande: 'Grande',
  tamanhonatela: 'Informe o tamanho da imagem na tela',
  imagem: 'Imagem',
  semsecao: 'Antes da primeira seção',
  valorminimo: 'Valor mínimo',
  informevalorminimo: 'Informe o valor mínimo',
  valormaximo: 'Valor máximo',
  informevalormaximo: 'Informe o valor máximo',
  quantidademinimo: 'Quantidade mínima de opções selecionáveis',
  informequantidademinimo: 'Informe a quantidade mínima',
  quantidademaximo: 'Quantidade máxima de opções selecionáveis',
  informequantidademaximo: 'Informe a quantidade máxima',
  duplicar: 'Duplicar',
  voltar: 'Voltar',
  classificacao: 'Classificação',
  niveis: 'Níveis',
  simbolo: 'Símbolo',
  estrela: 'Estrela',
  treinamentomin: 'treinamento',
  limparpesquisa: 'Limpar pesquisa',
  informepalavrachaveparapesquisar:
    'Informe uma palavra-chave para pesquisar e tecle enter',
  informepalavraparapesquisar:
    'Informe uma palavra para pesquisar e tecle enter',
  valordaquestao: 'Valor da questão',
  informevalordaquestao: 'Informe o valor da questão',
  somainvalida:
    'A soma dos pontos das questões não pode ser superior à pontuação máxima da avaliação',
  valornaopodesernegativo: 'O valor da questão não pode ser menor que zero',
  respostacorreta: 'Resposta correta',
  selecionerespostacorreta: 'Selecione a resposta correta',
  respostascorretas: 'Respostas corretas',
  selecionerespostascorretas: 'Selecione as respostas corretas',
  vincular: 'Vincular',
  ocultarinformacoes: 'Ocultar informações',
  mostrarinformacoes: 'Mostrar informações',
  aprovado: 'Aprovado',
  reprovado: 'Reprovado',
  pontuacaoobtida: 'Pontuação obtida',
  resultado: 'Resultado',
  gradesmin: 'grades',
  inserirnatelade: 'Inserir na tela de',
  selecionetela: 'Selecione a tela',
  titulocampo: 'Nome do campo',
  tipo: 'Tipo',
  tela: 'Tela',
  vinculo: 'Vínculo',
  vinculos: 'Vínculos',
  vinculomin: 'vínculo',
  informevinculo: 'Informe o vínculo',
  informedescricaovinculo: 'Informe a descricao do vínculo',
  jornadadetrabalho: 'Jornada de trabalho',
  jornadas: 'Jornadas',
  jornadamin: 'jornada de trabalho',
  informejornada: 'Informe a jornada de trabalho',
  informedescricaojornada: 'Informe a descricao da jornada de trabalho',
  remuneracao: 'Remuneração',
  remuneracaobeneficios: 'Remuneração e Benefícios',
  contratacao: 'Contratação',
  recrutamentoselecao: 'Recrutamento e Seleção',
  movimentacao: 'Movimentação',
  carreira: 'Carreira',
  novapesquisa: 'Nova pesquisa',
  configuracao: 'Configuração',
  gerenciamento: 'Gerenciamento',
  movimentacaoregistro: 'Movimentação e Registro',
  relacionamento: 'Relacionamento',
  acompanhamentoretencao: 'Acompanhamento e Retenção',
  desenvolvimento: 'Desenvolvimento',
  integracaotreinamento: 'Integração e Treinamento',
  informetelefone: 'Informe o telefone',
  documentoidentificacao: 'Documento de identificação',
  textodocumentoidentificacao: 'No Brasil, informe o CPF',
  gradetreinamentos: 'Grade de treinamentos',
  gradetbaseconhecimento: 'Grade de Arquivos',
  foto: 'Foto',
  pessoasparticipantes: 'Pessoas participantes',
  textovalidanotaminima:
    'A nota mínima para aprovação não pode ser superior ao valor da avaliação.',
  cadastropesquisa: 'Cadastro de pesquisa',
  nomepesquisa: 'Nome da pesquisa',
  informenomepesquisa: 'Informe o nome da pesquisa',
  descricaopesquisa: 'Descrição da pesquisa',
  informedescricaopesquisa: 'Informe a descrição da pesquisa',
  dataterminoinvalida:
    'A data de término não pode ser anterior à data de início',
  dataatestadoinvalida:
    'A data do atestado não pode ser posterior à data de início',
  tempeso: 'As respostas terão pesos',
  opcoes: 'Opções',
  pesos: 'Pesos',
  respostaobrigatoria: 'Resposta obrigatória',
  pesoresposta: 'Peso da resposta',
  documento: 'Documento',
  admissao: 'Admissão',
  afastamentos: 'Afastamentos',
  ausencias: 'Ausências',
  demissao: 'Demissão',
  tipodemissao: 'Tipo de demissão',
  tipoocorrencia: 'Tipo de ocorrência',
  datademissao: 'Data de demissão',
  dataocorrencia: 'Data da ocorrência',
  ferias: 'Férias',
  jornada: 'Jornada',
  ocorrencias: 'Ocorrências',
  transferencias: 'Transferências',
  demissoes: 'Demissões',
  cadastrosubcategoriademissao: 'Cadastro de subcategoria de demissão',
  cadastrocategoriademissao: 'Cadastro de categoria de demissão',
  informecategoria: 'Informe o nome da categoria',
  informedetalhescategoria: 'Informe os detalhes da categoria',
  categoria: 'Categoria',
  categoriamin: 'categoria',
  adicionarsubcategoria: 'Adicionar subcategoria',
  cadastrosubcategoriaocorrencia: 'Cadastro de subcategoria de ocorrência',
  cadastrocategoriaocorrencia: 'Cadastro de categoria de ocorrência',
  cadastrosubcategoriatransferencia:
    'Cadastro de subcategoria de transferência',
  cadastrocategoriatransferencia: 'Cadastro de categoria de transferência',
  cadastrosubcategoriaafastamento: 'Cadastro de subcategoria de afastamento',
  cadastrocategoriaafastamento: 'Cadastro de categoria de afastamento',
  exigejustificativa: 'Exige justificativa e documento comprobatório',
  exigedocumentocomprobatorio: 'Exige documento comprobatório',
  exigeatestado: 'Exige apresentação de atestado médico',
  cadastrosubcategoriaausencia: 'Cadastro de subcategoria de ausência',
  cadastrocategoriaausencia: 'Cadastro de categoria de ausência',
  cadastrosubcategoriapromocao: 'Cadastro de subcategoria de promoção',
  cadastrocategoriapromocao: 'Cadastro de categoria de promoção',
  colaborador: 'Colaborador',
  dataafastamento: 'Data de afastamento',
  tipoafastamento: 'Tipo de afastamento',
  cadastreumafastamento:
    'Cadastre um tipo de afastamento em Configuração / Afastamentos',
  cadastreumademissao:
    'Cadastre um tipo de demisão em Configuração / Demissões',
  cadastreumaocorrencia:
    'Cadastre um tipo de ocorrência em Configuração / Ocorrências',
  tipomin: 'tipo',
  justificativa: 'Justificativa',
  informejustificativa: 'Informe a justificativa do afastamento',
  arquivosrelacionadosafastamento: 'Arquivos relacionados ao afastamento',
  arquivosrelacionadosdemissao: 'Arquivos relacionados a demissão',
  arquivosrelacionadosocorrencia: 'Arquivos relacionados a ocorrência',
  dataterminoafastamento: 'Data de término do afastamento',
  dataretorno: 'Data de retorno',
  afastamentocadastrado: 'Afastamento cadastrado com sucesso!!!',
  afastamentoatualizado: 'Afastamento atualizado com sucesso!!!',
  afastamentoexcluido: 'Afastamento excluído com sucesso!!!',
  demissaocadastrado: 'Demissão cadastrada com sucesso!!!',
  demissaoatualizado: 'Demissão atualizada com sucesso!!!',
  demissaoexcluido: 'Demissão excluída com sucesso!!!',
  demissaocancelada: 'Demissão cancelada com sucesso!!!',
  ocorrenciacadastrado: 'Ocorrência cadastrada com sucesso!!!',
  ocorrenciaatualizado: 'Ocorrência atualizada com sucesso!!!',
  ocorrenciaexcluido: 'Ocorrência excluída com sucesso!!!',
  ocorrenciacancelada: 'Ocorrência cancelada com sucesso!!!',
  esteafastamento: 'este afastamento',
  estademissao: 'esta demissão',
  estaocorrencia: 'esta ocorrência',
  baixarimagem: 'Baixar imagem',
  baixardocumento: 'Baixar documento',
  arquivoscadastrados: 'Arquivos cadastrados',
  tipoausencia: 'Tipo de ausência',
  cadastreumaausencia:
    'Cadastre um tipo de ausência em Configuração / Ausências',
  arquivosrelacionadosausencia: 'Arquivos relacionados à ausência',
  ausenciacadastrada: 'Ausência cadastrada com sucesso!!!',
  ausenciaatualizada: 'Ausência atualizada com sucesso!!!',
  ausenciaexcluida: 'Ausência excluída com sucesso!!!',
  estaausencia: 'esta ausência',
  tipoatestado: 'Tipo de atestado',
  medico: 'Médico',
  odontologico: 'Odontológico',
  codigodoenca: 'Código da doença',
  informecodigodoenca:
    'Informe o código da doença ou preencha com Não informado',
  identificacaomedico: 'Identificação do médico / dentista',
  informeidentificacaomedico: 'Informe a identificação do médico/dentista',
  textocid:
    'Como o paciente pode solicitar que o código da doença não seja mencionado no atestado, a ausência dessa informação também deverá ser registrada no sistema: “NÃO INFORMADO”',
  dataatestado: 'Data do atestado',
  promocao: 'Promoção',
  promocoes: 'Promoções',
  transferenciaexcluida: 'Transferência excluída com sucesso!!!',
  transferenciacancelada: 'Transferência cancelada com sucesso!!!',
  estatransferencia: 'esta transferência',
  transferenciaatualizada: 'Transferência atualizada com sucesso!!!',
  transferenciacadastrada: 'Transferência cadastrada com sucesso!!!',
  motivoentrada: 'Motivo da entrada',
  motivosaida: 'Motivo da saída',
  estruturadestino: 'Estrutura destino',
  estruturaatual: 'Estrutura atual',
  datamovimentacao: 'Data da movimentação',
  arquivosrelacionadostransferencia: 'Arquivos relacionados à transferência',
  cadastreumatransferencia:
    'Cadastre um tipo de transferência em Configuração / Transferências',
  unidadedestinoinvalida:
    'A estrutura de destino deve ser diferente da estrutura atual',
  peridodoaplicacao: 'Período de aplicação',
  datainiciopromocao: 'Data de início da promoção',
  cargoatual: 'Cargo atual',
  salarioatual: 'Salário atual',
  novocargo: 'Novo cargo',
  novosalario: 'Novo salário',
  informenovosalario: 'Informe o novo salário',
  tipopromocao: 'Tipo de promoção',
  cadastreumapromocao:
    'Cadastre um tipo de promoção em Configuração / Promoções',
  arquivosrelacionadospromocao: 'Arquivos relacionados à promoção',
  datapromocao: 'Data promoção',
  promocaomin: 'promoção',
  arquivosrelacionadospesquisa: 'Arquivos relacionados à pesquisa',
  tempoduracaopesquisa: 'Tempo de duração da pesquisa',
  marcartodos: 'Marcar / Desmarcar todos',
  marcartodas: 'Marcar / Desmarcar todas',
  desenvolvimentoecarreira: 'Desenvolvimento e Carreira',
  dossievirtual: 'Workplace',
  eventosfolha: 'Eventos Folha',
  encargos: 'Encargos',
  provisoes: 'Provisões',
  remuneracaovariavel: 'Remuneração Variável',
  adiantamentosalarial: 'Adiantamento Salarial',
  beneficios: 'Benefícios',
  dadosbancarios: 'Dados Bancários',
  parametros: 'Parâmetros',
  recibos: 'Recibos',
  folhadepagamento: 'Folha de Pagamento',
  relatorios: 'Relatórios',
  programacao: 'Programação',
  controle: 'Controle',
  documentos: 'Documentos',
  numeroperiodoaquisitivo: 'Número do período aquisitivo (PA) de férias',
  informenumeroperiodoaquisitivo:
    'Informe o número sequencial a que se refere esse período aquisitivo',
  datainicioperiodoaquisitivo: 'Data de início do período aquisitivo (PA)',
  dataterminoperiodoaquisitivo: 'Data de término do período aquisitivo (PA)',
  diasantecedencia:
    'Dias de antecedência para sair de férias antes do término do período aquisitivo (PA)',
  informediasantecedencia: 'Informe o número de dias de antecedência',
  datalimitesairferias: 'Data limite para sair de férias',
  numerofaltasperiodoaquisitivo: 'Número de faltas no período aquisitivo',
  informenumerofaltaspa: 'Informe o número de faltas',
  numerodiasferias: 'Número de dias de férias',
  informenumerodiasferias:
    'Informe o número de dias que o colaborador sairá de férias',
  opcaoabonopecuniario: 'Opção por abono pecuniário',
  opcaoantecipacao: 'Opção por antecipação 1ª parcela do 13º salário',
  datainicioferias: 'Data de início das férias',
  dataterminoferias: 'Data de término das férias',
  numeroperiodoaquisitivoinvalido:
    'O número do período aquisitivo deve ser maior que 0',
  diasantecedenciainvalido:
    'O número de dias de antecedência deve ser maior ou igual a 30',
  diasferiasinvalido: 'O número de dias de férias deve ser maior que 0',
  datainicioferiasinvalida:
    'A data de início das férias não pode ser anterior à data de início do período aquisitivo',
  datainicioferiasfimperiodo:
    'A data de início das férias não pode ser posterior à data de término do período concessivo',
  numerofaltasinvalido: 'O número de faltas deve ter valor maior ou igual a 0',
  colaboradorsaldozero:
    'Colaborador não tem saldo de férias para o período aquisitivo informado',
  saldoferiasinsuficiente:
    'Número de dias de férias superior ao saldo do colaborador. Saldo atual: ',
  numeropa: 'Número do PA',
  datainiciopa: 'Data início PA',
  dataterminopa: 'Data término PA',
  numerofaltas: 'Número faltas',
  saldodiasferias: 'Saldo dias férias',
  iniciogozoferias: 'Início gozo férias',
  terminogozoferias: 'Término gozo férias',
  totaldiasferias: 'Total dias férias',
  abonopecuniario: 'Abono pecuniário',
  antecipar13sal: 'Antecipar 13º sal. (S/N)',
  aprovada: 'Aprovada (S/N)',
  S: 'S',
  N: 'N',
  feriasmin: 'férias',
  solicitacaoabonopecuniario: 'Solicitação de abono pecuniário',
  solicitacaoantecipar13sal: 'Solicitação antecipar 13º salário',
  avisoferias: 'Aviso de férias',
  reciboferias: 'Recibo de férias',
  gerar: 'Gerar',
  vagas: 'Vagas',
  vaga: 'Vaga',
  vagamin: 'vaga',
  candidatos: 'Candidatos',
  candidatosmin: 'candidatos',
  formularios: 'Formulários',
  entrevistas: 'Entrevistas',
  exames: 'Exames',
  agenciasrs: 'Agências R&S',
  informediasabono: 'Informe a quantidade de dias referentes ao abono',
  diasabonoinvalido:
    'A quantidade de dias de abono deve ser maior ou igual a 0',
  diasabonoinsuficiente:
    'O número de dias de férias somado ao número de dias de abono não pode ser superior ao saldo de dias de férias. Saldo atual: ',
  escalas: 'Escalas',
  marcacoes: 'Marcações',
  compensacoes: 'Compensações',
  adicionais: 'Adicionais',
  quadropessoal: 'Quadro de Pessoal',
  quantidadeidealpessoas: 'Quantidade ideal de pessoas',
  staffnuminvalido: 'O número de pessoas deve ser maior ou igual a 0',
  camporepetido: 'Não podem ser inseridos dois campos com o mesmo nome',
  secaorepetida: 'Não podem ser inseridas duas seções com o mesmo nome',
  empresatrabalha: 'Empresa na qual trabalha',
  empresasacessar: 'Empresas que irá acessar',
  permissoesempresa: 'Permissões na empresa ',
  admin_sistema: 'Administrador Demarco',
  camposobrigatorios: 'Campos obrigatórios',
  exibirusuarios: 'Exibir usuários',
  ativos: 'Ativos',
  inativos: 'Inativos',
  hierarquia: 'Hierarquia',
  reunioes: 'Reuniões',
  feedback: 'Feedback',
  planos: 'Planos',
  pipeline: 'Pipeline',
  geral: 'Geral',
  condutor: 'Condutor',
  planoacao: 'Plano de ação',
  aprovacoes: 'Aprovações',
  marqueitensaprovacao: 'Assinale os itens que necessitam de aprovação',
  atividades: 'Atividades',
  experiencia: 'Experiência',
  habilidades: 'Habilidades',
  funcoeseresponsabilidadescargo: 'Funções e responsabilidades do cargo',
  textocargoplanejamento:
    'A seleção de cargos é obrigatória caso não sejam selecionadas pessoas',
  textopessoasplanejamento:
    'A seleção de pessoas é obrigatória caso não sejam selecionados cargos',
  caracteristica: 'Característica',
  caracteristicamin: 'característica',
  informecaracteristica: 'Informe a característica',
  peso: 'Peso',
  informepeso: 'Informe o peso dessa característica',
  opcoesresposta: 'Opções de resposta',
  informeopcaoresposta: 'Informe as opções de resposta',
  opcoesadicionadas: 'Opções adicionadas',
  referencia: 'Referência',
  referenciamin: 'referência',
  nivelexigencia: 'Nível de exigência',
  desejavel: 'Desejável',
  recomendavel: 'Recomendável',
  pesoinvalido: 'O valor do peso deve ser maior ou igual a zero',
  informecargoparapesquisar: 'Informe um cargo para pesquisar',
  contratacoes: 'Contratações',
  cadastrosubcategoriacontratacao: 'Cadastro de subcategoria de contratação',
  cadastrocategoriacontratacao: 'Cadastro de categoria de contratação',
  requisicao: 'Requisição',
  aprovacao: 'Aprovação',
  anunciarvaga: 'Anunciar vaga',
  novavaga: 'Nova vaga',
  caracteristicasnaoinformadas: 'Características não informadas',
  datarequisicao: 'Data da requisição',
  codigovaga: 'Código da vaga',
  informecodigovaga: 'Informe o código da vaga',
  nomevaga: 'Nome da vaga',
  informenomevaga: 'Informe o nome da vaga',
  justificativacontratacao: 'Justificativa da contratação',
  cadastreummotivo: 'Cadastre um motivo em Configuração / Contratações',
  motivomin: 'motivo',
  quantidadevagas: 'Quantidade de vagas',
  informequantidadevagas: 'Informe a quantidade de vagas',
  salarioreferencia: 'Salário de referência',
  informesalarioreferencia: 'Informe o salário de referência',
  prazocontratacao: 'Prazo para contratação (em dias)',
  informeprazocontratacao: 'Informe o prazo para contratação',
  tiporecrutamento: 'Tipo de recrutamento',
  interno: 'Interno',
  externo: 'Externo',
  misto: 'Misto',
  nomeaprovador: 'Nome do aprovador',
  informenomeaprovador: 'Informe o nome do aprovador',
  emailaprovador: 'Email do aprovador',
  informeemailaprovador: 'Informe o email do aprovador',
  celularaprovador: 'Celular do aprovador',
  informecelularaprovador: 'Informe o celular do aprovador',
  cadastreumaestrutura: 'Cadastre uma estrutura em Configuração / Estrutura',
  quantidadevagasinvalida: 'A quantidade de vagas deve ser maior que 0',
  salarioinvalido: 'O salário deve ser maior que 0',
  prazoinvalido: 'O prazo de contratação deve ser maior que 0',
  aguardandoaprovacao: 'Aguardando aprovação',
  aprovadavaga: 'Aprovada',
  reprovada: 'Reprovada',
  horariotrabalho: 'Horário de trabalho',
  informehorariotrabalho: 'Informe o horário de trabalho',
  aprovaroureprovar: 'Aprovar ou reprovar',
  aprovarvaga: 'Aprovar vaga',
  reprovarvaga: 'Reprovar vaga',
  formulariocandidatos: 'Formulário candidatos',
  formularioinscricao: 'Formulário de inscrição',
  tituloformulario: 'Título do formulário',
  informetituloformulario: 'Informe o título do formulário',
  icp: 'Índice de conformidade de perfil',
  icpparaaprovacao: 'Índice de conformidade de perfil mínimo (%)',
  informeicpparaaprovacao: 'Informe o valor mínimo de ICP para aprovação',
  descricaoformulario: 'Descrição do formulário',
  informedescricaoformulario: 'Informe a descrição do formulário',
  formulariocadastrocandidatos: 'Formulário de cadastro de candidatos',
  formularioinscricaocandidatos: 'Formulário de inscrição de candidatos',
  textoicpaprovacao:
    'Valor mínimo de ICP para que o candidato possa avançar à próxima fase do processo seletivo',
  requisitosadicionais: 'Requisitos adicionais',
  informerequisitosadicionais: 'Informe os requisitos adicionais, se houver',
  ecolaboradordaempresa: 'É colaborador da empresa?',
  outrasinformacoes: 'Outras informações',
  novoform: 'Novo formulário',
  cadastroformulario: 'Cadastro de formulário',
  nomeformulario: 'Nome do formulário',
  informenomeformulario: 'Informe o nome do formulário',
  vagaaqualserefere: 'Vaga a qual se refere',
  cadastreumavaga: 'Cadastre uma vaga',
  identificacaocandidato: 'Identificação do candidato',
  formularionaopodeseralterado:
    'Este formulário não pode ser alterado, pois existem respostas vinculadas a ele',
  checkhabilidades: 'Check de habilidades',
  formulariosgerais: 'Formulários gerais',
  formulariocheckhabilidades: 'Cadastro de formulário de check de habilidades',
  parecerentrevistador: 'Parecer do entrevistador',
  apto: 'Apto',
  inapto: 'Inapto',
  ocandidatoesta: 'O candidato está',
  opcaomin: 'opção',
  selecao: 'Seleção',
  linkinscricao: 'Link para inscrição',
  formulariocheckhabilidadesparaocargo:
    'Formulário de check de habilidades para o cargo de ',
  novocandidato: 'Novo candidato',
  candidato: 'Candidato',
  naofoiencontradocolaborador:
    'Não foi encontrado colaborador com o documento informado na inscrição do candidato',
  candidatocomrestricao:
    'Candidato não atende caracterísitica(s) obrigatória(s) de perfil e/ou experiência',
  novaadmissao: 'Nova admissão',
  admissaomin: 'admissão',
  cancelada: 'cancelada com sucesso!!!',
  cancelado: 'cancelado com sucesso!!!',
  afastamento: 'Afastamento',
  afastamentocancelado: 'Afastamento cancelado com sucesso!!!',
  ausencia: 'Ausência',
  funilcontratacao: 'Funil de Contratação',
  informedocumento: 'Informe o documento',
  informetipo: 'Informe o tipo',
  novaagencia: 'Nova agência',
  agenciamin: 'agência',
  agencia: 'Agência',
  certezaexcluiragencia: 'Tem certeza que deseja excluir essa agência?',
  agenciaexcluida: 'Agência excluída com sucesso!!!',
  informetipodocumento: 'Informe o tipo do documento',
  etapa: 'Etapa',
  etapamin: 'etapa',
  informeetapa: 'Informe o nome da etapa',
  parecerfinal: 'Parecer final',
  justificativamin: 'justificativa',
  situacao: 'Situação',
  situacaomin: 'situação',
  inscricoesrecebidas: 'Inscrições recebidas',
  inscricoesconcluidas: 'Inscrições concluídas',
  inscricoesvalidadas: 'Inscrições validadas',
  prazoemdias: 'Prazo (dias)',
  etapasselecao: 'Etapas da seleção',
  agendamentos: 'Agendamentos',
  agendamento: 'Agendamento',
  registros: 'Registros',
  formulariosassociados: 'Formulários associados',
  avaliacoesassociadas: 'Avaliações associadas',
  cadastreetapasfunil:
    'Cadastre as etapas do funil em Configuração / Funil de Contratação',
  formulariosmin: 'formulários',
  informetipoclinica: 'Selecione o tipo da clínica / médico',
  clinicasalva: 'Clínica cadastrada com sucesso !!!',
  clinicaatualizada: 'Clínica atualizada com sucesso!!!',
  clinica: 'Clínica',
  novaclinica: 'Nova Clínica',
  certezaexcluirclinica: 'Tem certeza que deseja excluir essa clínica?',
  clinicaexcluida: 'Clínica excluída com sucesso!!!',
  juridica: 'Pessoa jurídica',
  fisica: 'Pessoa física',
  nomeclinica: 'Nome da clínica / médico',
  informenomeclinica: 'Informe o nome da clínica / médico',
  informenomeagencia: 'Informe o nome da agência',
  informedocumentoidentificacao: 'Informe o documento de identificação',
  pessoajuridica: 'Pessoa Jurídica',
  pessoafisica: 'Pessoa Física',
  selecionetipoagencia: 'Selecione o tipo de agência',
  pesodaetapa: 'Peso da etapa no processo seletivo',
  pesoetapa: 'Peso da etapa',
  anterior: 'Anterior',
  proximo: 'Próximo',
  formularioassociado: 'Formulário associado',
  cadastreformulariorecrutamento:
    'Cadastre um formulário em Recrutamento e Seleção / Formulários',
  avaliacaoassociada: 'Avaliação associada',
  cadastreavaliacaorecrutamento:
    'Cadastre uma avaliação em Recrutamento e Seleção / Avaliações',
  etapasdeselecao: 'Etapas de Seleção',
  agendaretapa: 'Agendar etapa',
  candidatomin: 'candidato',
  gerenciaretapas: 'Gerenciar Etapas',
  aderenciavaga: 'Aderência à vaga',
  verdetalhesetapas: 'Ver detalhes das etapas',
  incluirnocalculodeaderencia: 'Incluir no cálculo de aderência à vaga',
  atualizaaderecencia: 'Atualiza aderência',
  atribuiretapa: 'Atribuir etapa',
  cumprimentoprocesso: '% Cumprimento do processo',
  canceladavaga: 'Cancelada',
  congelada: 'Congelada',
  finalizada: 'Finalizada',
  comentario: 'Comentário',
  indefinido: 'Indefinido',
  parecerfinalprocesso: 'Parecer final do processo',
  comentariosfinais: 'Comentários finais',
  bancocandidatos: 'Banco de candidatos',
  checklistadmissao: 'Checklist de admissão',
  candidatosememail:
    'Candidato não informou email. Não é possível enviar o checklist de admissão.',
  candidatosememailcadastrado:
    'Candidato não informou email. Não é possível enviar emails para ele.',
  veroupreenchercheckhabilidades: 'Ver ou preencher check de habilidades',
  parecermin: 'parecer',
  tipoagendamento: 'Tipo de agendamento',
  envioform: 'Envio de formulário / avaliação',
  entrevista: 'Entrevista',
  examemedico: 'Exame médico',
  avaliacaomedica: 'Avaliação médica',
  avaliacaopsicologica: 'Avaliação psicológica',
  examepsicologico: 'Exame psicológico',
  exametoxicologico: 'Exame toxicológico',
  horarioinicio: 'Horário de início',
  horarioinicioopcional: 'Horário de início (opcional)',
  horariotermino: 'Horário de término',
  horarioterminoopcional: 'Horário de término (opcional)',
  tipolocal: 'Tipo de local',
  clinicacadastradasistema: 'Clínica cadastrada no sistema',
  localcadastradosistema: 'Local cadastrado no sistema',
  localnaocadastradosistema: 'Local não cadastrado no sistema',
  enviarmensagempara: 'Enviar mensagem para',
  lideranca: 'Liderança',
  clinicamin: 'clínica',
  mensagemlideranca: 'Mensagem para liderança',
  mensagemcandidato: 'Mensagem para candidato',
  informemensagem: 'Informe a mensagem',
  informeemaillideranca: 'Informe o email da liderança',
  nomesliderancas: 'Nome(s) da(s) liderança(s)',
  verdetalhesagendamento: 'Ver detalhes do agendamento',
  congelarvaga: 'Congelar vaga',
  cancelarvaga: 'Cancelar vaga',
  finalizarvaga: 'Finalizar vaga',
  descongelarvaga: 'Descongelar vaga',
  editaroualterarstatusvaga: 'Editar informações ou alterar status da vaga',
  certezacancelarvaga: 'Tem certeza que deseja cancelar essa vaga?',
  certezafinalizarvaga: 'Tem certeza que deseja finalizar essa vaga?',
  vaganaoativa: 'A vaga selecionada não está ativa no momento',
  mensagemaprovador: 'Mensagem para o aprovador',
  informeremensagemaprovador:
    'Digite o texto da mensagem a ser enviada ao aprovador',
  enviado: 'enviado com sucesso!!!',
  numdiasprevistos: 'Número de dias previstos para realização',
  responsaveletapa: 'Responsável pela etapa',
  diasrealizacao: 'Dias para realização',
  informedescricaoetapa: 'Informe uma descrição para a etapa',
  informenumdias: 'Informe o número de dias',
  informetelefonecelular: 'Informe o telefone celular',
  informeemail: 'Informe o email',
  selecionefuncionalidades:
    'Marque as funcionalidades que o cargo poderá acessar no aplicativo Macro',
  permissoesmacro: 'Permissões no Macro',
  ecolaborador: 'É colaborador?',
  admin_central_demarco: 'Administrador Central Demarco',
  administradorcentraldemarco: 'Administrador Central Demarco',
  erroaosalvarregistro: 'Erro ao salvar registro',
  erroaoexcluirregistro: 'Erro ao excluir registro',
  exportarparaexcel: 'Exportar dados para Excel',
  cadastradopor: 'Cadastrado Por',
  datacadastro: 'Data Cadastro',
  atualizadopor: 'Atualizado Por',
  dataatualizacao: 'Data Atualização',
  transferencia: 'Transferência',
  emoji: 'Emoji',
  significadoemojis: 'Significado dos emojis',
  informesignificadoemoji: 'Informe o significado do emoji',
  odio: 'Ódio',
  desapontamento: 'Desapontamento',
  neutro: 'Neutro',
  bom: 'Bom',
  excelente: 'Excelente',
  significadoemoji: 'Significado do emoji',
  mesesperiodoconcessivo: 'Número de meses do período concessivo',
  informeperiodoconcessivo: 'Informe o número de meses do período concessivo',
  periodo: 'Período',
  erroafastamento:
    'Não foi possível inserir o registro. Colaborador afastado na data/período informado',
  erroausencia:
    'Não foi possível inserir o registro. Colaborador ausente na data/período informado',
  erroferias:
    'Não foi possível inserir o registro. Colaborador em férias na data/período informado',
  copia: 'Cópia',
  datainicioperiodo2invalida:
    'A data de início do período 2 deve ser posterior ao término do período 1',
  datainicioperiodo3invalida:
    'A data de início do período 3 deve ser posterior ao término do período 2',
  dataterminoperiodoinvalida:
    'A data de término do período deve ser posterior à data de ínicio',
  periodoconcessivoinvalido:
    'O número de meses do período concessivo deve ser maior que 0',
  sebrasil: 'se Brasil',
  existepessoavinculadaaodocumento:
    'Já existe uma pessoa vinculada à este documento',
  limiterespostas: 'Número máximo de opções selecionadas na resposta',
  selecionenomaximo: 'Selecione no máximo',
  opcoesmin: 'opções',
  nessaquestao: 'nessa questão',
  informelimiterespostas:
    'Informe o número máximo de opções que podem ser selecionadas',
  limiterespostainvalido:
    'O número máximo de opções selecionadas deve ser maior ou igual a 1 e menor ou igual à quantidade de opções de resposta',
  planilha: 'Planilha',
  escolhaplanilha: 'Escolha a planilha ou arraste-a...',
  treinamentodemarcoacademy: 'É um treinamento da Demarco Academy',
  cargouousalarioinvalido: 'O cargo ou o salário deve ser diferente do atual',
  novosalarionaopodesermenor:
    'O novo salário não pode ser inferior ao salário atual',
  exportardadosresumidos: 'Exportar dados resumidos',
  exportardadoscompletos: 'Exportar dados completos',
  considerarcalculoabsenteismo: 'Considerar no cálculo de absenteísmo',
  considerarcalculoturnover: 'Considerar no cálculo de turnover',
  importaradmissoes: 'Importar admissões',
  importar: 'Importar',
  dadosimportados: 'Dados importados com sucesso!!!',
  registrosnaoimportados: 'Registros não importados',
  IsWorkdayNotExist: 'jornada de trabalho não encontrada',
  isNotEmpty: 'campo(s) obrigatório(s) não preenchido(s)',
  isBoolean: 'deve ser SIM ou NÃO',
  isTrue: 'deve ser SIM',
  isString: 'deve ser um valor textual',
  isPhoneNumber: 'formato do número de telefone incorreto',
  isTimeString: 'formato de hora incorreto',
  isDateString: 'formato de data incorreto',
  isUrl: 'deve ser um endereço de URL',
  isNumber: 'deve ser um valor númerico',
  isPositive: 'deve ser um número positivo',
  max: 'não pode ser maior que o limite especificado',
  min: 'não pode ser menor que o limite especificado',
  isValidOption: 'deve ser uma opção válida',
  isArray: 'deve ser uma lista separada por ,',
  arrayContains: 'deve conter apenas opções validas',
  arrayUnique: 'todos os elementos da lista devem ser únicos',
  arrayNotEmpty: 'a lista não pode estar vazia',
  IsPositionNotExist: 'cargo não encontrado',
  IsReasonNotExist: 'motivo de admissão não encontrado',
  IsUnityNotExist: 'unidade não encontrada',
  IsBondNotExist: 'vínculo não encontrado',
  IsCompanyNotExist: 'empresa não encontrada',
  IsRoleNotExist: 'função não encontrada',
  isPersonAlreadExists: 'registro duplicado ou pessoa admitida anteriormente',
  largeDocument: 'quantidade informada acima de 20 caracteres',
  isEmail: 'email inválido',
  vagasemcargo:
    'Vaga não pode ser acessada, pois seu cargo não foi encontrado no sistema!!!',
  tiposausencias: 'Tipos de Ausências',
  motivosausencias: 'Motivos de Ausências',
  informetipoausencia: 'Informe o tipo de ausência',
  informedescricaotipoausencia: 'Informe a descrição do tipo de ausência',
  certezaexcluirtipoausencia:
    'Tem certeza que deseja excluir esse tipo \n de ausência?',
  motivoausencia: 'Motivo da ausência',
  informemotivoausencia: 'Informe o motivo da ausência',
  cadastreumtipoausencia: 'Cadastre um tipo de ausência',
  motivo: 'Motivo',
  consideracalculoabsenteismo: 'Considera cálculo absenteísmo?',
  exigedocumentacao: 'Exige documento comprobatório?',
  tiposmin: 'tipos',
  certezaexcluirmotivoausencia:
    'Tem certeza que deseja excluir esse motivo \n de ausência?',
  cadastreummotivoausencia: 'Cadastre um motivo de ausência',
  formasdemissao: 'Formas de Demissão',
  tiposdemissao: 'Tipos de Demissão',
  motivosdemissao: 'Motivos de demissão',
  forma: 'Forma',
  informeformademissao: 'Informe a forma de demissão',
  iniciativa: 'Iniciativa',
  informedescricao: 'Informe a descrição',
  certezaexcluirforma: `Tem certeza que deseja excluir essa forma \n de demissão?`,
  informetipodemissao: 'Informe o tipo de demissão',
  formasdemisssaoassociadas: 'Formas de demissão associadas',
  formasmin: 'formas',
  certezaexcluirtipodemissao: `Tem certeza que deseja excluir esse tipo \n de demissão?`,
  formacadastrada: 'Forma de demissão cadastrada com sucesso!!!',
  formaatualizada: 'Forma de demissão atualizada com sucesso!!!',
  formaexcluida: 'Forma de demissão excluída com sucesso!!!',
  motivoadmissao: 'Motivo da admissão',
  motivodemissao: 'Motivo da demissão',
  informemotivodemissao: 'Informe o motivo da demissão',
  tiposdemisssaoassociados: 'Tipos de demissão associados',
  motivodemissaoatualizado: 'Motivo de demissão atualizado com sucesso!!!',
  motivodemissaocadastrado: 'Motivo de demissão cadastrado com sucesso!!!',
  certezaexcluirmotivodemissao: `Tem certeza que deseja excluir esse motivo \n de demissão?`,
  motivodemissaoexcluido: 'Motivo de demissão excluído com sucesso!!!',
  formademissao: 'Forma de demissão',
  placeholderbuscaadmissao:
    'Digite um nome, documento, data de admissão, cargo ou estrutura',
  placeholderbuscaafastamento:
    'Digite um nome, documento, cargo ou tipo de afastamento',
  placeholderbuscaausencia:
    'Digite um nome, documento, cargo, tipo ou motivo de ausência',
  placeholderbuscademissao:
    'Digite um nome, documento, cargo, forma, tipo ou motivo de demissão',
  placeholderbuscaferias: 'Digite um nome, documento ou cargo',
  placeholderbuscapromocao:
    'Digite um nome, documento, cargo, salário ou tipo de promoção',
  placeholderbuscaocorrencia:
    'Digite um nome, documento, cargo ou tipo de ocorrência',
  placeholderbuscatransferencia:
    'Digite um nome, documento, cargo ou motivo da transferência',
  placeholderbuscacargo: 'Digite nome ou descrição do cargo',
  placeholderbuscaformulario: 'Digite nome ou descrição do formulário',
  placeholderbuscanomeformulario: 'Digite o nome do formulário',
  placeholderbuscaavaliacao: 'Digite nome ou descrição da avaliação',
  placeholderbuscavaga: 'Digite o nome da vaga, nome ou email do aprovador',
  placeholderbuscacandidato: 'Digite o nome ou documento do candidato',
  placeholderbuscaagencia: 'Digite o nome da agência, cidade, estado ou país',
  placeholderbuscaclinica: 'Digite o nome da clínica, cidade, estado ou país',
  placeholderbuscatreinamento:
    'Digite nome, descrição, palavra chave ou grade do treinamento',
  placeholderbuscapesquisa: 'Digite nome ou descrição da pesquisa',
  placeholderbuscaciclo: 'Digite o nome do ciclo',
  media: 'Média',
  copiarregistrosde: 'Copiar registros de',
  formasdemissoes: 'Formas de demissões',
  tiposdemissoes: 'Tipos de demissões',
  motivosdemissoes: 'Motivos de demissões',
  tiposocorrencias: 'Tipos de ocorrências',
  tipospromocoes: 'Tipos de promoções',
  tipostransferencias: 'Tipos de transferências',
  copiarde: 'Copiar do cliente',
  copiarpara: 'Copiar para o cliente',
  registromin: 'registro',
  importante: 'Importante',
  textocopiaregistros:
    'Caso não importe todos os registros de uma vez, faça da seguinte forma:',
  tiposausenciaantesmotivo:
    'Importe os tipos de ausências antes de importar os motivos de ausências',
  formademissaoantesdotipo:
    'Importe as formas de demissão antes de importar os tipos de demissão',
  tiposdemissaoantesmotivo:
    'Importe os tipos de demissão antes de importar os motivos de demissão',
  copiaconfiguracao: 'Cópias de configurações',
  erroaocopiarregistro: 'Erro ao copiar registros',
  selecioneumoumaistiposderegistros: 'Selecione um ou mais tipos de registros',
  registroscopiados: 'Registros copiados com sucesso!!!',
  copiados: 'copiados com sucesso!!!',
  tiposafastamentos: 'Tipos de afastamentos',
  tiposjornada: 'Tipos de jornadas',
  tiposvinculo: 'Tipos de vínculos',
  tiposcontratacao: 'Tipos de contratação',
  visibilidadeseraperdida: `A visibilidade dos dados vinculados a ela \n será perdida!`,
  certezaexcluirunidade: 'Tem certeza que deseja excluir esta estrutura?',
  condicionarexibicao: 'Vincular a uma resposta anterior',
  exibirse: 'Exibir se a resposta da pergunta',
  foriguala: 'For igual a',
  candidatonaoencontrado:
    'Não foi encontrado candidato com o documento informado!',
  tempomaximoemminutos: 'tempo máximo para enviar as respostas (em min.)',
  administradorcliente: 'Administrador Cliente',
  admin_cliente: 'Administrador Cliente',
  tipocargo: 'Tipo de cargo',
  tiposcargomin: 'tipos de cargos',
  tiposcargos: 'Tipos de cargos',
  tiposcargosmin: 'tipos de cargos',
  informetipocargo: 'Informe o tipo de cargo',
  informedescricaotipocargo: 'Informe a descrição do tipo de cargo',
  certezaexcluirtipocargo: `Tem certeza que deseja excluir esse tipo de \n cargo?`,
  nomecompleto: 'Nome completo',
  informelink: 'Informe o link',
  link: 'Link',
  duracao: 'duração',
  informeCPFvalido: 'Informe um CPF válido',
  templatecertificado: 'Template do certificado',
  somenteimagempng: 'somente imagem com extensão png',
  imageminvalida: 'A Extensão da imagem deve ser .png',
  naopodeexcluiretapavinculadaavaga:
    'Não é possível excluir esta etapa pois ela está vinculada a uma ou mais vagas',
  configuracoescliente: 'Configurações de clientes',
  modulosacessar: 'Módulos que irá acessar',
  modulosmin: 'módulos',
  testecomporatamental: 'Teste de perfil comportamental',
  integracaogeg: 'Integração com Gente e Gestão',
  pesquisasecuritaria: 'Pesquisa securitária',
  realiza: 'Realiza',
  empresapesquisasecuritaria: 'Empresa integração pesquisa securitária',
  empresatestecomporatamental: 'Empresa integração teste comportamental',
  modulos: 'Módulos',
  configuracaocliente: 'Configuração de cliente',
  somapontuacaoinvalida:
    'A soma da pontuação das questões não pode ser diferente da pontuação máxima da avaliação. Soma das questões',
  cpfsebrasil: 'número do CPF se for do Brasil',
  erroetapaselecao:
    'Não foi possível excluir o registro, pois ele está vinculado a uma ou mais etapas de seleção',
  arquivosabios: 'Resultado do teste de perfil comportamental da Sabios',
  concordareceberwhatsapp:
    'Você concorda em receber mensagens do processo de seleção via Whatsapp?',
  enviarwhatsapp: 'Enviar Whatsapp?',
  documentojaregistradoparavaga:
    'Já existe inscrição para esta vaga com o documento informado',
  formularioavaliacaoprocessoseletivo:
    'Formulário de avaliação do processo seletivo',
  descricaoformularioavaliacaoprocessoseletivo:
    'Em uma escala de 1 a 10, sendo 1 o menor e pior valor, o quanto indicaria nosso processo seletivo para um amigo ou familiar?',
  descricaonps: 'Comente sua resposta',
  enviarnpsefeedback: 'Realizar envio de NPS e feedback do processo',
  feedbackcandidatoaprovado: 'Mensagem para candidatos aprovados',
  feedbackcandidatoreprovado: 'Mensagem para candidatos reprovados',
  feedbackcandidatoindefinido:
    'Mensagem para demais candidatos (banco de candidatos ou status indefinido)',
  resultadonetpromoterscoretitulo: 'Resultado do Net Promoter Score',
  npstotalrespostas: 'Total de respostas',
  promotores: 'Promotores',
  neutros: 'Neutros',
  detratores: 'Detratores',
  filtrar: 'Filtrar',
  admitircandidato: 'Admitir candidato',
  possuitestecomportamental: 'Esta vaga possui teste de perfil comportamental?',
  certezaaprovarsemupload:
    'Deseja realmente aprovar o candidato sem upload \n do resultado do teste de perfil comportamental?',
  etapasmin: 'etapas',
  etapas: 'Etapas',
  tiposagendamento: 'Tipos de agendamento',
  escolhaimagem: 'Escolha uma imagem ou arraste-a...',
  nomelink: 'Nome do link ou texto a ser exibido',
  informenomelink: 'Informe o nome do link ou texto a ser exibido',
  uploadcurriculo: 'Upload de currículo no cadastro de candidatos',
  uploadcurriculoopcao: 'O upload do currículo será',
  opcionalmaiuscula: 'Opcional',
  curriculo: 'Currículo',
  escolhacurriculo: 'Escolha o arquivo com o currículo ou arraste-o aqui...',
  curriculomin: 'currículo',
  datainscricao: 'Data de inscrição',
  naopertenceagrade: 'Não é vinculado a nenhuma grade',
  justificativasdemissao: 'Justificativas de demissão',
  justificativademissao: 'Justificativa de demissão',
  motivosdemisssaoassociados: 'Motivos de demissão associados',
  certezaexcluirjustificativademissao: `Tem certeza que deseja excluir essa \n justificativa de demissão?`,
  justificativademissaoatualizada:
    'Justificativa de demissão atualizada com sucesso!!!',
  justificativademissaocadastrada:
    'Justificativa de demissão cadastrada com sucesso!!!',
  justificativademissaoexcluida:
    'Justificativa de demissão excluída com sucesso!!!',
  informejustificativademissao: 'Informe a justificativa da demissão',
  motivosdemissaoantesmotivo:
    'Importe os motivos de demissão antes de importar as justificativas de demissão',
  informepis: 'Informe o PIS (se Brasil)',
  cadastrados: ' cadastrados com sucesso!!!',
  textoarquivos:
    'Para salvar adicione o arquivo AFDT ou o arquivo ACJEF ou ambos',
  extensaotxt: 'A extensão do arquivo deve ser txt',
  cadastradomin: 'cadastrado',
  dataupload: 'Data de upload',
  estes: 'estes',
  excluidos: 'excluídos com sucesso!!!',
  atualizados: 'atualizados com sucesso!!!',
  vernps: 'Ver o NPS da',
  cargosantesetapas: 'Importe os cargos antes de importar as etapas de seleção',
  anoreferenciamin: 'ano de referência',
  mesreferencia: 'mês de referência',
  enviarviawhatsapp: 'Enviar mensagens via Whatsapp',
  datafinalinvalida: 'A data final deve ser posterior à data inicial',
  datainicial: 'Data inicial',
  datafinal: 'Data final',
  placeholderbuscahierarquia:
    'Digite um nome de líder ou nome de liderado de hierarquia',
  nomelider: 'Nome do líder',
  estruturalider: 'Estrutura do líder',
  nomeliderado: 'Nome do liderado',
  estruturaliderado: 'Estrutura do liderado',
  cargoliderado: 'Cargo do liderado',
  lembreatualizarhiearquia: `Atualize a hierarquia de liderança, \nse for o caso!!!`,
  novahierarquia: 'Nova hierarquia',
  liderados: 'Liderados',
  lideradomin: 'liderado',
  lidermin: 'líder',
  cadastreumalideranca: 'Cadastre uma liderança para selecionar',
  liderado: 'Liderado',
  adicionar: 'Adicionar',
  datapadrao: 'Data padrão',
  datapadraomin: 'data padrão',
  limpar: 'Limpar',
  datapadraoselecionados: 'data padrão para selecionados',
  lideradosmin: 'liderados',
  errodataadmissao:
    'A data precisa ser maior que a data de início de prestação de serviços do liderado e do líder',
  erroeventocolaborador: 'O colaborador possui algum evento na data informada',
  cadastreumliderado: 'Cadastre um liderado para selecionar',
  estahierarquia: 'esta hierarquia',
  aproveitarcandidatonavaga: 'Aproveitar candidato na vaga',
  inscricaocopiada: 'Inscrição copiada com sucesso!!!',
  aproveitarcandidato: 'Aproveitar candidato em outra vaga',
  etapascargo: 'Etapas por cargo',
  etapasantesselecaoporcargos:
    'Importe cargos, formulários, avaliações e etapas de seleção geral antes de importar as etapas de seleção por cargo',
  etapasporcargo: 'Etapas de seleção por cargo',
  datafinalmaioratual: 'A data final precisa ser menor que a data atual',
  lideradojatemlideranca:
    'O liderado já possui uma hierarquia de liderança ativa',
  etapaanterior: 'Etapa anterior',
  etapaprimeira: 'Essa é a primeira etapa',
  tenteacessarnovamente:
    'Não foi possível acessar o sistema. Por favor tente novamente mais tarde',
  datanascimentomaioratual:
    'A data de nascimento precisa ser menor que a data atual',
  dataanoinvalido: 'O ano da data deve ser superior a 1900',
  etapatempesquisasecuritaria: 'Nessa etapa é realizada Pesquisa Securitária',
  verresultadopesquisasecuritaria: 'Ver resultado da pesquisa securitária',
  enviarpesquisasecuritaria: 'Enviar pesquisa securitária',
  referenciaspessoais: 'Referências Pessoais',
  referenciascomerciais: 'Referências Comerciais',
  statusperfilsecutirario: 'Status Perfil Securitário',
  datavalidade: 'Data de validade',
  dataenvio: 'Data envio',
  protocolopesquisa: 'Protocolo pesquisa',
  status: 'Status',
  codigoperfil: 'Código perfil',
  observacoes: 'Observações',
  excluirimagem: 'Excluir imagem',
  imagemsemnome: 'Imagem sem nome',
  videosemnome: 'Vídeo sem nome',
  comperfilsecuritario: 'Com perfil securitário',
  carregardoformulario: 'Carregar respostas do formulário',
  naoforamencontradasrespostasvalidas:
    'Não foram encontradas respostas válidas',
  outro: 'Outro',
  solicitarhorarioausencia: 'Solicitar horário de início e término',
  funcao: 'Função',
  informefuncao: 'Informe a função',
  informedescricaofuncao: 'Informe a descrição da função',
  certezaexcluirfuncao: 'Tem certeza que deseja excluir essa função?',
  funcoes: 'Funções',
  funcoesmin: 'funções',
  funcaomin: 'função',
  referenciasprofissionais: 'Referências Profissionais',
  tiposdocumentos: 'Tipos de Documentos',
  nomedocumento: 'Nome do documento',
  informenomedocumento: 'Informe o nome do documento',
  informedescricaodocumento: 'Informe a descrição do documento',
  tipodocumento: 'Tipo de documento',
  certezaexcluirtipodocumento: `Tem certeza que deseja excluir esse tipo de \ndocumento?`,
  cadastreumtipodocumento: 'Cadastre um tipo de documento',
  documentomin: 'documento',
  certezaexcluirdocumento: 'Tem certeza que deseja excluir esse documento?',
  placeholderbuscadocumento:
    'Digite o nome do colaborador, estrutura, o tipo de documento ou a data de envio',
  placeholderbuscacolaborador: 'Digite o nome do colaborador',
  ciclo: 'Ciclo',
  ciclomin: 'ciclo',
  elegiveis: 'Elegíveis',
  comite: 'Comitê',
  resultados: 'Resultados',
  acompanhamento: 'Acompanhamento',
  enviowhatsappcarreira:
    'Envio de whatsapp no módulo de Desenvolvimento e Carreira',
  de: 'De',
  ate: 'Até',
  informeclassificacao: 'Informe a classificação',
  informevalorinicial: 'Informe o valor inicial',
  informevalorfinal: 'Informe o valor final',
  parametro: 'Parâmetro',
  certezaexcluirparametro: 'Tem certeza que deseja excluir esse parâmetro?',
  valorincialmaiorigualzero: 'O valor inicial deve ser maior ou igual a 0',
  valorfinalmenorigualcem: 'O valor final deve ser menor ou igual a 100',
  valorfinalmenorvalorinicial:
    'O valor final deve ser maior que o valor inicial',
  faixasdeclassificacaoinvalidas:
    'Atenção!!! As faixas de classificação devem iniciar em 0 e terminar em 100',
  intervalosdefaixasinvalidos:
    'Atenção!!!! Os intervalos de faixas de classificação não estão em uma sequência correta',
  enviowhatsapprecrutamento:
    'Envio de whatsapp no módulo de Recrutamento e Seleção',
  cadastrar: 'Cadastrar',
  depositar: 'Depositar',
  consultar: 'Consultar',
  adicionardocumento: 'Adicionar documento',
  excluirdocumento: 'Excluir documento',
  depositado: 'depositado com sucesso!!!',
  depositados: 'depositados com sucesso!!!',
  workplace: 'Workplace',
  nomeciclo: 'Nome do ciclo',
  tipoavaliacao: 'Tipo de avaliação',
  escolhanome: 'Escolha um nome',
  haveracomite: 'Haverá um comitê',
  tempodeempresa: 'Tempo de casa',
  tempodeempresamin: 'tempo de casa',
  comites: 'Comitês',
  novociclo: 'Criar Novo Ciclo',
  gerarcomunicado: 'Gerar Comunicado',
  certezaexcluirciclo: `Tem certeza que deseja excluir esse ciclo?`,
  zeroatresmeses: '0 a 3 meses',
  tresaseismeses: '3 a 6 meses',
  seisadozemeses: '6 a 12 meses',
  acimadedozemeses: 'Acima de 12 meses',
  tipoavaliacaonoventa:
    '90°: Colaborador se avalia - Liderança avalia colaborador',
  tipoavaliacaocentoeoitenta:
    '180°: Colaborador se avalia - Liderança avalia colaborador - Colaborador avalia liderança',
  tipoavaliacaotrezentosesessenta:
    '360°: Colaborador se avalia - Liderança avalia colaborador - Colaborador avalia liderança - Convidados podem avaliar',
  datainicioinvalida: 'A data inicial deve ser superior a data atual',
  iniciodiautil: 'Todas as etapas devem ter início em um dia útil',
  datainicialmaiorquefinal:
    'A data inicial não pode ser maior que a data final',
  datafinalmenorqueinicial:
    'A data final não pode ser menor que a data inicial',
  erroaocadastrarciclo: 'Ocorreu um erro ao tentar salvar ciclo',
  follow: 'Follow',
  temposempresamin: 'tempos de casa',
  documentoselecionado: 'Documento selecionado',
  candidatosememailesemoptin:
    'Candidato não informou email e não concordou em receber Whatsapp. Não é possível enviar mensagens para ele.',
  salveowhatsapp: `Salve o Whatsapp do DMPeople em seus contatos, para facilitar a identificação das mensagens`,
  naopodeagendarentrevistaeform:
    'Não é possível agendar Entrevista e Envio de formulário/avaliação simultaneamente quando for enviar Whatsapp',
  voltarparalista: 'Voltar para lista',
  certezaexcluirelegivel: `Tem certeza que deseja excluir esse elegível?`,
  novoelegivel: 'Novo elegível',
  sucessoenvioemail: 'E-mails enviados com sucesso',
  errosaoenviaremail: 'Erro ao enviar e-mails',
  codigo: 'Código',
  informecodigocliente: 'Informe o código do cliente',
  informenomecliente: 'Informe o nome do cliente',
  codigoclienteexiste: 'Já existe um cliente com esse código',
  nomeclienteexiste: 'Já existe um cliente com esse nome',
  configuracoesacesso: 'Configurações de acesso',
  oportunidademelhoria: 'Oportunidade de melhoria identificada',
  motivoacao: 'Motivo da ação',
  desenvolverpontencializarcompetencia:
    'O que vou fazer para desenvolver ou potencializar a competência identificada',
  quempodeajudar: 'Quem pode me ajudar?',
  selecionecolaborador: 'Selecione um colaborador',
  adcionarnovaacao: 'Adicionar nova ação',
  datafollow: 'Data follow',
  adicionarfollow: 'Adicionar follow',
  removerfollow: 'Deletar follow',
  acao: 'Ação',
  removeracao: 'Remover ação',
  erroaocadastrarplanodeacao: 'Erro ao cadastrar plano de ação',
  novoplanoacao: 'Novo plano de ação',
  certezaexcluirplanoacao: 'Tem certeza que deseja excluir esse plano de ação?',
  planoacaoexcluido: 'Plano de ação excluído com sucesso!!!',
  planoacaocadastrado: 'Plano de ação incluído com sucesso!!!',
  erroaoexcluirplanoacao: 'Erro ao excluir plano de ação',
  placeholderplanejamentocomite: 'Busque por nome, e-mail ou data',
  colaboradores: 'Colaboradores',
  convidados: 'Convidados',
  convidadosExternos: 'Convidados externos',
  dataehorariorealizacao: 'Data/Hora de realização',
  listadeconfirmacao: 'Lista de confirmação',
  conferencia: 'Conferência',
  ou: 'ou',
  obrigadocontribuicao: 'Obrigado pela contribuição',
  comparecerevento: 'Irá comparecer ao evento?',
  evento: 'Evento',
  talvez: 'Talvez',
  tituloModalConfirmacao: 'Lista de confirmação',
  emespera: 'Em espera',
  recusado: 'Recusado',
  aceito: 'Aceito',
  linkcomite: 'Link comitê',
  certezaexcluircomite: 'Tem certeza que deseja excluir este comitê?',
  selecionecolaboradoresavaliados:
    'Selecione os colaboradores a serem avaliados',
  selecionepessoascomite: 'Selecione as pessoas que participarão do comitê',
  selecionecolaboradorescomite:
    'Selecione as pessoas que participarão do comitê',
  desajaincluirforaorganizacao:
    'Deseja incluir pessoas de fora da organização?',
  informedatahorariocomite: 'Informe a data e horário do comite',
  comitefisicoouconferencia:
    'O comitê será realizado em local fisico ou através de conferência?',
  informeurlcomite: 'Informe o url da sala que será realizada o comitê',
  infomerlocalcomite: 'Informe o local onde será realizado o comitê',
  fisico: 'Fisico',
  ambos: 'Ambos',
  colaboradoresmin: 'colaboradores',
  datarealizacao: 'Data de realização',
  erroaocadastrarcomite: 'Ocorreu um erro ao tentar salvar comitê',
  horariofinalmaiorqueinicial:
    'O horário final deve ser maior que o horário inicial',
  comitemin: 'comitê',
  urlinvalida: 'URL inválida',
  colaboradorsemlideranca:
    'Existem colaboradores sem liderança cadastrada.\nEles não serão incluídos no ciclo. \nDeseja continuar?',
  semregistros: 'Não há registros para mostrar',
  elegivelexcluido: 'Elegível excluído com sucesso!!!',
  elegivelcadastrado: 'Elegível incluído com sucesso!!!',
  carregandoelegiveis: 'Seleção dos elegíveis está em andamento',
  errocarregarelegiveis: 'Erro ao selecionar elegíveis',
  elegiveiscarregados: 'Seleção dos elegíveis finalizada com sucesso!!!',
  classificacoesepesos: 'Classificações e Pesos',
  veracao: 'Visualizar Ação',
  editarplanoacao: 'Editar Plano',
  verplano: 'Visualizar plano',
  editaracao: 'Editar ação',
  follows: 'Follows',
  realizarfollow: 'Realizar follow',
  semfollowsvalidos: 'Nenhum follow válido até o momento',
  selecionefollow: 'Selecione um follow',
  selecionefollowstatus: 'Selecione os status',
  actionstatusnotstarted: 'Não iniciado',
  actionstatuspending: 'Em andamento',
  actionstatusdone: 'Concluído',
  convite: 'Convite',
  avaliados: 'Avaliados',
  numerodiasprevistos: 'Número de dias previstos para realização',
  informenumerodias: 'Informe o número de dias ',
  diaspararealizacao: 'Dias para realização',
  certezaexcluiretapaciclo: 'Tem certeza que deseja excluir essa etapa?',
  semautorizacao: 'Você não tem autorização para realizar esta operação',
  semAutorizacaoAtualizarFormulario:
    'Você não tem autorização para alterar esse formulário',
  semAutorizacaoAlterarCamposFormulario:
    'Você não tem autorização para alterar campos criados por usuários administradores',
  configuracoesvisibilidade: 'Configurações de visibilidade',
  grupoempresas: 'Grupo Empresas',
  ecossistema: 'Ecossistema',
  ecossistemamin: 'ecossistema',
  ecossistemas: 'Ecossistemas',
  ecossistemasmin: 'ecossistemas',
  nomeecossistema: 'Nome Ecossistema',
  informenomeecossistema: 'Informe o nome do ecossistema',
  certezaexcluirecossistema: 'Tem certeza que deseja excluir esse ecossistema?',
  nomeecossistemaexiste: 'Já existe um ecossistema com esse nome',
  existemunidadesnesseecossistema:
    'Não é possível excluir. Existem unidades vinculadas a esse ecossistema',
  novousuario: 'Novo usuário',
  placeholderbuscausuario: 'Digite o email do usuário',
  pesquisar: 'Pesquisar',
  tiposusuarios: 'Tipos de usuários',
  informetipousuario: 'Informe o tipo de usuário',
  informedescricaotipousuario: 'Informe a descrição do tipo de usuário',
  tipousuario: 'Tipo de usuário',
  certezaexcluirtipousuario:
    'Tem certeza que deseja excluir esse tipo de usuário?',
  save_jobstage_blocked_with_candidates:
    'Não pode alterar pois esta etapa já contém candidatos',
  actionplan_duplicated:
    'Não é possível criar mais de um plano de ação para o mesmo colaborador por ciclo',
  jobstage_blocked_with_candidates:
    'Não é possível modificar etapas de vagas que possuam candidatos inscritos',
  acessototal: 'Acesso total',
  somenteleitura: 'Somente leitura',
  semacesso: 'Sem acesso',
  cadastrardocumentos: 'Cadastrar documentos',
  consultardocumentos: 'Consultar documentos',
  depositardocumentos: 'Depositar documentos',
  depositardocumento: 'Depositar documento',
  configuracoesgerais: 'Configurações gerais',
  controleferias: 'Controle de férias',
  documentosferias: 'Documentos de férias',
  programacaoferias: 'Programação de férias',
  tipocontrato: 'Tipo de contrato',
  tipousuarioexiste: 'Esse tipo de usuário já existe!!!',
  dataalteracaovinculo: 'Data de alteração de vinculo',
  novovinculo: 'Novo vínculo',
  vinculoanterior: 'Vinculo anterior',
  arquivosrelacionadosalteracao: 'Arquivos relacionados à alteração',
  tipovinculoanterior: 'Tipo de vínculo anterior',
  tipovinculoatual: 'Tipo de vínculo atual',
  certezaexcluirvinculacao: 'Tem certeza que deseja excluir esta vinculação?',
  vinculacaoexcluida: 'Vinculação excluída com sucesso',
  vinculacaosalva: 'Vinculação salva com sucesso',
  dataalteracao: 'Data de alteração',
  certezaexcluirtipocontrato:
    'Tem certeza que deseja excluir esse tipo de contrato?',
  tipocontratocadastrado: 'Tipo de contrato cadastrado com sucesso!!!',
  tipocontratoatualizado: 'Tipo de contrato atualizado com sucesso!!!',
  tipocontratoexcluido: 'Tipo de contrato excluído com sucesso!!!',
  placeholderbuscatipocontrato: 'Digite um nome, documento ou tipo de vinculo',
  emavaliacao: 'Em avaliação',
  datasuperioradmissiao: 'A data deve ser superior à data de admissão',
  datasuperiorprestacaoservico:
    'A data deve ser superior à data de início de prestação de serviços',
  previacampo: 'Esta é uma prévia do seu campo',
  arrastecampos: 'Arraste os campos para construir seu formulário',
  construindoformulario: 'Construindo formulário',
  visaopublica: 'Visão pública',
  mostrar: 'Mostrar',
  ocultar: 'Ocultar',
  seacondicaofor: 'Se a condição for',
  verdadeira: 'Verdadeira',
  falsa: 'Falsa',
  condicional: 'Condicional',
  seocenarioabaixoacontecer: 'Se o cenário abaixo acontecer',
  infomenomecondicional: 'Informe o nome da condicional',
  condicionaisemcampos: 'Condicionais em campos',
  exibaouocultecampos:
    'Exiba ou oculte campos do formulário de acordo com as respostas do usuário.',
  adcionarnovacondicional: 'Adicionar nova condicional',
  adicionarregra: 'Adicionar regra',
  adicionargrupo: 'Adicionar grupo',
  escolhacampo: 'Escolha o campo',
  camposmostradosdifentesdeocultados:
    'Campos a serem ocultados devem ser diferentes dos que serão mostrados',
  novoformulario: 'Criar Novo Formulário',
  verformulario: 'Ver Formulário',
  nomedoformulario: 'Nome do Formulário',
  certezaexcluirformulario: 'Tem certeza que deseja excluir este formulário?',
  textodeajuda: 'Texto de ajuda',
  textodedica: 'Texto de dica',
  solteabaixo: 'Solte abaixo os campos desejados',
  campocontemcondicional: 'Este campo já contém condicional',
  and: 'E',
  or: 'OU',
  tipogrupo: 'Tipo de grupo',
  campoduplicadonaoadicionado: 'Condicional duplicada não adicionada',
  certezaexcluircampoformulario:
    'Este campo faz parte de uma condição. Tem certeza que deseja excluir este campo?',
  certezaexcluirgrupoformulario: 'Tem certeza que deseja excluir este grupo?',
  linkinvalido: 'Link Inválido',
  autoCompleteColaborator: 'Selecione o colaborador elegivel',
  adicionartexto: 'Adicionar texto',
  paragrafo: 'Parágrafo',
  informeparagrafo: 'Informar parágrafo',
  informepesoresposta: 'Informe o peso da resposta',
  mostrarcabecalho:
    'Deseja que o cabeçalho não se oculte durante as respostas?',
  formulariosalvo: 'Formulário salvo com sucesso!!!',
  avaliacaosalva: 'Avaliação salva com sucesso!!!',
  certezaexcluiravaliacao: 'Tem certeza que deseja excluir esta avaliação?',
  usuarioduplicado: 'Já existe usuário : com esse email',
  certezaexcluirusuario: 'Tem certeza que deseja excluir esse usuário?',
  reativarusuario: 'Reativar usuário',
  inativarusuario: 'Inativar usuário',
  certezainativarusuario: 'Tem certeza que deseja inativar esse usuário?',
  usuarioinativado: 'Usuário inativado com sucesso!!!',
  erroaoinativarousuario: 'Não foi possível inativar o usuário',
  usuarioreativado: 'Usuário reativado com sucesso!!!',
  certezaexcluirconfiguracao:
    'Tem certeza que deseja excluir essa configuração?',
  placeholderbuscadocumentocliente:
    'Digite o tipo de documento ou a data de envio',
  acessaanalytics: 'Tem acesso a relatórios e/ou dashboards do DMAnalytics',
  descricaodocargo: 'Descrição do cargo',
  etapasdeselecaodavaga: 'Etapas de seleção da vaga',
  requisicaodevaga: 'Requisição de vaga',
  cadastroavaliacoes: 'Cadastro de avaliações',
  cadastroformularios: 'Cadastro de formulários',
  listaciclos: 'Lista de ciclos',
  analisardocumentos: 'Analisar documentos',
  vinculardocumentos: 'Vincular documentos',
  parametrizar: 'Parametrizar',
  analisar: 'Analisar',
  numeroidentificacao: 'Número de identificação / CNPJ',
  numeroidentificacaocnpj: 'Número de identificação (CNPJ, se Brasil)',
  informenumeroidentificacao: 'Informe o número de identificação da empresa',
  informenomeempresa: 'Informe o nome da empresa',
  homepage: 'Home page',
  informehomepageempresa: 'Informe a home page da empresa',
  empresas: 'Empresas',
  periodicidade: 'Periodicidade',
  selecioneperiodicidade: 'Selecione a periodicidade',
  naocorrencia: 'Na ocorrência',
  diario: 'Diário',
  bimestral: 'Bimestral',
  trimestral: 'Trimestral',
  cadastral: 'Cadastral',
  fiscal: 'Fiscal',
  gestao: 'Gestão',
  saudeeseguranca: 'Saúde e Segurança',
  trabalhista: 'Trabalhista',
  selecionecategoria: 'Selecione a categoria',
  complexidade: 'Complexidade',
  texto: 'Texto',
  comoobter: 'Como obter',
  normasrelacionadas: 'Normas relacionadas',
  duvidasfrequentes: 'Dúvidas frequentes',
  links: 'Links',
  removerlink: 'Remover link',
  adicionarlink: 'Adicionar link',
  textos: 'Textos',
  removertexto: 'Remover texto',
  informetexto: 'Informe o texto',
  rotinadiaria: 'Rotina diária',
  naohouveregistro: 'Não houve registros no período informado',
  naohouveoperacao: 'Não houve operação no período informado',
  periodosemregistro: 'Período sem registro',
  periodosemoperacao: 'Período sem operação',
  certezaexcluirrotinadiaria:
    'Tem certeza que deseja excluir essa rotina diária?',
  ocorrencia: 'Ocorrência',
  selecioneocorrencia: 'Selecione a ocorrência',
  admissional: 'Admissional',
  monitoramento: 'Monitoramento',
  demissional: 'Demissional',
  selecionetipodocumento: 'Selecione o tipo de documento',
  conternodossie: 'Conter no dossiê',
  novencimento: 'No vencimento',
  novencimentoadmissao: 'No vencimento pós admissão',
  parametrizardocumentos: 'Parametrizar documentos',
  tiposempresas: 'Tipos de Empresas',
  tipoempresa: 'Tipo de empresa',
  informetipoempresa: 'Informe o tipo de empresa',
  informedescricaotipoempresa: 'Informe a descrição do tipo de empresa',
  certezaexcluirtipoempresa:
    'Tem certeza que deseja excluir esse tipo de empresa?',
  exigirdadosCNH: 'Exige dados de CNH',
  avaliacaoRegraPontos:
    'Não foi possível salvar! A soma dos pontos deve ser igual ou menor ao valor máximo.',
  confirmarPontosInsuficientes:
    'A soma dos pontos é menor que o valor máximo. Tem certeza que deseja salvar esse formulário?',
  fieldIsRequired: 'Este campo é obrigatório!',
  invalidFormat: 'Formato Inválido!',
  numberTooSmall: 'O número é muito pequeno! Mínimo: {0}',
  numberTooBig: 'O número é muito grande! Máximo: {0}',
  invalidNumber: 'Número inválido',
  invalidInteger: 'O valor não é um número inteiro',
  textTooSmall:
    'O comprimento do texto é muito pequeno! Atual: {0}, Mínimo: {1}',
  textTooBig: 'O comprimento do texto é muito grande! Atual: {0}, Máximo: {1}',
  thisNotText: 'Isso não é um texto!',
  thisNotArray: 'Isso não é uma matriz!',
  selectMinItems: 'Selecione no mínimo {0} itens!',
  selectMaxItems: 'Selecione no máximo {0} itens!',
  invalidDate: 'Data inválida!',
  dateIsEarly: 'A data é muito cedo! Atual: {0}, Mínimo: {1}',
  dateIsLate: 'A data é muito tarde! Atual: {0}, Máximo: {1}',
  invalidEmail: 'Endereço de email invalido!',
  invalidURL: 'URL inválida!',
  invalidCard: 'Formato de cartão inválido!',
  invalidCardNumber: 'Número de cartão inválido!',
  invalidTextContainNumber:
    'Texto inválido! Não pode conter números ou caracteres especiais',
  invalidTextContainSpec:
    'Texto inválido! Não pode conter caracteres especiais',
  erroOpcoesIguais: 'Cada opção deve ser única',
  informeumaopcao: 'Informe pelo menos uma opção',
  alteracoes: 'Alterações',
  empresaatual: 'Empresa atual',
  empresadestino: 'Empresa destino',
  unidadenaovinculadaaempresa:
    'A unidade selecionada não é vinculada à empresa selecionada',
  cadastreumaempresa: 'Cadastre uma empresa',
  empresadestinoinvalida:
    'A empresa destino não pode ser igual à empresa atual',
  empresaselecionadainvalida:
    'A empresa selecionada não é vinculada à unidade selecionada',
  cargoeousalario: 'Cargo e/ou salário',
  funcaoatual: 'Função atual',
  novafuncao: 'Nova função',
  obrigatoriosecargotemfuncao:
    'Obrigatório se o cargo possuir uma ou mais funções vinculadas',
  certezaexcluircliente: 'Tem certeza que deseja excluir esse cliente?',
  nomefantasia: 'Nome fantasia',
  informenomefantasiaempresa: 'Informe o nome fantasia da empresa',
  certezaexcluirempresa: 'Tem certeza que deseja excluir essa empresa?',
  justificativaspadrao: 'Justificativas padrão',
  adicionarestruturasequencial: 'Adicionar estrutura sequencial',
  funcaoselecionadainvalida: 'A nova função não pode ser igual à função atual',
  cadastreumafuncao: 'Cadastre uma função',
  datapromocaoanterioradmissao:
    'A data da promoção não pode ser anterior à data da admissão do colaborador',
  datapromocaoanteriorinicioprestacao:
    'A data da promoção não pode ser anterior à data de início de prestação de serviços do colaborador',
  papellideranca: 'Papel de liderança',
  passouserlider: 'Passou a ser uma liderança',
  deixouserlider: 'Deixou de ser uma liderança',
  competencia: 'Competência',
  datavencimento: 'Data de vencimento',
  tipodedocumento: 'Tipo de documento',
  tipodedocumentomin: 'tipo de documento',
  statusnaoenviado: 'Não enviado',
  statusdepositado: 'Depositado',
  statuscancelado: 'Cancelado',
  statusfinalizado: 'Finalizado',
  enviardocumento: 'Enviar documento',
  verdocumento: 'Ver documento',
  editardocumento: 'Editar documento',
  datepicker_label_prevDecade: 'Década anterior',
  datepicker_label_prevYear: 'Ano anterior',
  datepicker_label_prevMonth: 'Mês passado',
  datepicker_label_currentMonth: 'Mês atual',
  datepicker_label_nextMonth: 'Próximo mês',
  datepicker_label_nextYear: 'Próximo ano',
  datepicker_label_nextDecade: 'Próxima década',
  datepicker_label_today: 'Hoje',
  datepicker_label_selected: 'Data selecionada',
  datepicker_label_noDateSelected: 'Nenhuma data selecionada',
  datepicker_label_calendar: 'Calendário',
  datepicker_label_nav: 'Navegação do calendário',
  datepicker_label_help: 'Use as teclas de seta para navegar pelo calendário',
  selecionarhoje: 'Selecionar hoje',
  fechar: 'Fechar',
  multiselect_placeholder: 'Selecione uma ou mais opções',
  multiselect_select_label: 'Pressione enter para selecionar',
  multiselect_select_group_label: 'Pressione enter para selecionar o grupo',
  multiselect_selected_label: 'Selecionado',
  multiselect_deselect_label: 'Pressione enter para remover',
  multiselect_deselect_group_label: 'Pressione enter para desmarcar o grupo',
  multiselect_max_elements:
    'Máximo de {max} opções selecionadas. Primeiro remova uma opção selecionada para selecionar outra',
  feed: 'Feed',
  consultarcolaboradores: 'Consultar colaboradores',
  acessararquivos: 'Acessar arquivos',
  cadastrararquivos: 'Cadastrar arquivos',
  baseconhecimento: 'Base de conhecimento',
  certezaexcluirgrade: 'Tem certeza que deseja excluir essa grade?',
  formulariocopiado: 'Formulário copiado com sucesso!!!',
  avaliacaocopiada: 'Avaliação copiada com sucesso!!!',
  dataadmissaoinvalida:
    'A data de admissão precisa ser menor ou igual à data atual',
  datafinalsuperiorinvalida:
    'A data final não pode ser inferior à data inicial',
  dataproximovencimento: 'Data do próximo vencimento',
  tolltipdataproximovencimento:
    'Data em que o documento deverá ser depositado novamente',
  placeholderbuscanomedocumento: 'Digite o nome do documento',
  formularioparametrizacao: 'Formulário de parametrização',
  trazerdadoscolaboradoresativosmes:
    'Trazer dados de colaboradores ativos no mês',
  trazerdadoscolaboradoresdesligados:
    'Trazer dados de colaboradores desligados',
  aceitanaoaplicavel: 'Aceita "Não Aplicável"',
  aceitavinculacao: 'Aceita Vinculação',
  analisardocumento: 'Analisar Documento',
  analisepelocliente: 'Análise pelo Cliente',
  reanalisepelocliente: 'Reanálise pelo Cliente',
  gerabloqueio: 'Gera Bloqueio?',
  antecipavel: 'É Antecipável?',
  tempovalidacao: 'Tempo de Validação',
  parametrizacaodocumento: 'Parametrização de documento',
  palavraschavearquivo: 'Palavras-chave',
  videorelacionado: 'Vídeo relacionado',
  comodisponibilizar: 'Vinculado a',
  arquivosoulinks: 'Adicone ao menos um arquivo ou item',
  arquivoporecossistemas: 'Vincular aos ecossistemas',
  arquivoporclientes: 'Clientes, estruturas e cargos',
  placeholderbuscaarquivo:
    'Digite um nome, descrição ou palavras chave do arquivo',
  certezaexcluirarquivo: 'Tem certeza que deseja excluir esse arquivo?',
  linkdoform: 'Link do Formulário',
  linkdowebsite: 'Link do Dashboard',
  linkdovideo: 'Link do Vídeo',
  selecioneagrade: 'Selecione a grade',
  informacoesporcliente: 'clientes, estruturas e cargos',
  selecioneempresa: 'Selecione a empresa',
  selecionecargo: 'Selecione o cargo',
  responsavelanalise: 'Responsavel pela analise',
  selecionecargoresponsavelporanalise:
    'Selecione o cargo resposavel pela analise',
  atividadeespecial: 'Atividade especial',
  vinculacaodocumento: 'Vinculação de documento',
  resumidomin: 'resumido',
  completomin: 'completo',
  periodicidadedocumento: 'Periodicidade de apresentação',
  categoriadocumento: 'Categoria do documento',
  complexidadeanalise: 'Complexidade da Análise',
  exibirdossiecolaborador: 'Exibir no dossiê do colaborador',
  justificativacancelamento: 'Justificativa de cancelamento',
  comoobterdocumento: 'Como obter este documento',
  normasdocumento: 'Normas relacionadas ao documento',
  duvidasdocumento: 'Dúvidas sobre o documento',
  modelodocumento: 'Modelo do documento',
  fichadegente: 'Ficha de Gente',
  remover: 'Remover',
  conteudo: 'Conteúdo',
  conteudos: 'Conteúdos',
  conteudomin: 'conteúdo',
  conteudosmin: 'conteúdos',
  informenomeconteudo: 'Informe o nome do conteúdo',
  informedescricaoconteudo: 'Informe a descrição do conteúdo',
  palavraschaveconteudo: 'Palavras-chave',
  conteudosoulinks: 'Adicione ao menos um conteúdo ou item',
  escolhaconteudos: 'Escolha um ou mais conteúdos ou arraste-os...',
  certezaexcluirconteudo: 'Tem certeza que deseja excluir esse conteúdo?',
  placeholderbuscaconteudo:
    'Digite um nome, descrição ou palavras chave do conteúdo',
  novaentrevista: 'Nova entrevista',
  recuperacaosenha: 'Recuperação de senha',
  recuperarsenha: 'Recuperar senha',
  usuarionaoencontrado: 'Usuário não encontrado',
  linkrecuperasenha: 'Link para redefinir senha',
  termosdeuso: 'Termos de uso',
  aceitotermosdeuso: 'Li e aceito os termos de uso',
  dossiedocolaborador: 'Dossiê do colaborador',
  statusativo: 'Ativo',
  statusafastado: 'Afastado',
  statusausente: 'Ausente',
  statusferias: 'Em férias',
  statusdesligado: 'Desligado',
  statusdemitido: 'Demitido',
  documentacao: 'Documentação',
  historicomovimentacao: 'Histórico de movimentações',
  situacaoatualcolaborador: 'Situação atual do colaborador',
  consultahierarquia: 'Consulta hierarquia',
  collaborator: 'Colaborador',
  leaderships: 'Lideranças',
  subordinates: 'Liderados',
  participantes: 'Participantes',
  selecioneparticipantes: 'Selecione os participantes',
  liderancas: 'Lideranças',
  avaliadoresdaetapa: 'Avaliadores da etapa',
  avaliadonaetapa: 'Avaliado na etapa',
  selecioneavaliadores: 'Selecione os avaliadores',
  selecioneavaliado: 'Selecione o avaliado',
  adicionarlideradosdahierarquiadeestruturas:
    'Selecionar liderados da hierarquia de estruturas do líder',
  adicionarlideradosforadahierarquiadeestruturas:
    'Selecionar liderados de outra estrutura',
  considerarsomentenovasocorrencias:
    'Considerar apenas ocorrências realizadas após o cadastro do documento',
  naoaplicavel: 'Não aplicável',
  criteriosdeaceitacao: 'Critérios de aceitação',
  criacaocamposanalise: 'Criação dos campos de análise',
  existeregistromovimentacao:
    'Existem registros de movimentação pra esse colaborador após a admissão. Exclua estes registros antes de cancelar o registro da admissão.',
  aceitamaisdeumarespostaporusuario: 'Aceita mais de uma resposta por usuário',
  analiseanaoanalisada: 'Não analisado',
  analiseanalisada: 'Aprovado',
  analisecancelada: 'Cancelado',
  reanalisar: 'Reanalisar',
  documentopossuideposito:
    'Existem depósitos para este documento. Caso seja alterado, os status atuais podem ser atualizados. Deseja continuar?',
  analizadopor: 'Analisado por',
  dataanalise: 'Data da análise',
  historico: 'Histórico',
  btableemptytext: 'Não há registros para mostrar',
  datadeposito: 'Data do depósito',
  situacaocandidato: 'Situação do candidato',
  errocolaboradorativo:
    'Não foi possível excluir essa estrutura, pois há colaboradores ativos vinculados a ela ou a suas subestruturas',
  contemanalise: 'Analisar documento',
  'aviso-doc-nao-parametrizado':
    'Não é possível analisar o documento porque ele não está parametrizado',
  verjustificativarelacionamento: 'Ver justificativa de cancelamento',
  porfavoraguardesuasconfiguracoes:
    'Por favor, aguarde enquanto carregamos suas configurações de acesso.',
  tipodedocumentoinvalido: `Extensão de documento inválida! Aceito: {acceptTypes}`,
  statusexcluido: 'Excluído',
  parametrizacaotipodedocumentoexcluido:
    'Essa parametrização é referente a um documento excluído e não pode ser alterada',
  depositotipodedocumentoexcluido:
    'Esse depósito é referente a um documento excluído e não pode ser alterado',
  analisetipodedocumentoexcluido:
    'Essa análise é referente a um documento excluído e não pode ser alterada!',
  statusdocumento: 'Status do documento',
  datainiciomonitoramento: 'Data de início de monitoramento',
  certezacancelardocumento: 'Tem certeza que deseja cancelar esse documento?',
  documentocancelado: 'Documento cancelado com sucesso!!!',
  erroaocancelardocumento: 'Erro ao cancelar documento',
  analisedocumento: 'Análise de documento',
  impossivelexibirdocumento:
    'Não é possivel exibir este documento, o mesmo pode ter sido excluido.',
  documentopossuianalise:
    'Essa parametrização já contém analises feitas e não pode ser alterada',
  dadosdodepositante: 'Dados do depositante',
  dadosdocolaborador: 'Dados do colaborador',
  horadeposito: 'Horário de depósito',
  deposito: 'Depósito',
  analise: 'Análise',
  verhistorico: 'Ver histórico',
  informecomentario: 'Informe um comentário',
  pesquisaeanonima: 'A pesquisa é anônima',
  idadeinvalida: 'A idade do colaborador deve ser superior ou igual a 14 anos',
  idadeadmissaoinvalida:
    'A idade do colaborador deve ser superior ou igual a 14 anos na data que foi admitido',
  erroanoinferior1920: 'O ano não pode ser inferior a 1920',
  datainicioinferioradmissao:
    'A data de início deve ser superior à data de início da prestação de serviços do colaborador',
  datainiciosuperioratual:
    'A data de início não pode ser superior à data atual',
  anosuperior2090: 'O ano não pode ser superior a 2090',
  datainferioradmissao:
    'A data deve ser superior à data de admissão do colaborador',
  datainferiorprestacaoservicos:
    'A data deve ser superior á data de início de prestação de serviços do colaborador',
  datasuperioratual: 'A data não pode ser superior à data atual',
  dataterminoperiodosuperior3anos:
    'A data de términino das férias não pode ser superior a 3 anos da data atual',
  desejadesvicularcliente:
    'Se você desvincular algum cliente, todas as unidades desse cliente que forem vinculadas ao ecossistema serão desvinculadas. Deseja continuar?',
  analiseserelatorios: 'Análises e Relatórios',
  analises: 'Análises',
  admissoes: 'Admissões',
  colaboradoresativos: 'Colaboradores ativos',
  vercolaboradoresativos: 'Ver colaboradores ativos',
  hierarquias: 'Hierarquias',
  rotinasdiarias: 'Rotinas diárias',
  tiposdecontratos: 'Tipos de contratos',
  depositos: 'Depósitos',
  consultadedocumentos: 'Consulta de documentos',
  selecioneanovaestruturasuperior: 'Selecione a nova estrutura superior',
  alterarestruturasuperior: 'Alterar estrutura superior',
  existecandidatonavaga:
    'Não é possivel incluir, excluir ou alterar etapas, pois já existem candidatos inscritos na vaga',
  pesquisacomresposta:
    'Pesquisa possui resposta vinculada, não pode ser alterada!',
  statusexcluida: 'Excluída',
  inspecoes: 'Inspeções',
  novainspecao: 'Nova inspeção',
  diautilfechamento: 'Dia útil de fechamento',
  informediautilfechamento: 'Informe o dia útil de fechamento',
  diautilinvalido: 'O dia deve ser maior que 0 e menor que 22',
  certezaexcluirvinculo: 'Tem certeza que deseja excluir este vínculo?',
  selecionesituacao: 'Selecione a situação do candidato',
  agendamentodoisoumaiscandidatos: 'Agendar para dois ou mais candidatos',
  candidatossememailcadastrado:
    'Exitem candidatos que não informaram o email. Não é possivel enviar mensagens para eles.',
  candidatossememailesemoption:
    'Existem candidatos que não informaram o email e não concordaram em receber Whatsapp. Não é possível enviar mensagens para eles.',
  agendamentosemsegundoplano: 'Agendamentos sendo feitos em segundo plano!!!',
  horarios: 'Horários',
  copiarparatodos: 'Copiar para todos',
  isValidCPF: 'CPF inválido',
  isValidBirthDate: 'data de nascimento inválida',
  isValidAdmissionDate: 'data de admissão inválida',
  datapromocaoposteriordataatual:
    'A data da promoção não pode ser posterior à data atual',
  numerodediasaposadmissao: 'Número de dias após a admissão',
  informenumerodedias: 'Informe o número de dias',
  selecioneecosistema: 'Selecione o ecossistema',
  ecossistemadocumento: 'Ecossistema do documento',
  realizado: 'Realizado',
  realizada: 'Realizada',
  organizacaoempresa: 'Organização / Empresa',
  trazerlistadecolaboradoresativos:
    'Trazer lista de colaboradores ativos na referência',
  documentocopiado: 'Documento copiado com sucesso!!!',
  aceitadepositovinculado: 'Aceita depósito vinculado',
  colaboradoresativosnacompetencia: 'Colaboradores ativos na competência',
  selecionepelomenosumapendencia: 'Selecione pelo menos uma competência',
  depositarpara: 'Depositar para',
  depositarparatodos: 'Depositar para todos',
  analisecomdepositovinculado:
    'Está analise é referente a um depósito vinculado, ou seja, irá fazer uma analize para várias unidades. Deseja continuar?',
  dadosdodeposito: 'Dados do depósito',
  aceiteparcial: 'Aceite parcial',
  veranaliseparcial: 'Ver análise parcial',
  colaboradoresnaoconforme: 'Colaboradores Não Conforme',
  selecioneostatusunidade: 'Selecione o status para cada unidade',
  apenasnovosregistrosaposdocumento:
    'Considerar apenas registros realizados após o cadastro do documento',
  alterarregistrodeletado: 'Não é possivel alterar um registro deletado',
  acidentedoencadotrabalho: 'Acidente / Doença do Trabalho',
  salariomaiorpromocaorecente:
    'O salário não pode ser maior que o da promoção mais recente',
  geracobrancadecat: 'Gera cobrança de CAT',
  admissaocontraida: 'Adm',
  demissaocontraida: 'Dem',
  geracobrancadedocumento: 'Gera cobrança de documento',
  afastamentosuperiordias: 'Afastamento superior a 30 dias',
  unidadescomexigenciadodocumento: 'Unidades com exigência do documento',
  hasWorkLeave: 'O colaborador encontra-se afastado neste período',
  AlreadyExistsWorkleaveError:
    'O colaborador encontra-se afastado neste período',
  hasAbsence: 'O colaborador encontra-se ausente neste período',
  AlreadyExistsAbsenceError: 'O colaborador encontra-se ausente neste período',
  hasVacation: 'O colaborador encontra-se em férias neste período',
  AlreadyExistsVacationError:
    'O colaborador encontra-se em férias neste período',
  hasTransfer: 'O colaborador possui uma tranferência neste período',
  hasRise: 'O colaborador possui uma promoção neste período',
  hasOccurrence: 'O colaborador possui uma ocorrência neste período',
  hasBondChange: 'O colaborador possui uma mudança de contrato neste período',
  hasMovement:
    'Não é possível salvar o registro. Existem movimentações posteriores a essa movimentação',
  demissoesnaocadastradas: 'Demissões não cadastradas',
  ausenciasnaocadastradas: 'Ausências não cadastradas',
  superioratodoscolaboradores:
    'A data deve ser superior à data de início de prestação de serviços de todos os colaboradores',
  demissoesemsegundoplano: 'Demissões sendo feitas em segundo plano!!!',
  ausenciasemsegundoplano: 'Ausências sendo feitas em segundo plano!!!',
  demissoescadastrado: 'Demissões cadastradas com sucesso!!!',
  ausenciascadastrado: 'Ausências cadastradas com sucesso!!!',
  tipodedemissaoobrigatorio: 'O tipo de demissão é obrigatório',
  motivodemissaoobrigatorio: 'O motivo da demissão é obrigatório',
  feriasultrapassamolimiteconcessivo:
    'Férias lançadas ultrapassam o limite concessivo, deseja prosseguir?',
  acessaconsultaworkplace:
    'Acessa lista de colaboradores no Workplace, mesmo se não for um colaborador',
  naopossivelcancelaradmissao:
    'Não é possível cancelar a admissão. Existem documentos depositados para o colaborador',
  erropromocao:
    'Não foi possível inserir o registro. Colaborador recebeu promoção na data/período informado',
  erroocorrencia:
    'Não foi possível inserir o registro. Colaborador tem ocorrência na data/período informado',
  errotransferencia:
    'Não foi possível inserir o registro. Colaborador em transferência na data/período informado',
  errotipodecontrato:
    'Não foi possível inserir o registro. Colaborador em mudança de contrato na data/período informado',
  nomecampoexiste: 'Já existe um campo com esse nome',
  baixarmodelo: 'Baixar modelo',
  modeloimportacaoadmissao: 'importacao_admissão_modelo',
  errodemissao:
    'Não foi possível salvar o registro. O colaborador foi demitido ou teve sua admissão cancelada',
  exercepapeldegestor: 'Exerce papel de gestor',
  EmployeeIsFiredError:
    'Não é possível realizar o cadastro. Colaborador demitido ou com admissão cancelada',
  erroexclusaomovimentacaoposterior:
    'Não foi possível excluir o registro. Existem movimentações posteriores a essa movimentação',
  AbsenceDateCannotBeLessThanAdmissionDateError:
    'Não é possível realizar o cadastro. A data inicial da ausência é menor que a data de admissão',
  AbsenceDateCannotBeLessThanServiceProvisionDateError:
    'Não é possível realizar o cadastro. A data inicial da ausência é menor que a data de início da prestação de serviços',
  reabrirvaga: 'Reabrir vaga',
  desejareabrir: 'Deseja realmente reabrir esta vaga?',
  datadecorte: 'Data de corte',
  datacontabilizartempocasa:
    'Data considerada para contabilizar o tempo de casa',
  asrespostasseraoanonimas: 'As respostas serão anônimas',
  possuiplanejamento: 'Possui planejamento',
  tipoderesposta: 'Tipo de resposta',
  autoresposta: 'Responder sobre si mesmo',
  respostasobreoutrocolaborador: 'Resposta sobre outro colaborador',
  respostasobreoutrocolaboradorgeg:
    'Responder sobre colaboradores do Gente e Gestão',
  prioridadedeavaliacao: 'Prioridade de avaliação',
  gestor: 'Gestor',
  tempomaximodevesermaiorquezero:
    'O tempo máximo de resposta deve ser maior ou igual a 0',
  exigeinformacoesatestado: 'Exige informações do atestado',
  concordareceberwhatsappdaempresa:
    'Concorda em receber mensagens da empresa via whatsapp',
  configuracaodociclo: 'Configuração do Ciclo',
  execucaodociclo: 'Execução do Ciclo',
  ciclodeavaliacoes: 'Ciclo de Avaliações',
  datadefim: 'Data de Fim',
  etapasdociclo: 'Etapas do Ciclo',
  regraseparametrosdociclo: 'Regras e Parâmetros',
  funcaoobrigatorio: 'O campo função é obrigatório',
  funcoesobrigatorio: 'O campo funções é obrigatório',
  funcaoobrigatorioclient: 'A função é obrigatória',
  algumregistrovazio: 'Todos os campos obrigatórios devem ser preenchidos',
  solicitacoes: 'Solicitações',
  disponibilizarformworkplace: 'Disponibilizar formulário no Workplace',
  desejaformwokplace:
    'Deseja realmente disponibilizar esse formulário no Workplace?',
  removerformworkplace: 'Remover do Workplace',
  desejaremoverformwokplace:
    'Deseja realmente remover esse formulário do Workplace?',
  erroaodisponibilizarworkplace:
    'Não foi possivel vincular este formulário ao Workplace',
  responderformulario: 'Preencher formulário',
  identificacaodocolaborador: 'Identificação do colaborador',
  formularionaoencontrado: 'Formulário não encontrado',
  semplanejamentosativos: 'Sem planejamentos ativos',
  usuarionaoidentificado: 'Usuário não identificado',
  formdataforadoperiodopermitido:
    'Formulário não pode ser respondido. A data está fora do período permitido',
  formrespondidomaisdeumavez:
    'Formulário não pode ser respondido mais de uma vez',
  aceitarespostasdatadetermino: 'Aceita respostas após data de término',
  arquivorelacionados: 'Arquivo relacionados',
  tempoduracao: 'Tempo de duração',
  informetempoduracao: 'Infome o tempo de duração',
  quaispessoasparticiparao: 'Quais pessoas participarão ?',
  nomedocolaborador: 'Nome do colaborador',
  documentodocolaborador: 'Documento do colaborador',
  nomedousuario: 'Nome do usuário',
  documentodousuario: 'Documento do usuário',
  estruturadocolaborador: 'Estrutura do colaborador',
  nomedoautor: 'Nome do autor',
  documentodoautor: 'Documento do autor',
  estruturadoautor: 'Estrutura do autor',
  naoeliderougerente:
    'Você não tem permissão para responder esse formulário. Precisa ser uma liderança ou um gestor',
  formrespondidomaisdeumavezmesmapessoa:
    'Formulário não pode ser respondido mais de uma vez para a mesma pessoa',
  selecioneumparticipante: 'Selecione um participante',
  enviarplanejamentoporparticipante: 'Enviar planejamento por participante',
  jaexisteregistroferiasparaessecolaborador:
    'Já existe um registro de férias para esse colaborador no período aquisitivo informado. Atualize o registro existente.',
  liderancaimediata: 'Liderança imediata',
  informetempomaximo: 'Informe o tempo máximo',
  duracaodevesermaiorquezero: 'O tempo de duração deve ser maior ou igual a 0',
  confirmarcolaboradoressememail:
    'Há um ou mais colaboradores sem email associado. Deseja continuar?',
  colaboradoressememail: 'Colaborador não tem email associado',
  colaboradoressememailetelefone:
    'Colaborador não tem email ou telefone associado',
  horariodaresposta: 'Horário da resposta',
  'interview-form-answer': {
    nomedocolaborador: 'Nome do colaborador entrevistado',
    documentodocolaborador: 'Documento do colaborador entrevistado',
    estruturadocolaborador: 'Estrutura do colaborador entrevistado'
  },
  'health-form-answer': {
    nomedocolaborador: 'Nome do colaborador inspecionado',
    documentodocolaborador: 'Documento do colaborador inspecionado',
    estruturadocolaborador: 'Estrutura do colaborador inspecionado'
  },
  avaliadonomedocolaborador: 'Nome do colaborador avaliado',
  avaliadodocumentodocolaborador: 'Documento do colaborador avaliado',
  avaliadoestruturadocolaborador: 'Estrutura do colaborador avaliado',
  formulariodisponibilizado: 'Formulário disponibilizado com sucesso',
  enviowhatsappformulario: 'Envio de whatsapp para acesso a formulários',
  confirmarcolaboradoressememailetel:
    'Há um ou mais colaboradores sem email ou telefone associado. Deseja continuar?',
  funcoesparticipantes: 'Funções participantes',
  quaisfuncoesdevemparticipar: 'Quais funções devem participar?',
  apartirde: 'A partir de',
  colaboradorsememailesemoptin:
    'Colaborador não informou email e não concordou em receber Whatsapp. Não é possível enviar mensagens para ele.',
  colaboradoressememailesemoptin:
    'Há um ou mais colaboradores que não informaram email e não concordaran em receber Whatsapp. Não é possível enviar mensagens para eles.',
  formularioremovido: 'Formulário removido com sucesso',
  permitirusuariocomumaddcampo: 'Permitir que usuários comuns adicionem campos',
  campoadmin: 'Campo criado por administrador - Não editavel',
  horariossaoobrigatorios: 'Os horários são obrigatórios',
  reprovarcandidato: 'Reprovar candidato',
  certezareprovarcandidato: 'Tem certeza que deseja reprovar esse candidato?',
  candidatoreprovado: 'Candidato reprovado com sucesso!!!',
  erroaoreprovarcandidato: 'Erro ao reprovar candidato!!!',
  erroexclusaocolaboradornaoexiste:
    'Não é possível excluir o registro pois o colaborador foi desligado',
  esteformularioeglobal: 'Este formulário é global',
  visibilidade: 'Visibilidade',
  vincularatodososclientes: 'Vincular a todos os clientes',
  vincularaumoumaisecossistemas: 'Vincular a um ou mais ecossistemas',
  usuarionaocolaborador: 'Você não é um colaborador deste cliente',
  gerademandadereposicao: 'Gera demanda de reposição',
  gerademandadecontratacao: 'Gera demanda de contratação',
  colaboradoressubstituidos: 'Colaboradores a serem substituídos',
  informecolaboradoressubstituidos:
    'Informe os colaboradores que serão substituíudos',
  colaboradormaiorquequantidadevaga:
    'Não é possível selecionar mais colaboradores do que a quantidade de vagas',
  informeadatadefechamento: 'Informe a data de encerramento',
  errodatadefechamentoinvalida: 'Data de encerramento inválida',
  erropessoasouestruturasativas:
    'Não é possível excluir essa empresa pois existem colaboradores ou estruturas ativas vinculados a ela',
  prontuariodocolaborador: 'Prontuário do colaborador',
  tipodemovimentacao: 'Tipo de movimentação',
  selecioneotipodemovimentacao: 'Selecione o tipo de movimentação',
  templatededatacomhorario: 'DD/MM/YYYY [às] HH:mm',
  dataexclusao: 'Data Exclusão',
  excluidopor: 'Excluído Por',
  erroempresasouestruturasativas:
    'Não é possível excluir esse cliente pois existem empresas ou estruturas ativas, vinculadas a ele',
  days_after_admission: 'Dias após a admissão',
  admitted_until: 'Admitidos até a data',
  general: 'Geral',
  aplicacaociclo: 'Aplicação do ciclo',
  aplicacaociclomin: 'aplicação do ciclo',
  tiposdevinculos: 'Tipos de Vínculos',
  tiposdevinculosmin: 'tipos de vínculos',
  informediasaposadmissaoiniciaociclo: 'Informe quantos dias após a admissão inicia o ciclo',
  admitidosate: 'Admitidos até',
  selecionedatacorteadmitidos: 'Selecione a data de corte dos admitidos',
  datainicialmenorqueciclo:
    'A data de início da etapa não pode ser anterior à data de início do ciclo',
  impossivelcadastrarciclo:
    'Não é possível cadastrar esse ciclo. Existem colaboradores sem hierarquia de liderança cadastrada',
  excluirelegiveis: 'Excluir elegíveis',
  naoutilizair: 'Não utiliza indice de resultados neste ciclo',
  porcentagemir: '(% IR - Índice de Resultados)',
  valorinicialporcentagem: 'Informe o valor inicial em %',
  valorfinalporcentagem: 'Informe o valor final em %',
  parecerderesultadoir: 'Parecer de Resultados IR',
  parecerderesultadoirmin: 'parecer de resultados IR',
  informeparecerderesultadosir: 'Informe o nome do parecer de resultados do IR',
  arvoredeclassificacoes: 'Árvore de classificações',
  irciclo: 'IR',
  iccciclo: 'ICC',
  indicederesultados: 'Índice de resultados',
  indicederesultadosmin: 'índice de resultados',
  result_is_not_unique: {
    results_index:
      'Não pode haver dois pareceres de resultados com o mesmo nome',
    compliances_index:
      'Não pode haver dois pareceres de resultados com o mesmo nome',
    classification_tree: 'Não pode haver duas classificações com o mesmo nome'
  },
  pairs_is_not_unique:
    'Não pode haver duas classificações com o mesmo IR e ICC',
  invalid_from_or_to_field:
    'Os intervalos de porcentagem não podem sobrepor registros já criados',
  certezaexcluirir: 'Tem certeza que deseja excluir esse indice de resultados?',
  porcentagemicc: '(% ICC - Índice de Conformidade Comportamental)',
  parecerderesultadoicc: 'Parecer de Resultados ICC',
  parecerderesultadoiccmin: 'parecer de resultados ICC',
  informeparecerderesultadosicc: 'Informe o parecer de resultados do ICC',
  pesodaautoavaliacao: 'Peso da Autoavaliação',
  informepesoautoavaliacao: 'Informe o peso (%) da Autoavaliação',
  pesodaavaliacaodedesempenho: 'Peso da Avaliação de Desempenho',
  informeopesodaavaliacaodedesempenho:
    'Informe o peso (%) da Avaliação de Desempenho',
  valormenorque0oumaiorque100:
    'O valor não pode ser menor que 0 nem maior que 100',
  sum_of_weights_different_from_100: 'A soma dos pesos devem totalizar 100',
  indicedeconformidadecomportamental: 'Índice de conformidade comportamental',
  indicedeconformidadecomportamentalmin:
    'índice de conformidade comportamental',
  certezaexcluiricc:
    'Tem certeza que deseja excluir esse indice de conformidade comportamental?',
  certezaexcluirarvoredeclassificao:
    'Tem certeza que deseja excluir essa classificação?',
  result_index_is_required_in_this_cycle:
    'Índices de resultados são obrigatórios neste ciclo',
  this_cycle_no_accept_result_index:
    'Este ciclo não utiliza índice de resultados',
  parecerderesultados: 'Parecer de resultados',
  placeholderbuscalideranca:
    'Digite um nome de líder ou de liderado da hierarquia',
  alterarliderancaparacolaborador:
    'Quero alterar a liderança para um colaborador',
  alterarliderancadeumgrupo: 'Quero alterar a liderança de um grupo',
  liderancanociclo: 'Liderança no ciclo',
  selecioneoliderparaociclodeavaliacoes:
    'Selecione o lider para o ciclo de avaliações',
  personalizarhierarquia: 'Personalizar hierarquia',
  valormaiorquezero: 'O valor deve ser maior que zero',
  estecolaboradornaotemliderança: 'Este colaborador não tem liderança',
  hierarquiapersonaliza: 'Hierarquia personalizada',
  certezaexcluircadastrodelegivel: `Tem certeza que deseja excluir esse cadastro de elegível?`,
  subordinate_and_cycle_leadership_must_be_different:
    'O liderado precisa ser diferente da liderança',
  subordinate_already_exists_for_this_cycle:
    'Já existe uma hierarquia personalizada neste ciclo para este colaborador',
  subordinate_hierarchy_not_found: 'Hierarquia atual não encontrada',
  subordinate_is_invalid: 'Este colaborador não pertence mais a este cliente',
  cycle_leadership_is_invalid: 'O líder já não pertence mais a este cliente',
  cycle_leadership_not_is_leadership:
    'O colaborador selecionado como lider já não é mais um líder',
  hierarquiaspersonalizadasemsegundoplano:
    'Hierarquias personalizadas sendo feitas em segundo plano!!!',
  hierarquiaspersonalizadascadastradas:
    'Hierarquias personalizadas cadastradas com sucesso!!!',
  selecioneumcolaborador: 'Selecione um colaborador',
  capacitacaoesensibilizacao: 'Capacitação e Sensibilização',
  autoavaliacao: 'Autoavaliação',
  avaliacaodedesempenho: 'Avaliação de Desempenho',
  avaliacaoderesultados: 'Avaliação de Resultados',
  reuniaodegente: 'Reunião de Gente',
  planodeacao: 'Plano de Ação',
  followplanodeacao: 'Follow do Plano de Ação',
  naovourealizaretapanociclo: 'Não vou realizar esta etapa no ciclo',
  informediasaposadmissaoiniciaaetapa:
    'Informe quantos dias após a admissão inicia a etapa',
  selecioneoformularioassociadoaetapa:
    'Selecione o formulário associado a essa etapa',
  informeclassificacoesqueparticipamdaetapa:
    'Informe as classificações que participam da etapa',
  classificacoes: 'Classificações',
  classificacoesquerealizampdi: 'Classificações que realizam PDI',
  classificacoesquerealizampr: 'Classificações que realizam PR',
  informeasclassificacoesquerealizampdi:
    'Informe as classificações que realizam PDI',
  informeasclassificacoesquerealizampr:
    'Informe as classificações que realizam PR',
  alteraclassificacao: 'Altera classificação',
  quantidadedefollows: 'Quantidade de follows',
  informeaquantidadedefollows: 'Informe a quantidade de follows',
  informeasclassificacoesqueparticipamdaetapa:
    'Informe as classificações que participam da etapa',
  datadeiniciodo: 'Data de inicio do',
  datadeterminodo: 'Data de término do',
  proximaetapa: 'Proxima etapa',
  datafinalmaiorquefinalciclo:
    'A data de término não pode ser posterior à data de término do ciclo',
  etapajainiciada:
    'A data inicial não pode ser alterada, pois esta etapa já foi iniciada',
  valordevesermaiorqueum: 'O valor deve ser maior ou igual a 1',
  quantosdiasaposadmissaoocorreraofollow:
    'Em quantos dias após a admissão ocorrerá o Follow',
  certezaexcluirirs:
    'Os índices de resultados cadastrados serão excluídos. Você realmente deseja excluí-los?',
  nomedaetapa: 'Nome da etapa',
  etapaspersonalizadasdociclo: 'Etapas personalizadas do ciclo',
  incluirnocalculodeaderenciadociclo:
    'Incluir no calculo de aderência do ciclo',
  enviaretapaparatodososcolaboradores:
    'Enviar a etapa para todos os colaboradores',
  enviaretapaparaclassificacoesselecionadas:
    'Enviar a etapa para classificações selecionadas',
  informeonomedaetapa: 'Informe o nome da etapa',
  selecioneaetapaanteriordociclo: 'Selecione a etapa anterior do ciclo',
  selecioneestruturasenviaretapa:
    'Selecione uma ou mais estruturas para as quais deseja enviar esta etapa',
  selecioneumoumaiscargosparaenviaretapa:
    'Selecione um ou mais cargos para enviar esta etapa',
  selecionequaiscolaboradoresenviaretapa:
    'Selecione para quais colaboradores esta etapa será enviada',
  selecioneasclassificacoesqueparticipamdaetapa:
    'Selecione as classificações que participam da etapa',
  days_after_admission_invalid:
    'O número de dias após admissão não pode ser inferior ao informado na etapa anterior',
  days_after_admission_invalid_trainingandawareness:
    'O número de dias após admissão não pode ser inferior ao informado no cadastro do ciclo',
  invalid_end_date:
    'A data final deve ser inferior ou igual a data final do ciclo',
  invalid_start_date:
    'A data de início da etapa não pode ser inferior à data de início da etapa anterior',
  invalid_start_date_trainingandawareness:
    'A data de início da etapa não pode ser anterior à data de início do ciclo',
  other_steps_depend_on_this: 'Outras etapas tem dependência desta',
  start_date_greater_than_child:
    'A data de início deve ser inferior à data início de etapas dependentes desta',
  datafollowinferioraantecessor:
    'A data de início deve ser inferior ou igual à data de início de follows posteriores',
  datafollowsuperioraposterior:
    'A data de início do follow não pode ser inferior à data de início do follow anterior',
  datainicialmenorqueatual:
    'A data de início da etapa não pode ser anterior à data atual',
  dataciclomaiorqueatual:
    'A data de inicio do ciclo deve ser maior que a data atual',
  diasfollowsuperioraposterior:
    'Os dias após a admissão devem ser superiores ou iguais aos dias após a admissão de follows anteriores',
  diasfollowinferioraantecessor:
    'Os dias após a admissão devem ser inferiores ou iguais aos dias após a admissão de follows posteriores',
  dataterminoalteradainvalida:
    'Como o ciclo já iniciou, a data de término só pode ser alterada para uma data posterior à informada no cadastro do ciclo',
  cicloincompleto:
    'Por favor finalize o cadastro das etapas deste ciclo antes de tentar duplicar ou copiar',
  clienteinvalido: 'Cliente inválido',
  has_answers_in_this_step:
    'O formulário associado a essa etapa já contém respostas, não é possivel alterá-lo',
  ciclocopiadosucesso: 'Ciclo copiado com sucesso!!!',
  cicloduplicadosucesso: 'Ciclo duplicado com sucesso!!!',
  same_name_as_custom_step: 'Já existe outra etapa personalizada com este nome',
  same_name_as_default_step:
    'Não é possivel salvar etapas personalizadas com o nome de etapas padrões',
  incluir: 'Incluir',
  informediasaposadmissaoterminaciclo:
    'Informe o limite de dias após admissão para participar do ciclo',
  valordevesermaiorouigualosdiasaposadmissao:
    'O limite de dias após a admissão deve ser superior ou igual ao valor inicial',
  days_after_admission_limit_invalid:
    'O número de dias após admissão não pode ser superior ao limite informado no cadastro do ciclo',
  daysAfterAdmission_greater_than_child:
    'O número de dias após a admissão deve ser inferior ao número de dias após a admissão de etapas dependentes desta',
  previous_step_invalid: 'Etapa anterior inválida',
  has_custom_steps_as_child:
    'Não é possivel desabilitar esta etapa, pois existem etapas personalizadas que dependem desta',
  this_client_has_collaborators_without_hierarchy:
    'O cliente selecionado para a cópia tem colaboradores sem hierarquia de liderança cadastrada',
  nomedalideranca: 'Nome da liderança',
  selecioneosliderados: 'Selecione os liderados',
  mensagemcomunicadociclo:
    'Vai começar a nossa avaliação de desenvolvimento e carreira, um dos principais processos de Gestão de Pessoas em nossa empresa.\n\nO processo de desenvolvimento e carreira tem por objetivo analisar o desempenho do colaboradores, através da auto avaliação, avaliação de lideranças, verificação de cumprimento das metas, através de indicadores gerar uma classificação e propor ações voltadas para o desenvolvimento pessoal.\n\nSe prepare, pois o processo ocorrerá nas seguintes datas:',
  ola: 'Oiii',
  nomecolaboradorvinculadoaociclo: 'Nome do colaborador vinculado ao ciclo',
  colaboradorsemhierarquia:
    'Não é possível adicionar esse elegível, colaborador sem hierarquia de liderança definida',
  naopodeexcluirformvinculoaetapa:
    'Não é possível excluir este formulário pois ele está vinculado a uma ou mais etapas de ciclo',
  informacoesdociclo: 'Informações do Ciclo',
  enviodeformulariosporetapa: 'Envio de formulários por etapa',
  etapaspadraodociclo: 'Etapas padrão do ciclo',
  statusetapaspadraodociclo: 'Status das etapas padrão do ciclo',
  selecioneociclo: 'Selecione o ciclo',
  naoliderougerentenaoelegivelciclo:
    'Você não é liderança/gestor e não é elegível a um ciclo',
  naoepossivelresponderpoisexistemetapasnaoconcluidas:
    'Não é possivel responder para este colaborador, pois existem etapas anteriores que não foram concluídas',
  faroldeavaliacoes: 'Farol de Avaliações',
  on_delete_rule_and_parameter_cycle_started:
    'Não é possível excluir este registro pois o ciclo já foi iniciado',
  on_update_rule_and_parameter_cycle_started:
    'Não é possível alterar este registro pois o ciclo já foi iniciado',
  this_record_is_being_used_in_classifications_tree:
    'Não é possível excluir este registro pois o mesmo está sendo usado na árvore de classificações',
  salvareimprimir: 'Salvar e imprimir',
  classificacaocomir:
    'Não é possível alterar os indices de resultado enquanto houver classificações',
  ircicloiniciado:
    'Não é possível alterar os indices de resultado pois o ciclo já foi iniciado',
  selecionelideranca: 'Selecione a liderança',
  selecionestatusdesejavisualizar: 'Selecione quais status deseja visualizar',
  realizacao: 'Realização',
  datalimite: 'Data limite',
  sensibilizacao: 'Sensibilização',
  okforadoprazo: 'OK - Fora do Prazo',
  cycle_information_step_status_not_started: 'Etapa Não Iniciada',
  cycle_information_step_status_progress: 'Etapa em Andamento',
  cycle_information_step_status_complete: 'Etapa Concluída',
  statusetapascustomizadadociclo: 'Etapas Personalizadas do Ciclo',
  inputdemetas: 'Input de metas',
  exercepapeldegestordeciclo: 'Exerce papel de gestor de ciclo de avaliações',
  cycle_information_modal_title_send_step_form:
    'Enviar Formulário - {formName} por participante',
  cycle_information_modal_title_view_step_form: 'Formulário - {formName}',
  cycle_information_sent_to_eligible_success:
    'Formulário enviado para os participantes',
  selecionequaisparticipantesenviaretapa:
    'Selecione para quais participantes esta etapa será enviada',
  selecione_um_ou_mais_participantes: 'Selecione um ou mais participantes',
  meta: 'meta',
  atingimento: 'atingimento',
  informeameta: 'Informe a meta',
  abreviacaoexemplo: 'Ex:',
  calcular: 'calcular',
  avaliacoeseinspecoes: 'Avaliações e Inspeções',
  etapatemtestecomportamental:
    'Nessa etapa é realizado teste de perfil comportamental',
  historicodocolaborador: 'Histórico do colaborador',
  cicloanterior: 'Ciclo anterior',
  consultaravaliacoes: 'Consultar avaliações',
  cicloatual: 'Ciclo atual',
  registrodareuniao: 'Registro da reunião',
  classificacaocicloanterior: 'Classificação do ciclo anterior',
  classificacaosugerida: 'Classificação sugerida',
  classificacaofinal: 'Classificação final',
  comentariodareuniao: 'Comentários da reunião',
  resposta: 'Resposta',
  selecioneaprimeiraavaliacao: 'Selecione a primeira avaliação',
  selecioneasegundaavaliacao: 'Selecione a segunda avaliação',
  compararavaliacoes: 'Comparar avaliações',
  voltarparavisualizacaounica: 'Voltar para visualização única',
  classificacaofinaldociclo: 'Classificação do ciclo',
  informeaclassificacaofinaldociclo: 'Informe a classificação final do ciclo',
  comentariosdareuniao: 'Comentários da reunião',
  participantesdareuniaodegente: 'Participantes da reunião de gente',
  the_cycle_has_ended: 'Este ciclo já foi finalizado',
  cycle_not_found: 'Ciclo não encontrado',
  step_not_found: 'Etapa não encontrada',
  performance_evaluation_soma_dos_pesos_diferente_de_cem:
    'O somatório dos pesos é diferente de 100%',
  performance_evaluation_meta_cadastrada_com_sucesso:
    'Meta cadastrada com sucesso',
  performance_evaluation_meta_atualizada_com_sucesso:
    'Meta atualizada com sucesso',
  performance_evaluation_meta_salva_com_sucesso: 'Meta salva com sucesso',
  performance_evaluation_metas_salvas_com_sucesso: 'Metas salvas com sucesso',
  performance_evaluation_metas_cadastradas_com_sucesso:
    'Metas cadastradas com sucesso',
  performance_evaluation_meta_excluida_com_sucesso: 'Meta excluída com sucesso',
  certezaexcluirmeta: 'Tem certeza que deseja excluir essa meta?',
  placeholderbuscaplanoacao: 'Digite o nome do plano de ação',
  dataaprovacao: 'Data da aprovação',
  aprovarreprovarplano: 'Aprovar/Reprovar Plano',
  cadastrodoplano: 'Cadastro do plano',
  analisedecausa: 'Análise de causa',
  motivodaacao: 'Motivo da ação',
  tipodaacao: 'Tipo de ação',
  porquefazeressaacao: 'Por que vou fazer essa ação?',
  comorealizaracao: 'Como vou realizar essa ação?',
  desenvolvercompetencia: 'Desenvolver a competência',
  potencializarcompetencia: 'Potencializar a competência',
  onthejob: 'On the job',
  interacaonetworking: 'Interação / Networking',
  treinamentoestudo: 'Treinamento / Estudo',
  outros: 'Outros',
  informeotipodeacao: 'Informe o tipo de ação',
  informemotivoacao: 'Informe o motivo de ação',
  adicionarnovaacao: 'Adicionar nova ação',
  aprovarplano: 'Aprovar Plano',
  concluircadastro: 'Concluir cadastro',
  causasbaixodesempenho: 'Causas do baixo desempenho',
  analisecincoporques: 'Análise dos 5 porquês',
  selecioneoformulario: 'Selecione o formulário',
  selecioneasquestoesquedescrevemobaixodesempenho:
    'Selecione as questões que descrevem o baixo desempenho',
  apenassalario: 'Apenas salário',
  mapeamento: 'mapeamento',
  mapeamentoassociado: 'Mapeamento associado',
  potencialbrutoparaaprovacao: 'Potencial bruto mínimo para aprovação (%)',
  textopotencialbrutoaprovacao:
    'Valor mínimo de potencial bruto para que o candidato possa avançar à próxima fase do processo seletivo',
  informepotencialbrutoparaaprovacao:
    'Informe o valor mínimo de potencial bruto para aprovação',
  matchparaaprovacao: 'Match mínimo para aprovação (%)',
  textomatchaprovacao:
    'Valor mínimo de match para que o candidato possa avançar à próxima fase do processo seletivo',
  informematchparaaprovacao: 'Informe o valor mínimo de match para aprovação',
  potencialbruto: 'Potencial bruto',
  match: 'Match',
  cliqueaquiparaverorelatorio: 'Clique aqui para ver o relatório',
  oscincoporques: 'OS 5 PORQUÊS',
  causasdoproblema: 'Causas do problema',
  porque: 'Porquê',
  aprovar: 'Aprovar',
  reprovar: 'Reprovar',
  reprovaracao: 'Reprovar ação',
  aprovaracao: 'Aprovar ação',
  justificativadareprovacao: 'Justificativa da Reprovação',
  itemsalvo: 'Item salvo!',
  concluir: 'Concluir',
  acaoreprovadacomsucesso: 'Ação reprovada com sucesso!!!',
  certezaexcluiracao: 'Tem certeza que deseja excluir esta ação?',
  cadastrecausasnaabacausasdobaixodesempenho:
    'Cadastre as causas na aba "Causas do baixo desempenho"',
  analisedos5porquesconcluida: 'Análise dos 5 Porquês concluída com sucesso!!!',
  digitepelomentos3caracteres:
    'Digite pelo menos 3 caracteres para buscar pelo nome ou documento do colaborador',
  placeholderbuscacolaboradornomeedocumento:
    'Digite o nome ou documento do colaborador',
  acaoreprovada: 'Ação Reprovada',
  historicodeacoesreprovadas: 'Histórico de Ações Reprovadas',
  naohaacoesreprovadas: 'Não há ações reprovadas',
  datadaacao: 'Data da Ação',
  hierarquiapersonalizadanaocriada: 'Hierarquias personalizadas não criadas',
  cargosvinculados: 'Cargos vinculados',
  clientnaopossuinenhumcargo: 'O cliente não possui nenhum dos cargos',
  selecioneumfollow: 'Selecione um follow',
  justificativadareprogramacao: 'Justificativa da reprogramação',
  statusdaacao: 'Status da ação',
  anexar: 'Anexar',
  acaonoprazo: 'No prazo',
  acaoconcluida: 'Concluída',
  acaocancelada: 'Cancelada',
  acaoatrasada: 'Atrasada',
  comentariosdofollow: 'Comentários do follow',
  baixararquivo: 'Baixar arquivo',
  datainicioinferioracriacaodaacao:
    'A data de início não pode ser anterior à data de aprovação do plano',
  recomendavelseraposreuniaodegente:
    'Em envio de etapa por clasificação, recomenda-se que a etapa anterior seja reunião de gente ou posterior',
  vocejarealizouestaetapa:
    'Você já realizou essa etapa. Não é possível responder esse formulário novamente.',
  dadosdodocumento: 'Dados do documento',
  alterarsituacao: 'Alterar situação',
  alterarsituacaoparatodos: 'Alterar situação para todos',
  alterarsituacaodoscandidatos: 'Alterar situação dos candidatos',
  alterarsituacaopara: 'Alterar situação para',
  candidatosselecionados: 'Candidatos selecionados',
  candidatosatualizadoscomsucesso: 'Candidatos atualizados com sucesso!!!',
  erroaoatualizarsituacaodecandidatos:
    'Ocorreu um erro ao tentar atualizar a situção dos candidatos',
  parecerfinaldaetapa: 'Parecer final da etapa',
  monitoramentomin: 'monitoramento',
  informepelomenosumaformadecontato:
    'Informe pelo menos uma forma de contato, email ou telefone',
  emailIsRequired: 'E-mail é um campo obrigatório',
  emailOrPhoneIsRequired:
    'Informe pelo menos uma forma de contato (email ou telefone)',
  emailMustBeAnEmail: 'Formato de e-mail inválido',
  hasProfileNotAnswered: 'Existem campos não preenchidos na aba de Perfil',
  hasExperienceNotAnswered:
    'Existem campos não preenchidos na aba de Experiência',
  dataterminomonitoramento: 'Data de término do monitoramento',
  datafinalmenorouigualatual:
    'A data de término do monitoramento precisa ser menor ou igual à data atual',
  datafinaldomonitoramentoinferioradatadeinicio:
    'A data final do monitoramento não pode ser inferior ou igual à data de inicio do monitoramento',
  colaborators_linked_to_the_unity:
    'Não foi possível atualizar a estrutura, pois há colaboradores ativos vinculados a ela',
  has_movement_after_monitoring_end_date:
    'Não foi possível atualizar a estrutura, pois há movimentações posteriores à data final do monitoramento',
  unity_already_inactive:
    'Não foi possivel atualizar a estrutura, pois a mesma já está inativa',
  has_unity_child_monitored:
    'Não foi possível atualizar a estrutura, existem estruturas filhas ainda monitoradas.',
  unidadenaoemaismonitorada:
    'Não foi possível salvar os dados, a estrutura não é mais monitorada',
  erroaoselecionarestruturanaomaismonitorada:
    'Não foi possivel selecionar a estrutura, pois a mesma não é mais monitorada',
  naoepossivelatualizarpoisaestruturanaoemaismonitorada:
    'Não é possivel atualizar, pois a estrutura não é mais monitorada',
  unityNoLongerMonitored:
    'Não é possivel salvar, pois a estrutura não é mais monitorada',
  oldUnityNoLongerMonitored:
    'Não é possivel salvar, pois a estrutura atual não é mais monitorada',
  newUnityNoLongerMonitored:
    'Não é possivel salvar, pois a estrutura destino não é mais monitorada',
  unidadeinativa: 'Unidade inativa',
  subordinateUnityNoLongerMonitored:
    'Não é possivel salvar, pois a estrutura do subordinado não é mais monitorada',
  leadershipUnityNoLongerMonitored:
    'Não é possivel salvar, pois a estrutura do líder não é mais monitorada',
  oldUnityNoLongerMonitoredOnDelete:
    'Não é possivel excluir, pois a estrutura atual não é mais monitorada',
  newUnityNoLongerMonitoredOnDelete:
    'Não é possivel excluir, pois a estrutura destino não é mais monitorada',
  unitsNoLongerMonitored:
    'Não é possivel salvar, pois alguma das estruturas não é mais monitorada',
  unityNoLongerMonitoredOnDelete:
    'Não é possivel excluir, pois a estrutura não é mais monitorada',
  datainicialinferiora: 'Data inicial inferior a ',
  adicionardata: 'Adicionar data',
  datainferioraminima:
    'Não é possivel salvar, pois existe alguma data inicial abaixo do minimo permitido',
  datafinalnaopreenchida:
    'Não é possivel salvar, pois existe alguma data final de monitoramento não preenchida',
  datafinalsuperiordataincial:
    'Não é possivel salvar, existe algum intervalo com a data final superior à data final',
  intervalossesobrepondo:
    'Não é possivel salvar, existem intervalos de datas de monitoramento se sobrepondo',
  datainicialmenorqueafinaldeanteriores:
    'A data inicial deve ser superior à data final de monitoramentos anteriores',
  opcionalsetelefone: 'opcional, se informar o telefone',
  opcionalseemail: 'opcional, se informar o email',
  erro: 'Erro',
  funcaoobrigatoria: 'Não é possivel salvar, pois a função é obrigatória',
  alteracaoinvalidadocampo:
    'Você não tem permissão para alterar todos os campos',
  categoryCNHNotEmpty: 'Categoria da CNH é um campo obrigatório',
  registerCNHNotEmpty: 'Nº do Registro da CNH é um campo obrigatório',
  firstCNHNotEmpty: 'Data 1ª Habilitação é um campo obrigatório',
  emissionCNHNotEmpty: 'Data Emissão da CNH é um campo obrigatório',
  validityCNHNotEmpty: 'Validade da CNH é um campo obrigatório',
  renachCNHNotEmpty: 'Nº Renach é um campo obrigatório',
  mirrorCNHNotEmpty: 'Nº espelho CNH é um campo obrigatório',
  statusCNHNotEmpty: 'Situação CNH é um campo obrigatório',
  stateCNHNotEmpty: 'UF CNH é um campo obrigatório',
  cityCNHNotEmpty: 'Municipio CNH é um campo obrigatório',
  driverStateNotEmpty: 'UF Naturalidade Condutor é um campo obrigatório',
  driverCountryNotEmpty:
    'País de Nacionalidade Condutor é um campo obrigatório',
  driverCityNotEmpty: 'Município Naturalidade Condutor é um campo obrigatório',
  contabilizarnomodulodeperformance: 'Contabilizar no módulo de performance',
  erroadmissao:
    'Não foi possível inserir o registro. O colaborador foi admitido na data/período informado',
  dismissalTypeIsMandatory:
    'Não foi possível inserir o registro. O tipo de demissão é obrigatório.',
  dismissalReasonIsMandatory:
    'Não foi possível inserir o registro. O motivo de demissão é obrigatório.',
  dismissalReasonIsInvalid:
    'Não foi possível inserir o registro. O motivo de demissão é inválido',
  dismissalReasonJustificationIsMandatory:
    'Não foi possível inserir o registro. A justificativa de demissão é obrigatória.',
  resignationDateIsAfterCurrentDate:
    'Não foi possível inserir o registro. A data de demissão não pode ser superior à atual.',
  resignationDateIsBeforeAdmissionDate:
    'Não foi possível inserir o registro. A data de demissão não pode ser inferior à data de início da prestação de serviços do colaborador.',
  permitegerarocorrenciasnopreenchimento:
    'Permite gerar ocorrências no preenchimento',
  cadastrarumaocorrencia: 'Cadastrar uma ocorrência',
  occurrenceform: 'Formulário de Ocorrência',
  indicadorcorrespondentenomodulodeperformance:
    'Indicador correspondente no módulo de performance',
  selecioneoindicador: 'Selecione o indicador',
  datainicioprestacaoservicos: 'Data de início da prestação de serviços',
  informedatainicioprestacaoservicos:
    'Informe a data de início da prestação de serviço',
  datainicioprestacaoservicosinvalida:
    'A data de início da prestação do serviço é inválida.',
  datainicioprestacaoservicosinferioradmissao:
    'A data de início da prestação de serviço não pode ser inferior à data da admissão',
  hamovimentacoesanterioresadatainicioprestacaoservicos:
    'Não pode haver movimentações anteriores à data de início da prestação de serviços',
  campoinvalido: 'Campo inválido',
  isSameOrBeforeNow: 'A data não pode ser superior à data atual',
  datainicioprestacaoservicosemaiorquehoje:
    'A data de início da prestação de serviços precisa ser menor ou igual à data atual',
  skills_acquisition_process: 'Processo de aquisição de habilidades',
  days_after_admission_or_rise: 'Dias após admissão ou promoção',
  informediasaposadmissaooupromocaoiniciaociclo:
    'Informe o limite de dias após admissão ou promoção para participar do ciclo',
  feriascadastradas:
    'Não é possivel salvar, pois existem férias cadastradas para o colaborador',
  planejamentoemmassajaenviado:
    'O planejamento em massa pode ser enviado apenas uma vez',
  consultarenvios: 'Consultar envios',
  digiteumnomeoudocumento: 'Digite um nome ou documento',
  tipodeenviodoplanejamento: 'Tipo de envio',
  todos: 'todos',
  planejamentoenviadoemmassa: 'Em massa',
  planejamentoenviadoseparadamente: 'Individual',
  selecionetipodeenvio: 'Selecione o tipo de envio',
  horaenvio: 'Hora envio',
  relatoriodeenvios: 'Relatório de envios',
  vagaduplicada: 'Vaga duplicada com sucesso!!!',
  is_not_possible_to_change_the_parent: 'Etapa anterior inválida',
  cadastros: 'Cadastros',
  academy: 'Academy',
  replanejamento: 'Replanejamento',
  treinamentosagendados: 'Treinamentos agendados',
  listadepresenca: 'Lista de presença',
  calendario: 'Calendário',
  selecioneonivelnagrade: 'Selecione o nível na grade',
  dataprevista: 'Data prevista',
  naoobrigatorio: 'Não obrigatório',
  obrigatoriedade: 'Obrigatoriedade',
  aderenciageral: 'Aderência geral',
  aderenciaprincipal: 'Aderência principal',
  vocepodeselecionaronivelnoqualotreinamentoestaalocadoparafiltraralistadetreinamentos:
    'Você pode selecionar o nível no qual o treinamento está alocado para filtrar a lista de treinamentos.',
  permitircadastrodetreinamentonestenivel:
    'Permitir cadastro de treinamentos neste nível',
  permitirtreinamentosatralados:
    'Permite que sejam atrelados treinamentos neste nível da grade',
  jaexistetreinamentosnestenivel:
    'Não é possivel desabilitar o cadastro de treinamentos neste nivel, pois já existem treinamentos atrelados',
  responsaveleinstrutor: 'Responsável/Instrutor',
  instrutor: 'Instrutor',
  calendariodetreinamentos: 'CALENDÁRIO DE TREINAMENTOS',
  exportar: 'Exportar',
  buscarpornome: 'Buscar por nome',
  buscarpordescricao: 'Buscar por descrição',
  nomedotreinamento: 'Nome do Treinamento',
  dataprevistadeinicio: 'Data prevista de início',
  datadetermino: 'Data de término',
  statusdotreinamento: 'Status do Treinamento',
  datadefinal: 'Data de Final',
  buscarpornomeoudescricao: 'Buscar por nome',
  exercepapeldegestornaareadegente: 'Exerce papel de gestor da área de Gente',
  visibilidadegestorformulario: 'Visibilidade gestor formulário',
  concluido: 'Concluído',
  agendado: 'Agendado',
  emandamento: 'Em andamento',
  atrasado: 'Atrasado',
  anoreferencia: 'Ano referência',
  campocustomizadoinvalido: 'Algum campo customizado está inválido',
  ano: 'ano',
  anomax: 'Ano',
  datadotreinamento: 'Data do Treinamento',
  presenca: 'Presença',
  mesdotreinamento: 'Mês do treinamento',
  anodotreinamento: 'Ano do treinamento',
  iniciodaprestacaodeservicos: 'Início da prestação de serviços',
  atraso: 'Atraso',
  previsto: 'Previsto',
  realizadoem: 'Realizado em',
  selecioneumtreinamento: 'Selecione um treinamento',
  presente: 'Presente',
  visualizarmotivos: 'Visualizar motivos',
  deletar: 'Deletar',
  placeholderbuscanometreinamento: 'Buscar pelo nome do treinamento',
  placeholderbuscanomeoudescricaotreinamento: 'Buscar pelo nome ou objetivo do treinamento',
  prorrogado: 'Prorrogado',
  marcacaomanual: 'Marcação manual',
  pendente: 'Pendente',
  faltou: 'Faltou',
  valordotreinamento: 'Valor do treinamento',
  palavraschaverelacionadasaotreinamento:
    'Palavras-chave relacionadas ao treinamento',
  planejamentorecorrente: 'Planejamento recorrente',
  marqueessaopcaoparatreinamentosqueacontecemdeformacontinua:
    'Marque essa opção para treinamentos que acontecem de forma contínua',
  otreinamentoeobrigatorio: 'O treinamento é obrigatório ?',
  tipodevinculo: 'Tipo de vínculo',
  tipodecargo: 'Tipo de cargo',
  quaiskpismetricassaoimpactadospelarealizacaodestetreinamento:
    'Quais kpi´s/métricas são impactadas pela realização deste treinamento.',
  valormonetariorecursofinanceiroqueseraaplicadonestetreinamento:
    'Valor monetário, recurso financeiro que será aplicado neste treinamento.',
  objetivodotreinamento: 'Objetivo do treinamento',
  selecioneocertificado: 'Selecione o certificado',
  quaisfuncoesparticiparaodotreinamento:
    'Quais funções participarão do treinamento',
  quaiscolaboradoresparticiparaodotreinamento:
    'Quais colaboradores participarão do treinamento?',
  diasaposiniciodaprestacaodeservicos:
    'Dias após início da prestação de serviços',
  quantidadedeagendamentos: 'Quantidade de agendamentos',
  admitidosapartirde: 'Admitidos a partir de',
  insiraaquantidadededias: 'Insira a quantidade de dias',
  campoparadatadecortedoscolaboradoreselegiveisaotreinamento:
    'Campo para data de corte dos colaboradores elegíveis ao treinamento',
  execucao: 'Execução',
  ocamponomedotreinamentodevecontercaracteresvalidos:
    'O campo nome do treinamento deve conter caracteres válidos',
  gradessequenciascriadasporadm:
    'Não é possivel excluir, pois existem grades sequenciais criados por um administrador',
  jaexisteumtreinamentocomonomeinformado:
    'Já existe um trainamento com o nome informado',
  rascunho: 'Rascunho',
  vocenaotempermissaopararealizaressaoperacao:
    'Você não tem permissão para realizar essa operação',
  digitenomeparanovotreinamento: 'Digite o nome do novo treinamento',
  nomeparanovotreinamento: 'Nome para o novo treinamento',
  treinamentoduplicadosucesso: 'Treinamento duplicado com sucesso!!!',
  naopossivelduplicarpoisonomejaeusadoemoutrotreinamento:
    'Não é possível duplicar, pois o nome já é usado em um outro treinamento',
  treinamentocopiadosucesso: 'Treinamento copiado com sucesso!!!',
  naoepossivelcopiarpoisnaoexistemessesvinculosnoclientedestino:
    'Não é possivel copiar, pois não existe os "vínculos" no cliente destino',
  naoepossivelcopiarpoisnaoexistemessecargosnoclientedestino:
    'Não é possivel copiar, pois não existe os "cargos" no cliente destino',
  naoepossivelcopiarpoisnaoexisteagradenoclientedestino:
    'Não é possivel copiar, pois não existe a "grade" no cliente destino',
  naoepossivelsalvarouatualizarpoisociclojafinalizou:
    'Não é possível salvar ou atualizar, pois o ciclo já foi finalizado',
  naoepossivelexcluirpoisociclojafinalizou:
    'Não é possível excluir, pois o ciclo já foi finalizado',
  documentosnaoanalisados: 'Documentos não analisados',
  relatoriodocumentosnaoanalizados: 'Relatório de documentos não analisados',
  gerarrelatorio: 'Gerar relatório',
  erroaogerarrelatorio: 'Erro ao gerar o relatório',
  competenciaate: 'Competência até',
  informedatacompetenciaate: 'Informe uma data de compentência em até 15 dias.',
  relatorio: 'Relatório',
  relatoriosrecentes: 'Relatórios recentes',
  seurelatorioficoupronto: 'O seu relatório está pronto',
  cliquenobotaodownloadparabaixarorelatorio:
    'Clique no botão "baixar" para fazer o download do seu relatório.',
  download: 'baixar',
  dowloademprogresso: 'Em progresso',
  downloadfinalizado: 'Completo',
  selecioneaquantidadedeagendamentos: 'Selecione a quantidade de agendamentos',
  selecioneumtipodeagendamento: 'Selecione um tipo de agendamento',
  selecioneumoumaistiposdevinculo: 'Selecione um ou mais tipos de vínculo',
  ocamponaopodetermaisque500caracteres:
    'O campo nome do treinamento não pode ultrapassar 500 caracteres',
  selecioneoanoreferencia: 'Selecione o ano de referência',
  selecioneoagendamento: 'Selecione o agendamento',
  selecioneaestrutura: 'Selecione a estrutura',
  selecioneostatus: 'Selecione o status',
  selecioneaaderenciageral: 'Selecione a aderência geral',
  selecioneaaderenciaprincial: 'Selecione a aderência principal',
  selecioneaobrigatoriedade: 'Selecione a obrigatoriedade',
  erroaotentaratualizarpoisexistetreinamentos:
    'Erro ao tentar atualizar, já existem treinamentos cadastrados neste nível da grade',
  tempodeduracaodoagendamentoemminutos:
    'Tempo de duração do agendamento(em min.)',
  vincularcheckderetencao: 'Vincular check de retenção',
  indicarinstrutor: 'Indicar instrutor',
  vincularavaliacaodereacao: 'Vincular avaliação de reação',
  exportarhabilitados: 'Exportar HABILITADOS',
  datadeprorrogacao: 'Data de prorrogação',
  visualizar: 'Visualizar',
  cliqueparaselecionarumformulario: 'Clique para selecionar um formulário',
  cliqueparaselecionarumaavaliacao: 'Clique para selecionar uma avaliação',
  digiteonomedoinstrutor: 'Digite o nome do instrutor',
  atribuirusuariosaestrutura: 'Atribuir usuários à estrutura.',
  atribuirusuarios: 'Atribuir usuários',
  selecioneotipodeusuario: 'Selecione o tipo de usuário',
  atribuir: 'Atribuir',
  scheduledtrainingsalert1:
    'Os campos preenchidos nesta tela filtram as opções de treinamento exibidas aqui.',
  scheduledtrainingsalert2:
    'Caso não esteja encontrando algum treinamento certifique-se que ele corresponde aos filtros aplicados',
  totaldecolaboradores: 'Total de colaboradores',
  datainicialmaiorouigualquefinal:
    'A data inicial deve ser menor que a data final',
  datafinaldevesermaiorqueinicial:
    'A data final deve ser maior que a data inicial',
  vincularformulario: 'Vincular formulário',
  vincularavaliacao: 'Vincular avaliação',
  naoepossivelatualizarpoisotreinamentojainiciou:
    'Não é possível atualizar, pois o treinamento já iniciou',
  tipodeagendamento: 'Tipo de agendamento',
  naoepossivelatualizarumagendamentojainiciado:
    'Não é possível atualizar um agendamento já iniciado',
  erroaotentaratualizarpoisexistetreinamentosnosfilhos:
    'Erro ao tentar excluir, pois já existem treinamentos cadastrados neste nível da grade ou nos niveis sequenciais',
  naoepossivelsalvarpoisjaexisteumtreinamentocomestenome:
    'Erro ao tentar salvar, pois já existe um treinamento cadastrado com este nome',
  dataagendamentoinferioraantecessor:
    'A data de início deve ser inferior ou igual à data de início de agendamentos posteriores',
  dataagendamentosuperioraposterior:
    'A data de início do agendamento não pode ser inferior à data de início do agendamento anterior',
  certezaalterarpresenca:
    'Tem certeza que deseja alterar a presença dessa pessoa?',
  informeadataprevistadetermino: 'Informe a data prevista de término',
  filtrartabela: 'Filtrar tabela',
  informeanovadatadetermino: 'Informe a nova data de término',
  novadatadetermino: 'Nova data de término',
  nenhumtreinamentofoiencontrado: 'Nenhum treinamento foi encontrado',
  oagendamentoaindanaoterminou: 'O agendamento ainda não terminou',
  naoepossiveleditardatalimiteatingida:
    'Não é possível editar, data limite atingida',
  treinamentosdisponiveis: 'Treinamentos Disponíveis',
  datafinalizacao: 'Data de Finalização',
  datafinalmaioratualtrainamento:
    'A data prevista de término não pode ser inferior à data atual',
  estecolaboradornaopodeserremovido: 'Este colaborador não pode ser removido!',
  vinculadoaumcolaboradordeoutraunidade:
    'Não é possivel remover, este item está vinculado a colaboradores de outra unidade',
  dataderealizacao: 'Data de realização',
  uploaddalistadepresenca: 'Upload da lista de presença',
  anexarlistadepresençadotreinamento: 'Anexar lista de presença do treinamento',
  quaiscolaboradoresparticiparamdotreinamento:
    'Quais colaboradores participaram do treinamento?',
  arquivosanteriores: 'Arquivos anteriores',
  visualizarcolaboradores: 'Visualizar colaboradores',
  listadecolaboradores: 'Lista de colaboradores',
  certezaexcluirlistadepresenca:
    'Tem certeza que deseja excluir essa lista de presença?',
  planejamentopendente: 'Planejamento pendente',
  agendamentodisponivelparainicioimediato:
    'Agendamento disponível para início imediato.',
  parabensvocejaconcluiuesteagendamento:
    'Parabéns, você já concluiu este agendamento!',
  agendamentorealizadodeformapresencial:
    'Agendamento realizado de forma presencial.',
  adatadeterminodesteagendamentojapassou:
    'A data de término deste agendamento já passou.',
  agendamentoindisponivelaguardeadatadeinicio:
    'Agendamento indisponível, aguarde a data de início.',
  nenhumcolaboradorencontrado: 'Nenhum colaborador encontrado',
  todososxagendamentosforamconcluidos: 'Todos os {quantity} agendamentos foram concluídos',
  voceaindanaorealizouxagendamentos: 'Você ainda não realizou {quantity} agendamentos',
  voceaindanaorealizouxagendamento: 'Você ainda não realizou {quantity} agendamento',
  informeotempodeduracaoemmin: 'Informe o tempo de duração em minutos',
  paisdenacionalidadedocondutor: 'País de Nacionalidade do Condutor',
  categoriadacnh: 'Categoria da CNH',
  numeroderegistrodacnh: 'N° do Registro da CNH',
  dataprimeirahabilitacao: 'Data 1ª Habilitação',
  dataemissaocnh: 'Data Emissão da CNH',
  validadedacnh: 'Validade da CNH',
  situacaocnh: 'Situação CNH',
  numeroespelhocnh: 'Nº espelho CNH',
  numerorenach: 'N° Renach',
  municipiocnh: 'Município CNH',
  ufdenaturalidadecondutor: 'UF Naturalidade Condutor',
  ufcnh: 'UF CNH',
  selecioneasituacao: 'Selecione a situação',
  municipionaturalidadecondutor: 'Município Naturalidade Condutor',
  tipodelicenca: 'Tipo de Licença',
  cnh: 'CNH',
  licencadeconducaoparaestrangeiros: 'Licença de Condução para Estrangeiros',
  validadelicencadeconducao: 'Validade Licença de Condução',
  validadepermissaodeconducaonobrasil:
    'Validade Permissão de Condução no Brasil',
  informevalidadelicencadeconducao: 'Informe a validade da licença de condução',
  informevalidadepermissaodeconducaonobrasil:
    'Informe a validade da permissão de condução no Brasil',
  datadevalidadedalicencadeconducaodocolaboradornoseupaisdeorigem:
    'Data de validade da licença de condução do colaborador no seu país de origem.',
  datalimitequeestecolaboradorpoderaconduzirnobrasilusandoapermissaodeconducaosemacnh:
    'Data limite que este colaborador poderá conduzir no Brasil usando a Permissão de Condução, sem a CNH.',
  checkderetencao: 'Check de retenção',
  avaliacaodereacao: 'Avaliação de reação',
  avisoprazoparaconcluiragendamento:
    'Atenção - O prazo máximo para a conclusão deste agendamento é: {date}, conclua-o até esta data para evitar possíveis inconvenientes com a perda do prazo.',
  finalizaravaliacao: 'Finalizar avaliação',
  descreveroindicador: 'Descrever o indicador',
  descrevaoobjetivodotreinamento: 'Descreva o objetivo do treinamento',
  nenhumresultadoencontrado: 'Nenhum resultado encontrado',
  nenhumaopcaodisponivel: 'Nenhuma opção disponível',
  semsubopcoes: 'Sem sub-opções',
  pressioneenterparaselecionar: 'Pressione enter para selecionar',
  presioneenterpararemover: 'Pressione enter para remover',
  selecionado: 'Selecionado',
  resultadodaavaliacaopontos: 'Resultado da avaliação: {score} pontos',
  vocejarespondeuestaavaliacao: 'Você já respondeu esta avaliação',
  avisoagendamentofeitopresencial:
    'Atenção - O check de retenção deste agendamento foi realizado de forma presencial. Você pode acessar o conteúdo abaixo porém não é possível enviar respostas.',
  datadeterminoagendamento: 'Data de término do agendamento',
  naoepossivelcopiarpoisonomejaeusadoemumtreinamentonoclientedestino:
    'Não é possível copiar, pois o nome já é usado em um treinamento no cliente destino',
  seuusuarionaopodeeditarestecampo: 'Seu usuario não pode editar este campo',
  voceconcluiuxdeyagendamentos:
    'Você concluiu {completed} de {total} agendamentos',
  na: 'N/A',
  prazofinalparaconclusaodoagendamento:
    'Prazo final para conclusão do agendamento',
  agendamentodisponivelporemforadoprazo:
    'Agendamento disponível porém fora do prazo.',
  onomeinformadoparaagradenaoevalido:
    'O nome informado para a grade não é válido',
  formcomagendamentosvinculados:
    'Não é possivel excluir, pois o formulário está vinculado à um ou mais agendamentos.',
  avaliacaocomagendamentosvinculados:
    'Não é possivel excluir, pois a avaliação está vinculada à um ou mais agendamentos.',
  copiadeformulario: 'Cópia de formulário',
  copiadeavaliacao: 'Cópia de avaliação',
  onomeinformadojaeutilizadoemoutragrade:
    'O nome informado já é utilizado em outra grade.',
  otreinamentocomstatusrascunhonaopodesermanipulado:
    'O treinamento com status rascunho não pode ser manipulado',
  naoepossiveleditarformulariopoisjaexisteresposta:
    'Não é possivel editar, pois já existe resposta cadastrada para este formulário',
  naoepossiveleditaravaliacaopoisjaexisteresposta:
    'Não é possível editar, pois já existe resposta cadastrada para esta avaliação',
  nenhumclienteencontrado: 'Nenhum cliente encontrado',
  naoepossiveldeletarpoiscontemrespostasaosformulariosdestetreinamento:
    'Não é possível deletar, pois contém respostas aos formulários deste treinamento',
  dataprevistadeterminoinvalida: 'Data prevista de término inválida',
  esteagendamentonaoestamaisdisponivel:
    'Este agendamento não está mais disponível',
  erroessenomejaestaemuso: 'Esse nome já está em uso',
  formularioduplicado: 'Formulário duplicado com sucesso!!!',
  erroaoduplicarregistro: 'Erro ao duplicar registros',
  avaliacaoduplicada: 'Avaliação duplicada com sucesso!!!',
  naoepossiveldeletarotreinamentopoiscontemrespostasasavaliacoesdomesmo:
    'Não é possível deletar o treinamento pois contém respostas às avaliações do mesmo',
  naoepossiveldeletarotreinamentopoisexisterespostasdeavaliacoeseformulariorespondidos:
    'Não é possível deletar o treinamento pois existem respostas de avaliações e formulário respondidos',
  vocedeveselecionaronivelnoqualotreinamentoseraalocado:
    'Você deve selecionar o nível no qual o treinamento será alocado',
  consultaprontuariodocondutor: 'Consulta Prontuário do Condutor',
  informeodocumentoparaconsultar: 'Informe o documento para consulta',
  consultarprontuario: 'Consultar prontuário',
  informacoesdocolaborador: 'Informações do colaborador',
  informacoesdocondutor: 'Informações do condutor',
  pontuacaodeprontuario: 'Pontuação de prontuário',
  motivosdobloqueio: 'Motivos do bloqueio',
  datadobloqueio: 'Data do bloqueio',
  bloqueado: 'Bloqueado',
  endereco: 'Endereço',
  vencimentodacnh: 'Vencimento da CNH',
  previsaodedesbloqueio: 'Previsão de desbloqueio',
  datavencimentogr: 'Data de vencimento GR',
  condutornaoencontradocomestedocumento:
    'Condutor não encontrado com este documento',
  grupodeanalise: 'Grupo de análise',
  selecioneumgrupodeanalise: 'Selecione um grupo de análise',
  selecioneumrelatorio: 'Selecione um relatório',
  selecioneumaanalise: 'Selecione uma análise',
  naofoipossivelcarregarodashboard: 'Não foi possível carregar o dashboard, tente novamente!',
  verdashboardemtelacheia: 'Ver dashboard em tela cheia',
  exportarparapdf: 'Exportar dados para PDF',
  integracombeedoo: 'Integra com Beedoo?',
  documentoexcluido: '(Documento excluído)',
  limparfiltros: 'Limpar filtros',
  unidadeinvalida: 'Unidade inválida',
  atualizardadoscadastrais: 'Atualizar dados cadastrais',
  atualizacaodedadosdocolaborador: 'Atualização de dados do colaborador',
  cancelaratualizacaodedados: 'Cancelar atualização de dados',
  mensagemconfirmacaocancelardadoscadastrais: 'Ao confirmar o cancelamento desta ação, todas as edições feitas serão descartadas, e os campos voltarão a exibir as informações originais.',
  atualizacaodosdados: 'atualização dos dados',
  desejarealmentecancelaraatualizacaodosdados: 'Deseja realmente cancelar a',
  confirmarcancelamento: 'Confirmar cancelamento',
  continuareditando: 'Continuar editando',
  atualizacaodedadospessoaisconcluida: 'Atualização de dados pessoais concluída',
  atualizacaodedadossociaisconcluida: 'Atualização de dados sociais concluída',
  atualizacaodedadosfuncionaisconcluida: 'Atualização de dados funcionais concluída',
  conteudomodalsalvardadoscadastrais1: 'Os dados na aba',
  conteudomodalsalvardadoscadastrais2: 'foram atualizados com sucesso.',
  perguntamodalsalvardadoscadastrais: 'O que deseja fazer agora?',
  voltarparaoprontuario: 'Voltar para o prontuário',
  editardadosfuncionais: 'Editar dados funcionais',
  editardadossociais: 'Editar dados sociais',
  seuusuarionaopossuiprivilegioparaeditarestecampo: 'Seu usuário não possui privilégio para editar este campo.',
  naohadadosfuncionaisparaseremeditados: 'Não há dados funcionais para serem editados',
  modelosdeplano: 'Modelos de plano',
  meusplanos: 'Meus planos',
  compartilhadoscomigo: 'Compartilhados comigo',
  planospublicos: 'Planos públicos',
  minhasacoes: 'Minhas ações',
  '+novoplanodeacao': '+ Novo plano de ação',
  placeholderbuscarpelonomedoplano: 'Buscar pelo nome do plano de ação',
  placeholderbuscarpelonomedoplanoouproprietario: 'Buscar pelo nome do proprietário ou nome do plano de ação',
  placeholderbuscarpelonomedoplanoouacao: 'Buscar pelo nome da ação ou do plano de ação',
  criarplanodeacao: 'Criar plano de ação',
  definaseusobjetivosidentifiqueasetapasestabelecaprazosatribuaresponsaveis:
    'Defina seus objetivos, identifique as etapas, estabeleça prazos, atribua responsáveis.',
  dicasejaclaroeespecificoparafacilitaroacompanhamentodoprogresso: 'Dica: Seja claro e específico para facilitar o acompanhamento do progresso.',
  nomedoplanodeacao: 'Nome do plano de ação',
  placeholdernomeplanodeacao: 'Ex: Plano de ação - Turnover',
  acesso: 'Acesso',
  selecioneomodelodoplanodeacao: 'Selecione o modelo do plano de ação',
  asacoesdoplanoexigemaprovacao: 'As ações do plano exigem aprovação.',
  obrigatoriedadedepreencherevidenciasnasacoes: 'Obrigatoriedade de preencher evidências nas ações.',
  selecioneotipodeacesso: 'Selecione o tipo de acesso',
  visualizaracoes: 'Visualizar ações',
  unidadenaoaceitamovimentacaonessenivel: 'A unidade não aceita movimentações nesse nível',
  permitircadastrodemovimentacoesnestenivel: 'Permitir cadastro de movimentações neste nível',
  permitequesejamatreladoscolaboradoresnesteniveldaestrutura: 'Permite que sejam atrelados colaboradores neste nível da estrutura',
  colaborators_linked_to_the_unity_for_no_allow_movements: 'Não é possível bloquear este nível de estrutura. Possui colaboradores alocados neste nível e deverão ser movimentados antes de bloquear a estrutura',
  unityNotAllowMovements: 'A unidade não permite movimentações',
  numerocelularinvalido: 'Número de celular inválido',
  '+novomodelodeplano': '+ Novo modelo de plano',
  placeholderbuscarpelonomedomodelo: 'Buscar pelo nome do modelo',
  criarplanocomestemodelo: 'Criar plano com este modelo',
  certezacopiartreinamento: 'Tem certeza que deseja copiar esse treinamento?',
  existemusuarioscadastradoscomestetipo: 'Não é possivel excluir, pois já existem usuários cadastrados com este tipo',
  isFormManagerVisibilityHelpText: 'Impactará nos formulários cadastrados em "Avaliações e Inspeções" e "Acompanhamento e Retenção"',
  admitido: 'Admitido',
  renomearplano: 'Renomear plano',
  titulodoplano: 'Título do plano',
  nomeieoplano: 'Nomeie o plano',
  exclusaodeacao: 'Exclusão de ação',
  exclusaodeplanodeacao: 'Exclusão de plano de ação',
  desejaexcluirestaacao: '\nDeseja realmente <strong style="color: red;">excluir esta ação</strong>?',
  veracoes: 'Ver ações',
  oquelabel: 'O quê?',
  porquelabel: 'Por quê?',
  ondelabel: 'Onde?',
  quemlabel: 'Quem?',
  quandolabel: 'Quando?',
  comolabel: 'Como?',
  emprogresso: 'Em progresso',
  naoiniciado: 'Não iniciado',
  atrasada: 'Atrasada',
  proprietario: 'Proprietário',
  gerenciarusuarios: 'Gerenciar usuários',
  planopublico: 'Plano público',
  planoprivado: 'Plano privado',
  encerrarplano: 'Encerrar plano',
  oqueestasendofeitoouserafeito: 'O que está sendo feito ou será feito?',
  restantedecaracteres: 'Restam {count} caracteres.',
  porqueissoestasendofeito: 'Por que isso está sendo feito?',
  ondeissoestaacontecendo: 'Onde isso está acontecendo?',
  quemestaenvolvido: 'Quem está envolvido?',
  quandoissoacontecera: 'Quando isso acontecerá?',
  comoissoserarealizado: 'Como isso será realizado?',
  adicionarcoluna: 'Adicionar coluna',
  publico: 'Público',
  privado: 'Privado',
  sovocepodeveresseplanovocepodecompartilharelecomusuariosmaistarde: 'Só você pode ver esse plano. Você pode compartilhar ele com\nusuários individualmente mais tarde.',
  todospodemverseuplanovocepoderestringiroacessoausuariosmaistarde: 'Todos podem ver o seu plano. Quando um plano de ação é do\ntipo público, qualquer usuário pode editá-lo.',
  digiteonomedomodelo: 'Digite o nome do modelo',
  modelosdeplanopersonalizado: 'Modelos de plano personalizado',
  editarnomedoplano: 'Editar nome do plano',
  tipodecoluna: 'Tipo de coluna',
  monetario: 'Monetário',
  visualizaroplanocompleto: 'Visualizar o plano completo',
  naoiniciada: 'Não iniciada',
  acoesaguardandoaprovacao: 'Ações aguardando aprovação ( {count} )',
  acoesconcluidas: 'Ações concluídas ( {count} )',
  acoesreprovadas: 'Ações reprovadas ( {count} )',
  concluiracao: 'Concluir ação',
  adicionarevidencia: 'Adicionar evidência',
  adicioneaevidencia: 'Adicione a evidência',
  procurararquivo: 'Procurar arquivo',
  reprogramar: 'Reprogramar',
  atribuircolaborador: 'Atribuir colaborador',
  reprogramaracao: 'Reprogramar ação',
  selecioneanovadata: 'Selecione a nova data',
  novonomedoplano: 'Novo nome do plano',
  aposaexclusaotodohistoricodemovimentacoesassociadoaesteplano: 'Após a exclusão, todo o histórico de movimentações associado as ações<br> deste plano será perdido, e o plano deixará de ser visível para os<br>colaboradores aos quais foi atribuída.',
  onomedoplanoduplicadonaopodeserigualaooriginal: 'O nome do plano duplicado não pode ser igual ao original',
  acaoadicionadanaabaacoesaguardandoaprovacao: 'Ação adicionada na aba: <span style="color: red">Ações aguardando aprovação.</span>',
  enviarnotificacao: 'Enviar notificação',
  justifiqueareprovacao: 'Justifique a reprovação',
  informeomotivodareprovacaodestaacao: 'Informe o motivo da reprovação da ação',
  digiteouselecioneonome: 'Digite ou selecione o nome',
  salvaracao: 'Salvar ação',
  evidenciadaacao: 'Evidência da ação',
  datadeupload: 'Data de upload',
  editouocampo: '<strong>{user}</strong> editou o campo "<strong>{field}</strong>"',
  as: 'às',
  atualizouostatuspara: '<strong>{user}</strong> atualizou o status para <strong>{status}</strong>',
  adicionaracao: 'Adicionar ação',
  certezaexcluirmodelodeplano: 'Tem certeza que deseja excluir este modelo de plano?',
  exclusaodemodelodeplano: 'Exclusão de modelo de plano',
  nomedomodelo: 'Nome do modelo',
  nomedomodeloplaceholder: 'Ex: Modelo de plano de ação - Turnover',
  salvarmodelo: 'Salvar modelo',
  salvarcomomodelo: 'Salvar como modelo',
  copiarparameusplanos: 'Copiar para meus planos',
  plano: 'Plano',
  caracteresrestantes: '{counter} caracteres restantes',
  adicionarnovacoluna: 'Adicionar nova coluna',
  nomedacoluna: 'Nome da coluna',
  nomeieacoluna: 'Nomeie a coluna',
  textodeapoio: 'Texto de apoio',
  textoopcionalparaexplicarafinalidadedacoluna: 'Texto opcional para explicar a finalidade da coluna',
  tipodacoluna: 'Tipo da coluna',
  selecioneotipodacoluna: 'Selecione o tipo da coluna',
  colunadotipotexto: 'Texto',
  colunadotipodata: 'Data',
  colunadotipomoeda: 'Monetário',
  planejarasdemandasdosemestre: 'Planejar as demandas do semestre',
  escrevaaqui: 'Escreva aqui',
  definirdata: 'Definir data',
  R$: 'R$: 0000,00',
  informeonovonomedoplano: 'Informe o novo nome do plano',
  ocorreuumerroporfavortentenovamente: 'Ocorreu um erro, por favor tente novamente',
  jaexisteumregistrocomestenome: 'Já existe um registro com este nome',
  nomearnovomodelo: 'Nomear novo modelo',
  nomeieomodelo: 'Nomeie o modelo',
  aosalvaresteplanocomomodelovocepoderaacessaloapartirdaabameusmodelos: 'Ao salvar este plano como modelo você poderá acessá-lo a partir da aba<br><span class="greenWord">Meus modelos</span>.',
  podevisualizar: 'Pode visualizar',
  adicionarusuario: 'Adicionar usuário',
  leitura: 'Leitura',
  podeeditar: 'Pode editar',
  informeonomedousuario: 'Informe o nome do usuário',
  quandoissoocorrera: 'Quando isso ocorrerá?',
  quantoissocustara: 'Quanto isso custará?',
  quantocustalabel: 'Quanto custa?',
  umavezexcluidoocolaboradorperdeavisualizacaoeoacessoatodososrecursosdesteplano: 'Uma vez excluído, o colaborador perde a visualização e o acesso a todos os\nrecursos deste plano de ação, se o colaborador estiver atribuído em uma ou\nmais ações dentro do plano será automaticamente removido delas.',
  exclusaodeusuarios: 'Exclusão de usuários',
  desejarealmenteexcluiresteusuario: 'Deseja realmente <strong>excluir este usuário</strong>?',
  desejarealmente: 'Deseja realmente',
  excluiresseplanodeacao: 'excluir este plano',
  editouaacao: '<strong>{name}</strong> editou a ação',
  planodeacaoduplicadocomsucesso: 'Plano de ação duplicado com sucesso!!!',
  planodeacaoexcluidocomsucesso: 'Plano de ação excluído com sucesso!!!',
  planodeacaocopiadocomsucesso: 'Plano de ação copiado com sucesso!!!',
  planodeacaosalvocomomodelo: 'Plano de ação salvo como modelo!!!',
  acaoadicionada: 'Ação adicionada',
  acaoadicionadanaaba: 'Ação adicionada na aba',
  acoesaguardandoaprovaçao: 'Ações aguardando aprovação',
  erroaocopiarplanodeacao: 'Erro ao copiar plano de ação',
  erroaosalvarcomomodelo: 'Erro ao salvar plano de ação como modelo',
  acaosalvacomsucesso: 'Ação salva com sucesso!!!',
  erroaosalvaracao: 'Erro ao salvar ação',
  evidenciaexcluida: 'Evidência excluída!!!',
  erroaoexluirevidencia: 'Erro ao excluir evidência',
  acaoexcluidacomsucesso: 'Ação excluída com sucesso!!!',
  erroaoexcluiracao: 'Erro ao excluir ação',
  erroaoexcluirplanodeacao: 'Erro ao excluir plano de ação',
  erroaoduplicaplanodeacao: 'Erro ao duplicar plano de ação',
  notificacaoenviadacomsucesso: 'Notificação enviada com sucesso!',
  IN_PROGRESS: 'Em andamento',
  NOT_STARTED: 'Não iniciada',
  naohaacoescadastradas: 'Não há ações cadastradas',
  semdadosparaexportar: 'Sem dados para exportar',
  verjustificativa: 'Ver justificativa',
  mensagemencerramentoplanodeacao: 'Uma vez encerrado o plano, não será possível adicionar, aprovar, concluir ou reprovar ações, todos os campos serão bloqueados para edição, sendo possível somente visualizar o conteúdo do plano.',
  desejaencerraroplanodeacao: '\nDeseja realmente <span>Encerrar o plano</span>?',
  naoencerrarplano: 'Não encerrar plano',
  encerramentodeplano: 'Encerramento de plano',
  reativaroplano: 'Reativar plano',
  nomesalvocomsucesso: 'Nome do plano salvo com sucesso',
  voltarparaemandamento: 'Voltar para em andamento',
  voltarparanaoiniciada: 'Voltar para não iniciada',
  jaexistecolunacomestenome: 'Já existe uma coluna com este nome',
  modeloplanoacaoexcluidocomsucesso: 'O modelo de plano de ação foi excluído com sucesso.',
  nomeatingiulimitemaximocaracteres: 'O nome atingiu o limite máximo de 250 caracteres.',
  camponomenaopodeestarvazio: 'O campo nome não pode estar vazio.',
  aindanaoexistemacoesaguardandoaprovacao: 'Ainda não existem ações aguardando aprovação.',
  aindanaoexistemacoesconcluidas: 'Ainda não existem ações concluídas.',
  aindanaoexistemacoesreprovadas: 'Ainda não existem ações reprovadas',
  COMPLETED: 'Concluída',
  acaoadicionadacomsucesso: 'Ação adicionada com sucesso.',
  limitedecaracteresatingido: 'Limite de {qtd} caracteres atingido.',
  colunacomnomeduplicado: 'Coluna com nome duplicado',
  jaexisteumacolunacomonome: 'Já existe uma coluna com o nome <strong>{columnName}</strong>, por favor renomeie para dar continuidade.',
  renomear: 'Renomear',
  planodeacaocapitalizado: 'Plano de ação',
  ultimaevidencianaopodeserremovida: 'A última evidência não pode ser removida, é necessário manter pelo menos uma evidência para a ação',
  userisresponsibleforactions: 'O usuário não pode ser removido ou alterado, pois está vinculado à uma ação',
  limitederesponsaveisdaacaoatingido: 'Você atingiu o limite de responsáveis nesta ação',
  oarquviopodeternomaximo: 'O arquivo deve ter no máximo {size}Mb',
  tipodedeevidenciainvalida: `Extensão de evidência inválida! Aceito: {acceptTypes}`,
  acaoatrasadatextocompleto: 'Ação Atrasada',
  informeomodelodesejado: 'Informe o modelo desejado',
  naofoipossivelsalvaressemodeloonomeescolhidojaestaemuso: 'Não foi possível salvar esse modelo, o nome escolhido já está em uso',
  naofoipossivelsalvaresseplanodeacaoonomeescolhidojaestaemuso: 'Não foi possível copiar esse plano de ação, o nome escolhido já está em uso',
  naofoipossivelduplicaresseplanodeacaoonomeescolhidojaestaemuso: 'Não foi possível duplicar esse plano de ação, o nome escolhido já está em uso',
  naohaatualizacoesparamostrar: 'Não há atualizações para mostrar',
  estaaçaonaopodeseralteradaporqueoplanojafoiconcluido: 'Esta ação não pode ser alterada porque o plano já foi concluído.',
  edicaonaosalva: 'Edição não salva',
  desejarealmentefecharsemsalvar: 'Deseja realmente <strong>fechar sem salvar</strong>?',
  edicoespendentes: 'Existem edições pendentes nesta ação. Se você fechar agora, as modificações feitas serão perdidas.',
  usuariosatualizadoscomsucesso: 'Usuários atualizados com sucesso',
  justificativadareprovacaomin: 'Justificativa da reprovação',
  aposaexclusaotodoohistoricodemovimentacoesrelacionadoaestaacao: 'Após a exclusão, todo histórico de movimentações relacionado a esta ação será perdido, e ela deixará de estar visível para os colaboradores a quem foi atribuída.',
  umavezexcluidanaoserapossivelreutilizaloemplanofuturos: 'Uma vez excluída não será possível reutilizá-lo em plano futuros',
  erroentreemcontatocomadministrador: 'Caso acredite que isso seja um erro, entre em contato com o administrador do sistema.',
  naopossuiacesso: 'Você não possui acesso a esta funcionalidade.',
  entraremcontato: 'Entrar em contato',
  naohamarcacaodeponto: 'Não há marcação de ponto',
  confirmarexclusaodecargo: 'Confirmar exclusão de cargo',
  temcertezadequedesejaexcluirestecargoessaacaoeirreversivel: 'Tem certeza de que deseja excluir este cargo? Essa ação é irreversível.',
  exclusaonaopermitida: 'Exclusão não permitida',
  estecargopossuicolaboradorescomregistrodepontohtml: 'Este cargo possui colaboradores vinculados com a flag <strong>Sem registro de ponto</strong> ativa. Ajuste as configurações de jornada dos colaboradores em: <strong>Jornada de Trabalho > Marcações</strong>, antes de excluir o cargo.',
  estecargopossuicolaboradorescomregistrodeponto: 'Este cargo possui colaboradores vinculados com a flag sem registro de ponto ativa. Ajuste as configurações de jornada dos colaboradores em: Jornada de Trabalho > Marcações, antes de excluir o cargo.',
  entendido: 'Entendido',
  positionmin: 'cargo',
  semregistrodeponto: 'Sem registro de ponto',
  collaboratormin: 'colaborador',
  confirmarexclusaoderegistro: 'Confirmar exclusão de registro',
  temcertezaquedesejaexcluiressaacaonaopoderaserdesfeita: 'Tem certeza de que deseja excluir este registro de jornada? Essa ação não poderá ser desfeita.',
  excluirregistro: 'Excluir registro',
  editarregistro: 'Editar registro',
  datadeterminodefinida: 'Data de término definida.',
  errointervalodetempomarcacao: 'O intervalo de tempo informado não pode sobrepor períodos de outras marcações já cadastradas e também não pode exceder o período em que o colaborador exerceu este cargo.',
  marcacaoatualsemdatadetermino: 'Este registro não pode ser salvo. É necessário inserir a data de término para o registro atual',
  admissaoobrigatoriaporrecrutamentoeselecao: 'Admissão obrigatória por Recrutamento e Seleção',
  canditadoadmitidonaopodesereditado: 'Candidato admitido não pode ser editado',
  somenteocriadordoplanopodeencerralo: 'Somente o criador do plano pode encerrá-lo.',
  somenteocriadordoplanoouumadministradorpodeencerralo: 'Somente o criador do plano ou um administrador pode encerrá-lo.',
  somenteocriadordoplanopodereativalo: 'Somente o criador do plano pode reativá-lo.',
  somenteocriadordoplanoouumadministradorpodereativalo: 'Somente o criador do plano ou um administrador pode reativá-lo.',
  configuracaosalvacomsucesso: 'Configuração salva com sucesso',
  rgcrnm: 'RG/CRNM',
  dataultimavalidaderg: 'Data da última validade do RG',
  orgaoexpedidorrg: 'Órgão expedidor do RG',
  informeorgoucrnm: 'Informe o RG ou CRNM',
  informeorgaoexpedidor: 'Informe o órgão expedidor',
  ocampodevecontercaracteres: 'O campo deve conter de {minChars} a {maxChars} caracteres',
  avisoexclusaobloqueadaenecessariodeletaroregistromaisrecente: 'Este registro não pode ser excluído. É necessário deletar o registro mais recente e criar novos registros em ordem cronológica.',
  avisocargopossuimarcacoes: 'Este cargo possui colaboradores vinculados com a flag "Sem registro de ponto" ativa. Ajuste as configurações de jornada dos colaboradores em: Jornada de Trabalho > Marcações',
  avisomudancadecargopossuimarcacoessemdatadetermino: "A mudança no cargo será feita a partir da definição da data de término para a flag 'Sem registro de ponto', configurada em Jornada de Trabalho > Marcações.",
  avisomudancadecargopossuimarcacoes: "Para realizar a mudança no cargo, defina uma data de início maior do que a data de término inserida para a flag 'Sem registro de ponto' configurada em Jornada de Trabalho > Marcações.",
  avisodemissaopossuimarcacoessemdatadetermino: "A demissão será feita a partir da definição da data de término para a flag 'Sem registro de ponto', configurada em Jornada de Trabalho > Marcações.",
  avisodemissaopossuimarcacoes: "A data de término cadastrada em demissões tem que ser maior do que a data de término da flag 'Sem registro de ponto'.",
  avisoexclusaobloqueadapessoademetida: 'Este registro não pode ser excluído porque seu colaborador está demetido.',
  conflitodedatasdetectado: 'Conflito de datas detectado',
  corrijaadataparacontinuar: 'Corrija a data para continuar',
  corrigirdata: 'Corrigir data',
  alteracaonaopermitida: 'Alteração não permitida',
  estecargopossuicolaboradorescomregistrodepontoalteracaohtml: 'Este cargo possui colaboradores vinculados com a flag <strong>Sem registro de ponto</strong> ativa. Ajuste as configurações de jornada dos colaboradores em: <strong>Jornada de Trabalho > Marcações</strong>, antes de salvar o cargo.',
  relatorioderespostasdoformulario: 'Relatório de respostas do formulário',
  statusdocolaborador: 'Status do colaborador',
  dataanomaximoinvalido: 'O ano da data deve ser inferior a 2100',
  quantidadederegistros: 'Quantidade de registros',
  liberado: 'Liberado',
  ocorreuerroaobuscardadosdmpeople: 'Ocorreu um erro ao buscar os dados do DMPeople',
  ocorreuerroaobuscardadosfrotalegal: 'Ocorreu um erro ao buscar os dados do FrotaLegal',
  informeoutro: 'Informe outro'
}
