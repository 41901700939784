const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userId: state => state.user.userId,
  userEmail: state => state.user.userEmail,
  userProfile: state => state.user.userProfile,
  userType: state => state.user.userType,
  userPosition: state => state.user.userPosition,
  userModules: state => state.user.userModules,
  userCpf: state => state.user.userCpf,
  userClient: state => state.user.userClient,
  userValidClients: state => state.user.userValidClients,
  userUnit: state => state.user.userUnit,
  userHierarchy: state => state.user.userHierarchy,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  evaluation: state => state.user.evaluation,
  research: state => state.user.research,
  responsavel: state => state.user.responsavel,
  local: state => state.user.local,
  locale: state => state.user.locale,
  formulario: state => state.user.formulario,
  updateForm: state => state.user.updateForm,
  formdata: state => state.user.formdata,
  linkImage: state => state.user.linkImage,
  updateCargo: state => state.user.updateCargo,
  updateUnidade: state => state.user.updateUnidade,
  tabIndex: state => state.user.tabIndex,
  updatePerson: state => state.user.updatePerson,
  updateVacation: state => state.user.updateVacation,
  showPositionList: state => state.user.showPositionList,
  updateTrainingEvaluation: state => state.user.updateTrainingEvaluation,
  userEcosystems: state => state.user.userEcosystems,
  updateClient: state => state.user.updateClient,
  canViewCollaboratorsInWorkplace: state => state.user.canViewCollaboratorsInWorkplace,
  isManager: state => state.user.isManager,
  isCycleManager: state => state.user.isCycleManager,
  isAdmin: state => state.user.userProfile === 'admin_sistema',
  isMaster: state => state.user.isMaster,
  modules: state => state.modules.modules,
  isVisibleTrainingCalendarTab: state => state.currentTrainingFollowUpTabIndex.currentTrainingFollowUpTabIndex === state.currentTrainingFollowUpTabIndex.CALENDAR_TAB,
  hasFullAccess: state => state.user.hasFullAccess
}
export default getters
